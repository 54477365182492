import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { StyledDivider, Tab, TabsContainer, TabsInnerContainer, Title } from './Tabs.styles';

interface TabType {
  title: string;
  icon?: ReactNode;
  path: string;
  onClick: () => void;
}

interface TabsProps {
  tabs: TabType[];
}
const Tabs = ({ tabs }: TabsProps) => {
  const location = useLocation();

  const isCurrentTab = (path: string) => location.pathname === path;

  const getStatus = (path: string) => {
    return isCurrentTab(path) ? 'active' : 'inactive';
  };

  return (
    <TabsContainer>
      <TabsInnerContainer>
        {tabs?.map((tab: TabType) => (
          <Tab key={tab.title} status={getStatus(tab.path)} onPress={() => !isCurrentTab(tab.path) && tab.onClick()}>
            {tab.icon && tab.icon} <Title>{tab.title}</Title>
          </Tab>
        ))}
      </TabsInnerContainer>
      <StyledDivider />
    </TabsContainer>
  );
};

export default Tabs;
