import { Avatar as StyledAvatar } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface AvatarProps {
  borderRadius?: string;
  border?: string;
  fontSize?: string;
  fontColor?: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
}

const defaultBorderRadius = 0;

const Avatar = styled(StyledAvatar)<AvatarProps>`
  width: ${(props: AvatarProps) => (props.width ? `${props.width}rem` : 'auto')};
  height: ${(props: AvatarProps) => (props.height ? `${props.height}rem` : 'auto')};
  border-radius: ${(props: AvatarProps) => props.borderRadius || defaultBorderRadius};
  .nextui-avatar-bg,
  .nextui-avatar-img {
    background-color: ${(props: ThemeProp & AvatarProps) => props.backgroundColor || props.theme.colors.primary.c500};
    border-radius: ${(props: AvatarProps) => props.borderRadius || defaultBorderRadius};
    border: ${(props: AvatarProps) => props.border || 'none'};
  }
  .nextui-avatar-text {
    color: ${(props: ThemeProp & AvatarProps) => props.fontColor || props.theme.colors.neutrals.black};
    font-size: ${(props: AvatarProps & ThemeProp) => props.fontSize || props.theme.fontSize.l};
  }
`;

export default Avatar;
