import { Dropdown } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  margin: 0 0 0.25rem 0.5rem;
`;

export const StyledDropdown = styled(Dropdown).attrs((props) => ({
  ...props,
  isBordered: false,
  offset: 4,
}))``;

export const DropDownButton = styled(Dropdown.Button).attrs((props) => {
  const isActive = props.$isActive;
  const isDisabled = props.$isDisabled;
  return {
    css: {
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      color: isActive ? props.theme.colors.primary : props.theme.colors.primary.c500,
      background: isActive ? props.theme.colors.primary.c200 : props.theme.colors.primary.c050,
      height: 'calc($13 - 2px)',
      width: '5.8rem',
      'border-radius': '$xxs',
      'border-color': 'transparent',
      'padding-left': '$6',
      'padding-right': '$6',
      '&.nextui-dropdown-button': {
        justifyContent: 'space-between',
      },
      '&.nextui-dropdown-button[aria-haspopup="true"].nextui-dropdown-button[aria-expanded="true"]': {
        transform: 'unset',
      },
      ...props.css,
    },
    ...props,
  };
})``;

interface DropDownMenuProps {
  $active?: boolean;
  $isDisabled?: boolean;
}

export const DropDownMenu = styled(Dropdown.Menu)<DropDownMenuProps>`
  width: 5.8rem;
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  color: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.colors.primary.c500)};
  background: ${(props) => (props.$active ? props.theme.colors.primary.c200 : props.theme.colors.primary.c050)};
  height: 2.25rem;
  display: flex;
  justify-content: flex-start;
  min-width: 6rem;

  & .nextui-dropdown-item {
    height: unset;
    padding: 0 0.4rem;
    background: transparent;
    color: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.colors.primary.c500)};
  }
`;

export const DropDownItemContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  height: 2rem;
`;

export const DropDownItemText = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const DropDownItemDescription = styled(DropDownItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.primary.c300};
  font-style: italic;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c050};
`;
