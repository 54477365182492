import React, { SVGProps } from 'react';

const ThinPenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9858 6.40793L17.592 5.01415C17.2066 4.62875 16.5827 4.62875 16.1982 5.01415L5.76366 15.4487C5.57835 15.6331 5.47485 15.8844 5.47485 16.1456V18.5251H7.85433C8.11554 18.5251 8.36689 18.4216 8.55121 18.2363L18.9858 7.8017C19.3712 7.41728 19.3712 6.79334 18.9858 6.40793V6.40793Z"
      strokeWidth="1.4375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.2145 9.5647L14.4348 6.78503" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ThinPenIcon;
