import { Popover } from '@nextui-org/react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarIcon, ThinCheckIcon } from '../../assets';
import {
  DATE_FILTER_OPTIONS,
  DateFilterOption,
  DateFilterOptionValue,
  useKPIsAndFilterContext,
} from '../../contexts/KPIsAndFilterContext';
import { inTablet, useWindowDimensions } from '../../hooks/useDimensions';
import Modal from '../Modal';
import { BottomSheetChangeBreakpoint } from '../Modal/Modal';
import {
  ButtonExtraDataContainer,
  Container,
  ContentContainer,
  MobileOptionsContainer,
  SelectedOptionButton,
  StyledButton,
  StyledChevronIcon,
  StyledDateRangePicker,
} from './DateGlobalFilter.styles';

const MAXIMUM_VALID_DAYS_BACK_FROM_TODAY = 179;

interface DateGlobalFilterProps {
  isSegmentsDateFilter?: boolean;
}

const DateGlobalFilter = ({ isSegmentsDateFilter }: DateGlobalFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCustomDate, setIsOpenCustomDate] = useState(false);
  const { t } = useTranslation();
  const { setDateRangeFilter, dateRangeFilter, setSegmentsDateRangeFilter, segmentsDateRangeFilter, setLoadingKPIMap } =
    useKPIsAndFilterContext();
  const { width } = useWindowDimensions();

  const dateRangeFilterToBeUsed = isSegmentsDateFilter ? segmentsDateRangeFilter : dateRangeFilter;
  const popoverOffset = isSegmentsDateFilter ? 8 : 20;

  // Calculate MAXIMUM_VALID_DAYS_BACK_FROM_TODAY back from today
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const maxValidDaysBackFromToday = new Date(today);
  maxValidDaysBackFromToday.setDate(today.getDate() - MAXIMUM_VALID_DAYS_BACK_FROM_TODAY);

  const handleFilterChange = (option: DateFilterOption) => {
    if (option.value === DateFilterOptionValue.CustomDate) {
      setIsOpenCustomDate(true);
    } else {
      if (isSegmentsDateFilter) {
        setSegmentsDateRangeFilter(option);
      } else {
        if (option.value !== dateRangeFilterToBeUsed?.value) {
          setLoadingKPIMap(true);
        }
        setDateRangeFilter(option);
      }
      setIsOpen(false);
    }
  };

  const handleDateRangeChange = (range: DateRange) => {
    const dateRangeToBeSaved = {
      ...DATE_FILTER_OPTIONS.find((option) => option.value === DateFilterOptionValue.CustomDate),
      range,
    };

    if (isSegmentsDateFilter) {
      setSegmentsDateRangeFilter(dateRangeToBeSaved);
    } else {
      const rangeChanged =
        range?.start.toISOString() !== dateRangeFilterToBeUsed?.range?.start.toISOString() ||
        range?.end.toISOString() !== dateRangeFilterToBeUsed?.range?.end.toISOString();

      if (rangeChanged) {
        setLoadingKPIMap(true);
      }
      setDateRangeFilter(dateRangeToBeSaved);
    }
    setIsOpenCustomDate(false);
    setIsOpen(false);
  };

  const getButtonLabel = (option: DateFilterOption) => {
    if (option?.days) {
      return option?.text;
    }

    return `${t('dateBars', {
      val: moment(dateRangeFilterToBeUsed?.range?.start)?.toDate(),
      interpolation: { escapeValue: false },
    })} - ${t('dateBars', {
      val: moment(dateRangeFilterToBeUsed?.range?.end)?.toDate(),
      interpolation: { escapeValue: false },
    })}`;
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setIsOpenCustomDate(false);
    }
    if (open && dateRangeFilterToBeUsed?.value === DateFilterOptionValue.CustomDate) {
      setIsOpenCustomDate(true);
    }
    setIsOpen(open);
  };

  const buttonTrigger = useMemo(
    () => (
      <SelectedOptionButton onClick={inTablet(width) ? () => setIsOpen(true) : undefined}>
        {isSegmentsDateFilter && (
          <ButtonExtraDataContainer>
            <CalendarIcon />
            {t('common.showing')}
          </ButtonExtraDataContainer>
        )}
        {getButtonLabel(dateRangeFilterToBeUsed)}
        <StyledChevronIcon $shouldRotate={isOpen} />
      </SelectedOptionButton>
    ),
    [dateRangeFilterToBeUsed, isOpen],
  );

  const optionButton = (option: DateFilterOption) => {
    const isActive = dateRangeFilterToBeUsed?.value === option.value;
    return (
      <StyledButton $isActive={isActive} onClick={() => handleFilterChange(option)}>
        {option?.text}
        {isActive && <ThinCheckIcon />}
      </StyledButton>
    );
  };

  return inTablet(width) ? (
    <>
      {buttonTrigger}
      <Modal
        mobileCloseButtonAtEnd
        bottomSheetChangeBreakpoint={BottomSheetChangeBreakpoint.Tablet}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={t('common.selectDateRange')}
        hideFooter
      >
        <MobileOptionsContainer>{DATE_FILTER_OPTIONS.map((option) => optionButton(option))}</MobileOptionsContainer>
      </Modal>
      <Modal
        title={t('common.selectDateRange')}
        bottomSheetChangeBreakpoint={BottomSheetChangeBreakpoint.Tablet}
        mobileCloseButtonAtEnd
        open={isOpenCustomDate}
        onClose={() => setIsOpenCustomDate(false)}
        hideFooter
      >
        <MobileOptionsContainer>
          <StyledDateRangePicker
            selectedRange={dateRangeFilterToBeUsed?.range}
            hidePredefinedSelections
            minimumDate={maxValidDaysBackFromToday}
            maximumDate={new Date()}
            onChange={handleDateRangeChange}
          />
        </MobileOptionsContainer>
      </Modal>
    </>
  ) : (
    <Popover
      offset={popoverOffset}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      placement={isSegmentsDateFilter ? 'bottom-right' : 'bottom-left'}
    >
      <Popover.Trigger>{buttonTrigger}</Popover.Trigger>
      <Popover.Content>
        <ContentContainer>
          <Container>{DATE_FILTER_OPTIONS.map((option) => optionButton(option))}</Container>
          {isOpenCustomDate && (
            <StyledDateRangePicker
              selectedRange={dateRangeFilterToBeUsed?.range}
              hidePredefinedSelections
              minimumDate={maxValidDaysBackFromToday}
              maximumDate={new Date()}
              onChange={handleDateRangeChange}
            />
          )}
        </ContentContainer>
      </Popover.Content>
    </Popover>
  );
};

export default DateGlobalFilter;
