import { Checkbox as NextCheckbox } from '@nextui-org/react';
import styled from 'styled-components';

const Checkbox = styled(NextCheckbox).attrs((props) => ({
  size: 'sm',
  ...props,
  css: {
    '& .nextui-checkbox-container': {
      height: '$8',
      width: '$8',
      borderRadius: !props.isRounded && '0',
      '--nextui--checkboxColor': props.theme.colors.primary.c700,
      '--nextui--checkboxColorHover': props.theme.colors.primary.c800,
      '--nextui--checkboxBorderColor': props.$borderColor || props.theme.colors.neutrals.white,
    },

    '& .nextui-checkbox-text': {
      'font-size': '$md',
      'line-height': '$sm2',
    },
  },
}))``;

export default Checkbox;
