import { LazyQueryExecFunction, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SEGMENT_KPI_BOX_MAX_WIDTH_2, SEGMENT_KPI_BOX_MAX_WIDTH_3 } from '../constants';
import KPIsHelper, {
  formatMultiMetricsData,
  KPIMultiMetric,
  KPIMultiMetricsPosition,
  mergeMultiMetricsResponses,
  mergeObjectsWithLatest,
  ValidKPIField,
} from '../helpers/KPIsHelper';
import { useAppContext } from '../hooks/AppContext';
import { useDebounce } from '../hooks/useDebounce';
import { usePrevious } from '../hooks/usePrevious';
import {
  DeleteSegmentDocument,
  Exact,
  Segment,
  SegmentFilter,
  SegmentFilterDateField,
  SegmentFilterStringField,
  SegmentsDocument,
  SegmentsQuery,
  useSegmentProcessedSubscription,
  useSegmentsLazyQuery,
} from '../types/api.graphql';
import { KPIsData } from './KpiMetricsContext';
import { getValueInDateRange, useKPIsAndFilterContext } from './KPIsAndFilterContext';
import { useSessionContext } from './SessionContext';

type CheckboxType = { id: string; isCreator: boolean };
type CheckedCheckboxesType = { [s: string]: { selected: boolean; isCreator: boolean } };
type LoadSegmentsType = LazyQueryExecFunction<
  SegmentsQuery,
  Exact<{
    first?: number;
    after?: string;
    before?: string;
    last?: number;
    searchString?: string;
  }>
>;

export interface SegmentExtended extends Segment {
  isBeingDeleted?: boolean;
  shouldAvoidCachedMultimetricResponses?: boolean; // Enforce bringing new data for segments that have been updated.
}

type FilterState = Record<SegmentFilterStringField | SegmentFilterDateField, FilterInput>;

type StringFilter = {
  type: 'string';
  field: SegmentFilterStringField;
  values: string[];
};

type DateFilter = {
  type: 'date';
  field: SegmentFilterDateField;
  values: { from: Date | null; to: Date | null };
};

type FilterInput = StringFilter | DateFilter;

interface SegmentsContextProps {
  segments: Array<SegmentExtended>;
  loadSegments: LoadSegmentsType;
  segmentsTotalCount: number;
  segmentsHasNextPage: boolean;
  loading: boolean;
  loadMoreItems: () => Promise<void>;
  checkedCheckboxes: CheckedCheckboxesType;
  handleSegmentSelected: (isSelected: boolean, segmentId: string) => void;
  handleSelectAll: () => void;
  handleDeselectAll: () => void;
  handleClearSearch: () => void;
  createdByUserSelectedCount: number;
  selectedCount: number;
  areAllCheckboxesSelected: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  validAndSelectedKPIs: Array<KPIsData>;
  handleDeleteSelectedSegments: () => Promise<void>;
  disableSelectAllCheckbox: boolean;
  isMobileCardViewSelected: boolean;
  setIsMobileCardViewSelected: (value: boolean) => void;
  KPIsPositions: Array<KPIMultiMetricsPosition>;
  priceKPIData: KPIMultiMetric;
  mileageKPIData: KPIMultiMetric;
  daysOnMarketKPIData: KPIMultiMetric;
  listingVolumeMetricKPIData: KPIMultiMetric;
  listingByFranchiseKPIData: KPIMultiMetric;
  listingByCPOKPIData: KPIMultiMetric;
  checkIfShouldRedirectToSegmentsList: () => void;
  marketDaysSupplyKPIData: KPIMultiMetric;
  priceVINKPIData: KPIMultiMetric;
  mileageVINKPIData: KPIMultiMetric;
  daysOnMarketVINKPIData: KPIMultiMetric;
  vehicleVolumeMetricKPIData: KPIMultiMetric;
  vehicleByFranchiseKPIData: KPIMultiMetric;
  vehicleByCPOKPIData: KPIMultiMetric;
  marketDaysSupplyVINKPIData: KPIMultiMetric;
  selectedFilters: FilterState;
  updateFilterField: (
    field: SegmentFilterStringField | SegmentFilterDateField,
    values: Array<string> | { from: Date; to: Date },
  ) => void;
  filterCount: number;
  clearAllFilters: () => void;
}

interface SegmentsProviderProps {
  children: ReactNode;
}

// Constant to define the checkbox + name boxes width.
const CHECKBOX_AND_NAME_BOXES = '1rem 15rem';
const LABEL_WIDTH = '11.875rem';
// Constant to take into account the columns that are always visible when rendering segment details.
export const ALWAYS_VISIBLE_COLUMNS_COUNT = 3;

export const calculateKPIsGridTemplateColumns = (selectedKPIs?: KPIsData[]) => {
  let KPIsBoxesTemplate = '';
  const permanentBoxesTemplate = [...Array(ALWAYS_VISIBLE_COLUMNS_COUNT)]
    .map(() => SEGMENT_KPI_BOX_MAX_WIDTH_2)
    .join(' ');

  if (selectedKPIs?.length > 0) {
    KPIsBoxesTemplate = selectedKPIs
      .map((kpi) => {
        switch (kpi.field) {
          case ValidKPIField.ListingByFranchise:
          case ValidKPIField.ListingByCPO:
          case ValidKPIField.VehicleByFranchise:
          case ValidKPIField.VehicleByCPO:
          case ValidKPIField.VehicleMarketDaysSupply:
          case ValidKPIField.MarketDaysSupply:
            return SEGMENT_KPI_BOX_MAX_WIDTH_3;
          default:
            return SEGMENT_KPI_BOX_MAX_WIDTH_2;
        }
      })
      .join(' ');
  }

  return `${CHECKBOX_AND_NAME_BOXES} ${LABEL_WIDTH} ${KPIsBoxesTemplate} ${permanentBoxesTemplate}`;
};

const initialFilters: FilterState = {
  createdByUserId: {
    type: 'string',
    field: SegmentFilterStringField.CreatedByUserId,
    values: [], // Initialize with an empty array for selected values
  },
  tagId: {
    type: 'string',
    field: SegmentFilterStringField.TagId,
    values: [],
  },
  createdAt: {
    type: 'date',
    field: SegmentFilterDateField.CreatedAt,
    values: { from: null, to: null },
  },
  updatedAt: {
    type: 'date',
    field: SegmentFilterDateField.UpdatedAt,
    values: { from: null, to: null },
  },
};

export const SegmentsContextInitialValues = {
  segments: null,
  loadSegments: null,
  segmentsTotalCount: null,
  segmentsHasNextPage: null,
  loading: false,
  loadMoreItems: null,
  checkedCheckboxes: null,
  handleSegmentSelected: null,
  handleSelectAll: null,
  handleDeselectAll: null,
  handleClearSearch: null,
  createdByUserSelectedCount: 0,
  selectedCount: 0,
  areAllCheckboxesSelected: false,
  searchValue: '',
  setSearchValue: null,
  validAndSelectedKPIs: null,
  handleDeleteSelectedSegments: null,
  disableSelectAllCheckbox: false,
  isMobileCardViewSelected: false,
  setIsMobileCardViewSelected: null,
  KPIsPositions: null,
  priceKPIData: null,
  mileageKPIData: null,
  daysOnMarketKPIData: null,
  listingVolumeMetricKPIData: null,
  listingByFranchiseKPIData: null,
  listingByCPOKPIData: null,
  checkIfShouldRedirectToSegmentsList: null,
  marketDaysSupplyKPIData: null,
  priceVINKPIData: null,
  setPriceVINKPIData: null,
  mileageVINKPIData: null,
  setMileageVINKPIData: null,
  daysOnMarketVINKPIData: null,
  setDaysOnMarketVINKPIData: null,
  vehicleVolumeMetricKPIData: null,
  setVehicleVolumeMetricKPIData: null,
  vehicleByFranchiseKPIData: null,
  setVehicleByFranchiseKPIData: null,
  vehicleByCPOKPIData: null,
  setVehicleByCPOKPIData: null,
  marketDaysSupplyVINKPIData: null,
  setMarketDaysSupplyVINKPIData: null,
  selectedFilters: initialFilters,
  updateFilterField: null,
  filterCount: 0,
  clearAllFilters: null,
};

export const SegmentsContext = createContext<SegmentsContextProps>(SegmentsContextInitialValues);

export const SegmentsProvider: FC<SegmentsProviderProps> = ({ children }) => {
  const NUMBER_OF_SEGMENTS_TO_FETCH = 10;

  const client = useApolloClient();
  const { cache } = useApolloClient();
  const { selectedSegmentsKPIs, segmentsDateRangeFilter } = useKPIsAndFilterContext();
  const { showError, showAlert } = useAppContext();
  const { t } = useTranslation();
  const { me, setSelectedCountry, selectedCountry } = useSessionContext();
  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState<FilterState>(initialFilters);
  const [checkboxes, setCheckboxes] = useState<Array<CheckboxType>>([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<CheckedCheckboxesType>(null);

  // Boolean flag to disable "Select all" checkbox while segments are being deleted.
  const [disableSelectAllCheckbox, setDisableSelectAllCheckbox] = useState(false);

  const selectedCount = useMemo(
    () => checkedCheckboxes && Object.values(checkedCheckboxes).filter((value) => value?.selected)?.length,
    [checkedCheckboxes],
  );
  const createdByUserSelectedCount = useMemo(
    () =>
      checkedCheckboxes &&
      Object.values(checkedCheckboxes).filter((value) => value?.selected && value?.isCreator)?.length,
    [checkedCheckboxes],
  );
  const areAllCheckboxesSelected = selectedCount === checkboxes.length;

  const checkIfShouldRedirectToSegmentsList = () => {
    const firstOrgImPartOf = me?.organizations?.find((org) => {
      return org?.userRoles?.some((role) => role?.user?.id === me?.id);
    });

    const hasMoreThanOneCountry = firstOrgImPartOf?.listingsCountries?.length > 1;
    if (!selectedCountry && hasMoreThanOneCountry && me) {
      navigate('/app/segments');
    }
    if (!hasMoreThanOneCountry) {
      setSelectedCountry(firstOrgImPartOf?.listingsCountries?.[0]);
    }
  };

  // Flag to avoid showing the "create segment" screen when clearing the search input and waiting for the fetch to start
  const [awaitingFetch, setAwaitingFetch] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const searchDebounced = useDebounce(searchValue, 500) as string;

  const handleClearSearch = () => {
    if (searchValue !== '') {
      setAwaitingFetch(true);
    }
    setSearchValue('');
  };

  // Boolean flag that is true when more segment items are being fetched.
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);

  // Boolean flag that determines if the "loadMore" segments procedure should be enabled or not.
  const enableLoadMore = useMemo(() => !isLoadingMoreItems, [isLoadingMoreItems]);

  const [segments, setSegments] = useState<Array<SegmentExtended>>([]);
  const previousSegments = usePrevious(segments);

  // KPIs that are valid to be requested.
  const [validAndSelectedKPIs, setValidAndSelectedKpis] = useState<Array<KPIsData>>(null);

  const [KPIsPositions, setKPIsPositions] = useState<Array<KPIMultiMetricsPosition>>(null);

  // KPIs data - one for each ValidKPIField.
  const [priceKPIData, setPriceKPIData] = useState<KPIMultiMetric>(null);
  const [mileageKPIData, setMileageKPIData] = useState<KPIMultiMetric>(null);
  const [daysOnMarketKPIData, setDaysOnMarketKPIData] = useState<KPIMultiMetric>(null);
  const [listingVolumeMetricKPIData, setListingVolumeMetricKPIData] = useState<KPIMultiMetric>(null);
  const [listingByFranchiseKPIData, setListingByFranchiseKPIData] = useState<KPIMultiMetric>(null);
  const [listingByCPOKPIData, setListingByCPOKPIData] = useState<KPIMultiMetric>(null);
  const [marketDaysSupplyKPIData, setMarketDaysSupplyKPIData] = useState<KPIMultiMetric>(null);

  // KPIs data VIN Level
  const [priceVINKPIData, setPriceVINKPIData] = useState<KPIMultiMetric>(null);
  const [mileageVINKPIData, setMileageVINKPIData] = useState<KPIMultiMetric>(null);
  const [daysOnMarketVINKPIData, setDaysOnMarketVINKPIData] = useState<KPIMultiMetric>(null);
  const [vehicleVolumeMetricKPIData, setVehicleVolumeMetricKPIData] = useState<KPIMultiMetric>(null);
  const [vehicleByFranchiseKPIData, setVehicleByFranchiseKPIData] = useState<KPIMultiMetric>(null);
  const [vehicleByCPOKPIData, setVehicleByCPOKPIData] = useState<KPIMultiMetric>(null);
  const [marketDaysSupplyVINKPIData, setMarketDaysSupplyVINKPIData] = useState<KPIMultiMetric>(null);

  const filtersObject = useMemo(() => {
    const filters: Array<SegmentFilter> = [];
    for (const field in selectedFilters) {
      const filter: FilterInput = selectedFilters[field];
      if (filter.type === 'string') {
        if (Array.isArray(filter.values) && filter.values.length > 0) {
          const filterObject = {
            stringFilter: {
              field: filter.field,
              values: filter.values.map(String),
            },
          };
          filters.push(filterObject);
        }
      } else if (filter.type === 'date') {
        if (filter.values.from && filter.values.to) {
          const filterObject = {
            dateFilter: {
              field: filter.field,
              values: {
                from: filter.values.from,
                to: filter.values.to,
              },
            },
          };
          filters.push(filterObject);
        }
      }
    }
    return filters;
  }, [selectedFilters]);

  const filterCount = useMemo(() => {
    // Calculate the filter count based on distinct filter types with non-empty values
    const distinctFilterTypes = Object.keys(selectedFilters).filter((filterType) => {
      const filter = selectedFilters[filterType];
      if (filter.type === 'date') {
        // For date filters, consider them as a single unit if either 'from' or 'to' is specified
        return filter.values.from != null || filter.values.to != null;
      }
      return filter.values.length > 0;
    });
    return distinctFilterTypes.length + (searchValue !== '' ? 1 : 0);
  }, [selectedFilters, searchValue]);

  const getUseStatesForKPI = (kpi: ValidKPIField) => {
    switch (kpi) {
      case ValidKPIField.Price:
        return {
          kpiData: priceKPIData,
          setKpiData: setPriceKPIData,
        };
      case ValidKPIField.VehiclePrice:
        return {
          kpiData: priceVINKPIData,
          setKpiData: setPriceVINKPIData,
        };
      case ValidKPIField.Mileage:
        return {
          kpiData: mileageKPIData,
          setKpiData: setMileageKPIData,
        };
      case ValidKPIField.VehicleMileage:
        return {
          kpiData: mileageVINKPIData,
          setKpiData: setMileageVINKPIData,
        };
      case ValidKPIField.DaysOnMarket:
        return {
          kpiData: daysOnMarketKPIData,
          setKpiData: setDaysOnMarketKPIData,
        };
      case ValidKPIField.VehicleDaysOnMarket:
        return {
          kpiData: daysOnMarketVINKPIData,
          setKpiData: setDaysOnMarketVINKPIData,
        };
      case ValidKPIField.ListingVolumeMetric:
        return {
          kpiData: listingVolumeMetricKPIData,
          setKpiData: setListingVolumeMetricKPIData,
        };
      case ValidKPIField.VehicleVolumeMetric:
        return {
          kpiData: vehicleVolumeMetricKPIData,
          setKpiData: setVehicleVolumeMetricKPIData,
        };
      case ValidKPIField.ListingByFranchise:
        return {
          kpiData: listingByFranchiseKPIData,
          setKpiData: setListingByFranchiseKPIData,
        };
      case ValidKPIField.VehicleByFranchise:
        return {
          kpiData: vehicleByFranchiseKPIData,
          setKpiData: setVehicleByFranchiseKPIData,
        };
      case ValidKPIField.ListingByCPO:
        return {
          kpiData: listingByCPOKPIData,
          setKpiData: setListingByCPOKPIData,
        };
      case ValidKPIField.VehicleByCPO:
        return {
          kpiData: vehicleByCPOKPIData,
          setKpiData: setVehicleByCPOKPIData,
        };
      case ValidKPIField.MarketDaysSupply:
        return {
          kpiData: marketDaysSupplyKPIData,
          setKpiData: setMarketDaysSupplyKPIData,
        };
      case ValidKPIField.VehicleMarketDaysSupply:
        return {
          kpiData: marketDaysSupplyVINKPIData,
          setKpiData: setMarketDaysSupplyVINKPIData,
        };
      default:
        return {
          kpiData: null,
          setKpiData: () => {
            // Do nothing.
          },
        };
    }
  };

  /**
   * Returns the valid KPIs from the ones that the user has selected, to be requested to backend.
   * @param selectedSegmentsKPIs - Selected KPIs by the user.
   */
  const getValidKpisToBeRequested = (selectedSegmentsKPIs: Array<KPIsData>): Array<KPIsData> => {
    return selectedSegmentsKPIs?.filter((item: KPIsData) => {
      const KPIHelper = KPIsHelper.createInstance(item.field);
      if (!KPIHelper) return false;

      return true;
    });
  };

  const fetchKPIMultiMetricsData = async (segments: Array<SegmentExtended>, KPI: KPIsData, position: number) => {
    const { setKpiData } = getUseStatesForKPI(KPI.field);
    // Exclude the segments that are not processed yet.
    const processedSegments = segments?.filter((segment) => segment.processed);

    // Initialize KPIs for these segments, and mark them as loading.
    const dataLoading = {};
    processedSegments.forEach(
      (segment) =>
        (dataLoading[segment?.id] = {
          loading: true,
        }),
    );
    setKpiData((prev) => ({
      name: prev?.name ?? KPI?.name,
      filterField: prev?.filterField ?? KPI?.field,
      position,
      data: mergeObjectsWithLatest(prev?.data, dataLoading),
    }));

    try {
      const KPIHelper = KPIsHelper.createInstance(KPI.field, client);
      const promises = KPIHelper?.getMultiMetricQueryPromises(
        getValueInDateRange(segmentsDateRangeFilter),
        processedSegments.map((segment) => segment?.id),
        processedSegments.some((s) => s.shouldAvoidCachedMultimetricResponses),
      );
      const responses = await Promise.all(promises.map((item) => item.query));
      const multiMetricsData = mergeMultiMetricsResponses(responses);
      const formattedMultiMetricsData = formatMultiMetricsData(multiMetricsData, KPI);

      // When data is received, update it and set loading = false;
      setKpiData((prev) => ({
        ...prev,
        data: mergeObjectsWithLatest(prev?.data, formattedMultiMetricsData),
      }));
    } catch (error) {
      // If error, set loading = false for these segments.
      const dataNotLoading = {};
      processedSegments.forEach(
        (segment) =>
          (dataNotLoading[segment?.id] = {
            loading: false,
          }),
      );
      setKpiData((prev) => ({
        ...prev,
        data: mergeObjectsWithLatest(prev?.data, dataNotLoading),
      }));
    }
  };

  const fetchSegmentsKPIs = (segments: Array<SegmentExtended>, validKpisToBeRequested: Array<KPIsData>) => {
    // Assign positions to valid KPIs
    const newKPIsPositions: Array<KPIMultiMetricsPosition> = validKpisToBeRequested?.map((KPI, index) => ({
      field: KPI.field,
      position: index,
    }));
    setKPIsPositions(newKPIsPositions);

    if (segments?.length > 0) {
      // Request data for each valid KPI.
      // We use Apollo's cached data, so if segments have changed only their position data will be available instantly.
      validKpisToBeRequested?.forEach((KPI, index) => {
        void fetchKPIMultiMetricsData(segments, KPI, index);
      });
    }
  };

  /**
   * Auxiliary function to load the KPIs for the segments.
   * It will be called when the user modifies its selected KPIs, or if new segments are loaded.
   * @param segments - Segments for which the KPIs will be requested.
   */
  const getSegmentsKpis = (segments: Array<SegmentExtended>) => {
    const validKpisToBeRequested = getValidKpisToBeRequested(selectedSegmentsKPIs);
    setValidAndSelectedKpis(validKpisToBeRequested);
    fetchSegmentsKPIs(segments, validKpisToBeRequested);
  };

  // Boolean flag to show card view for mobile devices.
  const [isMobileCardViewSelected, setIsMobileCardViewSelected] = useState(false);

  const [loadSegments, { data, loading: queryLoading, fetchMore }] = useSegmentsLazyQuery({
    variables: {
      first: NUMBER_OF_SEGMENTS_TO_FETCH,
      searchString: searchDebounced,
      filters: filtersObject,
    },
    onCompleted(data) {
      setAwaitingFetch(false);
      if (data?.segments?.nodes?.length > 0) {
        const checkboxesLocal = data?.segments?.nodes?.map((segment) => ({
          id: segment?.id,
          isCreator: segment?.creator?.id === me?.id,
        }));
        setCheckboxes(checkboxesLocal);

        // If all checkboxes are checked, check them all.
        // Else, if some were checked, check them again.
        let newCheckedCheckboxesLocal = {};
        if (areAllCheckboxesSelected) {
          newCheckedCheckboxesLocal = checkboxesLocal?.reduce(
            (acc, checkbox) => ({
              ...acc,
              [checkbox.id]: { selected: areAllCheckboxesSelected, isCreator: checkbox.isCreator },
            }),
            {},
          );
        } else {
          newCheckedCheckboxesLocal = checkboxesLocal?.reduce(
            (acc, checkbox) => ({
              ...acc,
              [checkbox.id]: {
                selected: checkedCheckboxes?.[checkbox.id]?.selected || false,
                isCreator: checkbox.isCreator,
              },
            }),
            {},
          );
        }
        setCheckedCheckboxes(newCheckedCheckboxesLocal);
      }
      setSegments(data?.segments?.nodes);

      if (previousSegments !== data?.segments?.nodes) {
        const newSegments: Array<SegmentExtended> = [];

        // First, check if there are new segments.
        const newSegmentsById = _.differenceBy(data?.segments?.nodes, previousSegments, 'id');
        newSegments.push(...newSegmentsById);

        // Then, check if there are any segments that were not processed, but now are.
        const newSegmentsIDs = newSegments?.map((segment) => segment?.id);
        const beingProcessedSegments = previousSegments.filter((segment) => !segment?.processed).map((s) => s.id);
        const newProcessedSegments = data?.segments?.nodes?.filter(
          (segment) =>
            // If the segment is new, we avoid it.
            !newSegmentsIDs.includes(segment.id) &&
            // We want to check those segments that were being processed.
            beingProcessedSegments.includes(segment.id) &&
            // We finally check that is now processed.
            segment.processed,
        );
        if (newProcessedSegments?.length > 0) {
          newSegments.push(...newProcessedSegments);
        }

        // Finally, check if there are segments that were updated.
        const newSegmentsByUpdatedDate = _.differenceBy(data?.segments?.nodes, previousSegments, 'updatedAt');
        // Avoid duplicating segments, and enforce bringing new data for these segments.
        // NOTE: if at least one segment enforces bringing new data, all "newSegments" will do so.
        newSegments.push(
          ...newSegmentsByUpdatedDate
            .filter((segment) => !newSegmentsIDs.includes(segment.id))
            .map((s) => ({
              ...s,
              shouldAvoidCachedMultimetricResponses: true,
            })),
        );

        getSegmentsKpis(newSegments);
      }
    },
    onError() {
      setAwaitingFetch(false);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const loading = awaitingFetch || queryLoading;

  const segmentsTotalCount = useMemo(() => data?.segments?.totalCount, [data]);
  const segmentsHasNextPage = useMemo(() => data?.segments?.pageInfo?.hasNextPage, [data]);

  const loadMoreItems = async () => {
    if (enableLoadMore) {
      setIsLoadingMoreItems(true);
      await fetchMore({
        variables: {
          after: data?.segments?.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            segments: {
              nodes: [...(prev.segments?.nodes || []), ...(fetchMoreResult.segments?.nodes || [])],
              pageInfo: fetchMoreResult.segments?.pageInfo,
              totalCount: fetchMoreResult.segments?.totalCount,
            },
          };
        },
      });
      setIsLoadingMoreItems(false);
    }
  };

  useEffect(() => {
    getSegmentsKpis(segments);
  }, [selectedSegmentsKPIs, segmentsDateRangeFilter]);

  const handleSegmentSelected = (isSelected: boolean, segmentId: string) => {
    setCheckedCheckboxes({
      ...checkedCheckboxes,
      [segmentId]: { ...checkedCheckboxes[segmentId], selected: isSelected },
    });
  };

  const processSegmentSubscription = (subscriptionData) => {
    const oldSegmentData = segments.find((segment) => segment.id === subscriptionData?.data?.segmentProcessed?.id);
    const newSegmentData = subscriptionData?.data?.segmentProcessed;
    const mergedSegmentData = {
      ...oldSegmentData,
      ...newSegmentData,
    };
    const newSegments = segments.map((segment) => {
      if (segment.id === subscriptionData?.data?.segmentProcessed?.id) {
        return mergedSegmentData;
      }
      return segment;
    });

    setSegments(newSegments as SegmentExtended[]);
  };

  const segmentsIdsToBeSubscribed = useMemo(
    () => segments?.filter((segment) => !segment.processed).map((segment) => segment?.id),
    [segments],
  );

  useSegmentProcessedSubscription({
    variables: {
      segmentIds: segmentsIdsToBeSubscribed,
    },
    skip: !segmentsIdsToBeSubscribed?.length,
    onSubscriptionData: ({ subscriptionData }) => {
      processSegmentSubscription(subscriptionData);
    },
  });

  const handleSelectAll = () => {
    const newIsChecked = checkboxes?.reduce(
      (acc, checkbox) => ({
        ...acc,
        [checkbox.id]: {
          isCreator: checkboxes.find((box) => box.id === checkbox.id)?.isCreator,
          selected: !areAllCheckboxesSelected,
        },
      }),
      {},
    );
    setCheckedCheckboxes(newIsChecked);
  };

  const handleDeselectAll = () => {
    const newIsChecked = checkboxes?.reduce(
      (acc, checkbox) => ({
        ...acc,
        [checkbox.id]: { isCreator: checkboxes.find((box) => box.id === checkbox.id)?.isCreator, selected: false },
      }),
      {},
    );
    setCheckedCheckboxes(newIsChecked);
  };

  const handleDeleteSelectedSegments = async () => {
    const idsToBeDeletedPromises = [];

    try {
      for (const key in checkedCheckboxes) {
        if (checkedCheckboxes[key].isCreator && checkedCheckboxes[key].selected) {
          idsToBeDeletedPromises.push({
            id: key,
            promise: client.mutate({
              mutation: DeleteSegmentDocument,
              variables: {
                deleteSegmentId: key,
              },
            }),
          });
        }
      }

      setDisableSelectAllCheckbox(true);
      // Set isBeingDeleted flag to true for the segments that are being deleted.
      const newSegments = segments?.map((segment) => {
        if (idsToBeDeletedPromises?.find((item) => item?.id === segment?.id)) {
          return { ...segment, isBeingDeleted: true };
        }
        return segment;
      });
      setSegments(newSegments);

      const results = await Promise.allSettled(idsToBeDeletedPromises.map((item) => item.promise));
      const mappedResults = idsToBeDeletedPromises.map((item, index) => ({ ...results[index], id: item.id as string }));

      const nonSuccessfullyDeletedSegments = mappedResults?.filter((item) => item?.status === 'rejected');

      const successfullyDeletedSegments = mappedResults
        ?.filter((item) => item?.status === 'fulfilled')
        ?.map((item) => item?.id);

      setDisableSelectAllCheckbox(false);
      if (successfullyDeletedSegments?.length > 0) {
        // Update segments data.
        // Set isBeingDeleted flag to false, to reset the flag for the segments that were not successfully deleted.
        const newSegments = segments
          ?.filter((segment) => !successfullyDeletedSegments?.includes(segment?.id))
          .map((segment) => ({ ...segment, isBeingDeleted: false }));
        setSegments(newSegments);

        // Update segments cached data.
        const segmentsCacheData: SegmentsQuery = cache.readQuery({
          query: SegmentsDocument,
          variables: {
            first: NUMBER_OF_SEGMENTS_TO_FETCH,
            searchString: searchDebounced,
          },
        });

        if (segmentsCacheData) {
          const filteredData = {
            ...segmentsCacheData,
            segments: {
              // Remove nodes that belong to successfullyDeletedSegments.
              nodes: segmentsCacheData?.segments?.nodes?.filter(
                (node) => !successfullyDeletedSegments?.includes(node?.id),
              ),
              totalCount: segmentsCacheData?.segments?.totalCount - successfullyDeletedSegments?.length,
            },
          };
          cache.writeQuery({
            query: SegmentsDocument,
            variables: {
              first: NUMBER_OF_SEGMENTS_TO_FETCH,
              searchString: searchDebounced,
            },
            data: filteredData,
          });
        }

        showAlert(t('segments.deleteSuccessToast', { count: successfullyDeletedSegments?.length }));
      }

      if (nonSuccessfullyDeletedSegments.length > 0) {
        showError(t('segments.deleteErrorToast', { count: nonSuccessfullyDeletedSegments?.length }));
      }
    } catch (error) {
      showError(error);
      setDisableSelectAllCheckbox(false);
    }
  };

  const updateFilterField = (
    field: SegmentFilterStringField | SegmentFilterDateField,
    values: string[] | { from: Date; to: Date },
  ) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: {
        ...prevFilters[field],
        values,
      },
    }));
  };

  const clearAllFilters = () => {
    setSelectedFilters(initialFilters);
    setSearchValue('');
  };

  return (
    <SegmentsContext.Provider
      value={{
        segments,
        loadSegments,
        segmentsTotalCount,
        segmentsHasNextPage,
        loading,
        loadMoreItems,
        checkedCheckboxes,
        handleSegmentSelected,
        handleSelectAll,
        handleDeselectAll,
        handleClearSearch,
        selectedCount,
        createdByUserSelectedCount,
        areAllCheckboxesSelected,
        searchValue,
        setSearchValue,
        validAndSelectedKPIs,
        handleDeleteSelectedSegments,
        disableSelectAllCheckbox,
        isMobileCardViewSelected,
        setIsMobileCardViewSelected,
        KPIsPositions,
        priceKPIData,
        mileageKPIData,
        daysOnMarketKPIData,
        listingVolumeMetricKPIData,
        listingByFranchiseKPIData,
        listingByCPOKPIData,
        checkIfShouldRedirectToSegmentsList,
        marketDaysSupplyKPIData,
        priceVINKPIData,
        mileageVINKPIData,
        daysOnMarketVINKPIData,
        vehicleVolumeMetricKPIData,
        vehicleByFranchiseKPIData,
        vehicleByCPOKPIData,
        marketDaysSupplyVINKPIData,
        selectedFilters,
        updateFilterField,
        filterCount,
        clearAllFilters,
      }}
    >
      {children}
    </SegmentsContext.Provider>
  );
};

export const useSegmentsContext: () => SegmentsContextProps = () => {
  return useContext(SegmentsContext);
};
