import React from 'react';
import { SVGProps } from 'react';

const TagIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.2069 8.50001L9.00001 2.29313C8.90748 2.19987 8.79735 2.12593 8.67599 2.0756C8.55464 2.02528 8.4245 1.99959 8.29313 2.00001H2.50001C2.3674 2.00001 2.24022 2.05268 2.14645 2.14645C2.05268 2.24022 2.00001 2.3674 2.00001 2.50001V8.29313C1.99959 8.4245 2.02528 8.55464 2.0756 8.67599C2.12593 8.79735 2.19987 8.90748 2.29313 9.00001L8.50001 15.2069C8.59287 15.2998 8.70312 15.3734 8.82446 15.4237C8.9458 15.474 9.07585 15.4999 9.20719 15.4999C9.33853 15.4999 9.46859 15.474 9.58993 15.4237C9.71127 15.3734 9.82152 15.2998 9.91438 15.2069L15.2069 9.91438C15.2998 9.82152 15.3734 9.71127 15.4237 9.58993C15.474 9.46859 15.4999 9.33853 15.4999 9.20719C15.4999 9.07585 15.474 8.9458 15.4237 8.82446C15.3734 8.70312 15.2998 8.59287 15.2069 8.50001ZM9.20688 14.5L3.00001 8.29313V3.00001H8.29313L14.5 9.20688L9.20688 14.5ZM6.00001 5.25001C6.00001 5.39834 5.95602 5.54335 5.87361 5.66668C5.7912 5.79002 5.67406 5.88615 5.53702 5.94291C5.39997 5.99968 5.24917 6.01453 5.10369 5.98559C4.9582 5.95666 4.82456 5.88522 4.71967 5.78034C4.61479 5.67545 4.54335 5.54181 4.51442 5.39632C4.48548 5.25084 4.50033 5.10004 4.5571 4.96299C4.61386 4.82595 4.70999 4.70881 4.83333 4.6264C4.95666 4.54399 5.10167 4.50001 5.25001 4.50001C5.44892 4.50001 5.63968 4.57902 5.78034 4.71967C5.92099 4.86033 6.00001 5.05109 6.00001 5.25001Z" />
    </svg>
  );
};

export default TagIcon;
