import React, { forwardRef, Ref, useRef } from 'react';
import { BottomSheetProps } from 'react-spring-bottom-sheet';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';

import { StyledBottomSheet } from './BottomSheet.styles';

interface StyledBottomSheetProps extends Omit<BottomSheetProps, 'title'> {
  zIndex?: number;
  fullScreen?: boolean;
  headerWithoutStyles?: boolean;
  showDragIndicator?: boolean;
  notVisible?: boolean;
  disableBackdrop?: boolean;
  isBottomSheetExpanded?: boolean;
  setBottomSheetExpanded?: (isBottomSheetExpanded: boolean) => void;
  useTransparentBackground?: boolean;
  title?: string | React.ReactNode;
}

export const defaultBottomSheetPosition = 111;

const BottomSheet = forwardRef(
  (
    {
      zIndex,
      fullScreen,
      headerWithoutStyles,
      showDragIndicator = false,
      notVisible = false,
      disableBackdrop = false,
      isBottomSheetExpanded,
      setBottomSheetExpanded,
      useTransparentBackground,
      title,
      ...rest
    }: StyledBottomSheetProps,
    ref: Ref<RefHandles>,
  ) => {
    const bottomSheetRef = useRef<RefHandles>(null);
    return (
      <StyledBottomSheet
        onSpringStart={() => {
          requestAnimationFrame(() => {
            if (isBottomSheetExpanded) {
              setBottomSheetExpanded?.(bottomSheetRef.current.height !== defaultBottomSheetPosition);
            }
          });
        }}
        onSpringEnd={() => {
          requestAnimationFrame(() => {
            if (!isBottomSheetExpanded) {
              setBottomSheetExpanded?.(bottomSheetRef.current.height !== defaultBottomSheetPosition);
            }
          });
        }}
        expandOnContentDrag
        ref={ref || bottomSheetRef}
        $useTransparentBackground={useTransparentBackground}
        zIndex={zIndex}
        $fullScreen={fullScreen}
        $headerWithoutStyles={headerWithoutStyles}
        $showDragIndicator={showDragIndicator}
        $notVisible={notVisible}
        $disableBackdrop={disableBackdrop}
        title={typeof title === 'string' ? title : undefined}
        {...rest}
      />
    );
  },
);

export default BottomSheet;
