import i18next from 'i18next';
import { TouchEvent } from 'react';

export const isTouchDevice = () => {
  return window.matchMedia('(pointer: coarse)').matches;
};

export const hashCode = (str: string) => {
  let hash = 0,
    i: number,
    chr: number;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export function lowercaseFirstLetter(str: string) {
  if (!str?.length) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function uppercaseFirstLetter(str: string) {
  if (!str?.length) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const lastFolder = (path: string) => {
  return path.match(/([^\\/]*)\/*$/)[1];
};

export const formatCount = (value: number): string => {
  return value.toLocaleString('en-US');
};

export const formatCurrency = (valueInCents: number, currency = 'USD', showCents = false): string => {
  return i18next.t('price', {
    val: { value: valueInCents / 100.0, currency, fractionDigits: showCents && 2 },
    lng: 'en-US',
  });
};

export const formatDate = (date: Date): string => {
  return i18next.t('date', { val: date ? new Date(date) : new Date(), interpolation: { escapeValue: false } });
};

export const formatDateShort = (date: Date): string => {
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  return `${day} ${month}`;
};

export const formatTime = (date: Date): string => {
  return date.toLocaleTimeString([], { timeStyle: 'short' });
};

export const formatUrl = (url?: string) => {
  if (!url) return '';

  // Split the URL into an array to distinguish double slashes from single slashes
  const doubleSlash = url.split('//');

  // Format the strings on either side of double slashes separately
  const formatted = doubleSlash
    .map(
      (str) =>
        // Insert a word break opportunity after a colon
        str
          .replace(/(?<after>:)/giu, '$1<wbr>')
          // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
          .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
          // Before and after an equals sign or ampersand
          .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>'),
      // Reconnect the strings with word break opportunities after double slashes
    )
    .join('//<wbr>');

  return formatted;
};

export const omitTypename = (key: string, value: any) => {
  if (value === null || value === undefined) return undefined;
  return key === '__typename' ? undefined : value;
};

export const handleButtonOnTouchEnd = (e: TouchEvent<HTMLButtonElement>, action: () => void) => {
  e?.preventDefault?.();
  action?.();
  e?.stopPropagation();
};

export const handleOnPressNoTouchDevices = (action: () => void) => {
  if (isTouchDevice()) return undefined;
  action?.();
};

/**
 * Get the key of an enum object based on its value.
 * @template T - The type of the enum object.
 * @param {string} value - The value to search for.
 * @param {T} enumObject - The enum object.
 * @returns {keyof T | undefined} - The key of the enum object or undefined if the value is not found.
 */
export const getEnumKeyByValue = <T extends Record<string, string>>(
  enumObject: T,
  value: string,
): keyof T | undefined => {
  const indexOfValue = Object.values(enumObject).indexOf(value);
  const key = Object.keys(enumObject)[indexOfValue];
  return key as keyof T | undefined;
};

export const getScrollParent = (node: HTMLElement) => {
  if (!node) return null;

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentElement);
};

export const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

/**
 * Adjusts the passed date, removing the user's local timezone offset
 * @param stringDate date in string format
 * @returns an object of type Date with the correct UTC offset
 */
export const createUTCDate = (stringDate: string): Date => {
  const date = new Date(stringDate);

  // https://www.youtube.com/watch?v=oKFb2Us9kmg
  // Methods on Date Object will convert from UTC to users timezone
  // Set minutes to current minutes (UTC) + User local time UTC offset
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  return date;
};
