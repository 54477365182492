import React, { SVGProps } from 'react';

const VerticalDotsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.8397 9.16031L10.6629 9.33709L10.8397 9.16031C10.617 8.93761 10.3149 8.8125 10 8.8125C9.76514 8.8125 9.53554 8.88215 9.34026 9.01263C9.14498 9.14311 8.99277 9.32858 8.90289 9.54556C8.81301 9.76255 8.7895 10.0013 8.83532 10.2317C8.88114 10.462 8.99424 10.6736 9.16031 10.8397C9.32639 11.0058 9.53798 11.1189 9.76833 11.1647C9.99868 11.2105 10.2374 11.187 10.4544 11.0971C10.6714 11.0072 10.8569 10.855 10.9874 10.6597C11.1179 10.4645 11.1875 10.2349 11.1875 10C11.1875 9.68505 11.0624 9.38301 10.8397 9.16031ZM10 5.875C10.2349 5.875 10.4645 5.80535 10.6597 5.67487C10.855 5.54439 11.0072 5.35892 11.0971 5.14194C11.187 4.92495 11.2105 4.68618 11.1647 4.45583C11.1189 4.22548 11.0058 4.01389 10.8397 3.84781C10.6736 3.68174 10.462 3.56864 10.2317 3.52282C10.0013 3.477 9.76255 3.50051 9.54556 3.59039C9.32858 3.68027 9.14311 3.83248 9.01263 4.02776C8.88215 4.22304 8.8125 4.45264 8.8125 4.6875C8.8125 5.00244 8.93761 5.30449 9.16031 5.52719C9.38301 5.74989 9.68506 5.875 10 5.875ZM10 14.125C9.76513 14.125 9.53554 14.1946 9.34026 14.3251C9.14498 14.4556 8.99277 14.6411 8.90289 14.8581C8.81301 15.075 8.7895 15.3138 8.83532 15.5442C8.88114 15.7745 8.99424 15.9861 9.16031 16.1522C9.32639 16.3183 9.53798 16.4314 9.76833 16.4772C9.99868 16.523 10.2374 16.4995 10.4544 16.4096C10.6714 16.3197 10.8569 16.1675 10.9874 15.9722C11.1179 15.777 11.1875 15.5474 11.1875 15.3125C11.1875 14.9976 11.0624 14.6955 10.8397 14.4728C10.617 14.2501 10.3149 14.125 10 14.125Z"
      strokeWidth="0.5"
    />
  </svg>
);

export default VerticalDotsIcon;
