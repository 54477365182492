import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export default function AuthenticatedStatus(): boolean {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null);

  async function isUserAuthenticated() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser !== undefined) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch {
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    void isUserAuthenticated();
  });

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn' || 'autoSignIn' || 'tokenRefresh':
          setIsAuthenticated(true);
          break;
        case 'signOut' || 'signIn_failure' || 'tokenRefresh_failure' || 'autoSignIn_failure':
          setIsAuthenticated(false);
          break;
      }
    };

    Hub.listen('auth', listener);
  });

  return isAuthenticated;
}
