import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';

import { HelpContainer, StyledQuestionMarkIcon, TooltipContent } from './HelpTooltip.styles';

const HelpTooltip = ({ $helpText }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      content={<TooltipContent>{$helpText}</TooltipContent>}
      placement="top"
      visible={isOpen}
      portalClassName="tooltip-wrapper"
      onVisibleChange={setIsOpen}
    >
      <HelpContainer onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
        <StyledQuestionMarkIcon />
      </HelpContainer>
    </Tooltip>
  );
};

export default HelpTooltip;
