import React, { createContext, FC, ReactNode, useContext, useState } from 'react';

type Nullable<T> = T | null;

interface OrganizationContextProps {
  organizationData: Nullable<OrganizationData>;
  organizationId: Nullable<string>;
  organizationSeatsData: Nullable<OrganizationSeatsData>;
  setOrganizationData: (organizationName: OrganizationData) => void;
  setOrganizationId: (organizationId: string) => void;
  setOrganizationSeatsData: (organizationSeats: OrganizationSeatsData) => void;
}

interface OrganizationProviderProps {
  children: ReactNode;
}

interface OrganizationData {
  name: string;
  imageUrl: string;
}

interface OrganizationSeatsData {
  seatsTaken: number;
  totalSeats: number;
}

const OrganizationContext = createContext<OrganizationContextProps>({
  organizationData: null,
  setOrganizationData: undefined,
  organizationId: null,
  setOrganizationId: undefined,
  organizationSeatsData: null,
  setOrganizationSeatsData: undefined,
});

export const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  const [organizationData, setOrganizationData] = useState<Nullable<OrganizationData>>(null);
  const [organizationId, setOrganizationId] = useState<Nullable<string>>(null);
  const [organizationSeatsData, setOrganizationSeatsData] = useState<Nullable<OrganizationSeatsData>>(null);

  return (
    <OrganizationContext.Provider
      value={{
        organizationData,
        setOrganizationData,
        organizationId,
        setOrganizationId,
        organizationSeatsData,
        setOrganizationSeatsData,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext: () => OrganizationContextProps = () => {
  return useContext(OrganizationContext);
};
