import React, { SVGProps } from 'react';

const SortAscendingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="16" width="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.35403 10.6462C7.40052 10.6927 7.4374 10.7478 7.46256 10.8085C7.48772 10.8692 7.50067 10.9343 7.50067 11C7.50067 11.0657 7.48772 11.1308 7.46256 11.1915C7.4374 11.2522 7.40052 11.3073 7.35403 11.3538L5.35403 13.3538C5.30759 13.4002 5.25245 13.4371 5.19175 13.4623C5.13105 13.4874 5.06599 13.5004 5.00028 13.5004C4.93457 13.5004 4.86951 13.4874 4.80881 13.4623C4.74811 13.4371 4.69296 13.4002 4.64653 13.3538L2.64653 11.3538C2.60007 11.3073 2.56322 11.2521 2.53808 11.1914C2.51294 11.1308 2.5 11.0657 2.5 11C2.5 10.9343 2.51294 10.8692 2.53808 10.8086C2.56322 10.7479 2.60007 10.6927 2.64653 10.6462C2.74035 10.5524 2.8676 10.4997 3.00028 10.4997C3.06598 10.4997 3.13103 10.5127 3.19173 10.5378C3.25242 10.5629 3.30757 10.5998 3.35403 10.6462L4.50028 11.7931V3C4.50028 2.86739 4.55296 2.74021 4.64672 2.64645C4.74049 2.55268 4.86767 2.5 5.00028 2.5C5.13289 2.5 5.26006 2.55268 5.35383 2.64645C5.4476 2.74021 5.50028 2.86739 5.50028 3V11.7931L6.64653 10.6462C6.69296 10.5998 6.74811 10.5629 6.80881 10.5377C6.86951 10.5126 6.93457 10.4996 7.00028 10.4996C7.06599 10.4996 7.13105 10.5126 7.19175 10.5377C7.25245 10.5629 7.30759 10.5998 7.35403 10.6462Z"
        fill="#6F6F6F"
      />
      <path
        d="M11.354 2.64616L13.354 4.64615C13.4478 4.73998 13.5006 4.86722 13.5006 4.99991C13.5006 5.13259 13.4478 5.25984 13.354 5.35366C13.2602 5.44748 13.133 5.50018 13.0003 5.50018C12.8676 5.50018 12.7403 5.44748 12.6465 5.35366L11.5003 4.20678V12.9999C11.5003 13.1325 11.4476 13.2597 11.3538 13.3535C11.2601 13.4472 11.1329 13.4999 11.0003 13.4999C10.8677 13.4999 10.7405 13.4472 10.6467 13.3535C10.553 13.2597 10.5003 13.1325 10.5003 12.9999V4.20678L9.35403 5.35366C9.26021 5.44748 9.13296 5.50018 9.00028 5.50018C8.8676 5.50018 8.74035 5.44748 8.64653 5.35366C8.55271 5.25984 8.5 5.13259 8.5 4.99991C8.5 4.86722 8.55271 4.73998 8.64653 4.64615L10.6465 2.64616C10.693 2.59967 10.7481 2.56279 10.8088 2.53763C10.8695 2.51246 10.9346 2.49951 11.0003 2.49951C11.066 2.49951 11.131 2.51246 11.1917 2.53763C11.2524 2.56279 11.3076 2.59967 11.354 2.64616Z"
        fill="white"
      />
    </svg>
  );
};

export default SortAscendingIcon;
