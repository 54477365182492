import React from 'react';
import { SVGProps } from 'react';

const CardsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5 4.5H2.5C2.23478 4.5 1.98043 4.60536 1.79289 4.79289C1.60536 4.98043 1.5 5.23478 1.5 5.5V12.5C1.5 12.7652 1.60536 13.0196 1.79289 13.2071C1.98043 13.3946 2.23478 13.5 2.5 13.5H11.5C11.7652 13.5 12.0196 13.3946 12.2071 13.2071C12.3946 13.0196 12.5 12.7652 12.5 12.5V5.5C12.5 5.23478 12.3946 4.98043 12.2071 4.79289C12.0196 4.60536 11.7652 4.5 11.5 4.5ZM11.5 12.5H2.5V5.5H11.5V12.5ZM14.5 3.5V11C14.5 11.1326 14.4473 11.2598 14.3536 11.3536C14.2598 11.4473 14.1326 11.5 14 11.5C13.8674 11.5 13.7402 11.4473 13.6464 11.3536C13.5527 11.2598 13.5 11.1326 13.5 11V3.5H4C3.86739 3.5 3.74021 3.44732 3.64645 3.35355C3.55268 3.25979 3.5 3.13261 3.5 3C3.5 2.86739 3.55268 2.74021 3.64645 2.64645C3.74021 2.55268 3.86739 2.5 4 2.5H13.5C13.7652 2.5 14.0196 2.60536 14.2071 2.79289C14.3946 2.98043 14.5 3.23478 14.5 3.5Z" />
    </svg>
  );
};

export default CardsIcon;
