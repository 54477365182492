import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../locale';
import { twoDecimalsNumberFormat } from '../utils';
import { KeyText, Row, TooltipContainer, ValueKey, ValueText } from './ChartTooltip.styles';

interface ChartTooltipProps {
  bucketKey: string;
  count: number;
  percentage?: number;
  cumulative?: number;
}
const ChartTooltip = ({ bucketKey, count, percentage, cumulative }: ChartTooltipProps) => {
  const { t } = useTranslation();
  return (
    <TooltipContainer>
      <KeyText>{bucketKey}</KeyText>
      <Row>
        <ValueKey>{t('common.count')}</ValueKey>
        <ValueText>{i18n.format(count, 'number', undefined, twoDecimalsNumberFormat)}</ValueText>
      </Row>
      {percentage && (
        <Row>
          <ValueKey>{t('common.percentage')}</ValueKey>
          <ValueText>{percentage}%</ValueText>
        </Row>
      )}
      {cumulative && (
        <Row>
          <ValueKey>{t('common.cumulative')}</ValueKey>
          <ValueText>{i18n.format(cumulative, 'number', undefined, twoDecimalsNumberFormat)}</ValueText>
        </Row>
      )}
    </TooltipContainer>
  );
};

export default ChartTooltip;
