import { ApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { createClient } from '../graphql';
import AuthenticatedStatus from '../hooks/IsUserAuthenticated';
import { errorToast } from '../utils/CustomToasts/CustomToasts';

type Nullable<T> = T | null;

export interface AuthContextProps {
  loading: boolean;
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  wasLoggedIn?: boolean;
  client?: ApolloClient<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

/* eslint-disable */
const defaultProps: AuthContextProps = {
  loading: null,
  isAuthenticated: null,
  logout: null,
  wasLoggedIn: null,
  client: null,
};
/* eslint-enable */

const AuthContext = createContext<AuthContextProps>(defaultProps);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState(true);

  const [wasLoggedIn, setWasLoggedIn] = useState(false);
  const isAuthenticated = AuthenticatedStatus();
  const [apolloClient, setApolloClient] = useState<Nullable<ApolloClient<any>>>(null);

  const showError = (error: string) => {
    errorToast(error);
  };

  const handleError = (error: string, code?: string) => {
    if (code !== 'UNAUTHENTICATED') {
      showError(error);
      // don't show a toast for UNAUTHENTICATED errors
      return;
    }
  };

  const getClient = () => {
    setApolloClient(createClient(handleError, isAuthenticated));
  };

  useEffect(() => {
    void getClient();
    if (isAuthenticated !== null) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const logout = async () => {
    try {
      await Promise.allSettled([apolloClient.clearStore(), Auth.signOut()]);
    } catch (err: any) {
      console.error({
        message: 'Error while logging out',
        error: err?.stack || err,
      });
    }
    localStorage.clear();
    setWasLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        wasLoggedIn,
        logout,
        client: apolloClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => AuthContextProps = () => {
  return useContext(AuthContext);
};
