import { Dropdown as NextDropdown } from '@nextui-org/react';
import React, { Key, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { ChevronIcon } from '../../assets';
import HelpTooltip from '../HelpTooltip';
import {
  Container,
  DropDownButton,
  DropDownItemContainer,
  DropDownItemDescription,
  DropDownItemText,
  DropDownMenu,
  StyledDropdown,
  Title,
} from './Dropdown.styles';

export interface DropdownItemProps {
  text: string;
  value?: any;
  description?: string;
  selected?: boolean;
}

interface DropDownProps extends React.ComponentPropsWithoutRef<typeof Container> {
  title?: string;
  items: DropdownItemProps[];
  selectedIndex?: number;
  placeholder?: string;
  multiselect?: boolean;
  helpText?: string;
  disabled?: boolean;
  onSelectionChange?: (selection: number | number[]) => void;
  showSeparators?: boolean;
}

const Dropdown = ({
  items,
  title,
  selectedIndex,
  placeholder,
  multiselect,
  helpText,
  disabled,
  onSelectionChange,
  showSeparators,
  ...rest
}: DropDownProps) => {
  const [selected, setSelected] = useState(
    new Set<Key>(selectedIndex !== undefined ? [items[selectedIndex].text] : []),
  );
  const buttonRef = useRef<HTMLElement>();
  const [buttonWidth, setButtonWidth] = useState(0);

  const selectedValue = useMemo(() => Array.from(selected).join(', '), [selected]);

  const onLocalSelectionChange = (keys: 'all' | Set<Key>) => {
    if (keys === 'all') return;

    setSelected(keys);
    const selectedIndexes = Array.from(keys || []).map((key) => items.findIndex((item) => item.text === key));
    onSelectionChange?.(multiselect ? selectedIndexes : selectedIndexes[0]);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setButtonWidth(buttonRef.current.offsetWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Container {...rest}>
      {title && (
        <Title>
          {title}
          {helpText && <HelpTooltip $helpText={helpText} />}
        </Title>
      )}
      <StyledDropdown isDisabled={disabled}>
        <DropDownButton
          disabled={disabled}
          $noOptionsSelected={selected?.size === 0}
          ref={buttonRef}
          iconRight={<ChevronIcon />}
        >
          {selectedValue || placeholder || ''}
        </DropDownButton>
        <DropDownMenu
          width={buttonWidth}
          selectionMode={multiselect ? 'multiple' : 'single'}
          disallowEmptySelection={!multiselect}
          selectedKeys={selected}
          onSelectionChange={onLocalSelectionChange}
        >
          {items.map((item, index) => (
            <NextDropdown.Item key={item.text} withDivider={index !== 0 && showSeparators}>
              <DropDownItemContainer>
                <DropDownItemText>{item.text}</DropDownItemText>
                {item.description && <DropDownItemDescription>{item.description}</DropDownItemDescription>}
              </DropDownItemContainer>
            </NextDropdown.Item>
          ))}
        </DropDownMenu>
      </StyledDropdown>
    </Container>
  );
};

export default Dropdown;
