import React from 'react';
import { DefaultToastOptions, toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  CustomErrorToastContainer,
  CustomSuccessToastContainer,
  CustomToastContainer,
  CustomToastText,
  StyledCheckToastIcon,
  StyledCrossToastIcon,
} from './CustomToasts.styles';

const ToastCommonStructure = ({
  text,
  toastProps,
  icon,
}: {
  text: string;
  toastProps: DefaultToastOptions;
  icon?: JSX.Element;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {icon}
      <CustomToastText>{text}</CustomToastText>
      <button onClick={() => toast.dismiss(toastProps.id)}>{t('components.customToast.dismissButtonLabel')}</button>
    </>
  );
};

export function successToast(text: string) {
  return toast((t: DefaultToastOptions) => (
    <CustomSuccessToastContainer>
      <ToastCommonStructure text={text} toastProps={t} icon={<StyledCheckToastIcon />} />
    </CustomSuccessToastContainer>
  ));
}

export function errorToast(text: string) {
  return toast((t: DefaultToastOptions) => (
    <CustomErrorToastContainer>
      <ToastCommonStructure text={text} toastProps={t} icon={<StyledCrossToastIcon />} />
    </CustomErrorToastContainer>
  ));
}

export function customToast(text: string, icon?: JSX.Element) {
  return toast((t: DefaultToastOptions) => (
    <CustomToastContainer>
      <ToastCommonStructure text={text} toastProps={t} icon={icon} />
    </CustomToastContainer>
  ));
}
