import { createGlobalStyle } from 'styled-components';

import { SCROLLBAR_WIDTH } from '../constants';
import { ThemeProp } from './Themes';

export const GlobalStyles = createGlobalStyle<ThemeProp>`
  body {
    background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c800};
    color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c800};
    margin: 0; 
    padding: 0;
    position: relative;
    scrollbar-width: thin;
     scrollbar-color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c600} ${({
  theme,
}: ThemeProp): string => theme.colors.neutrals.c800};
  }
  
  h4 {
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    font-size: 16px;
  }
  
  .rjsf-form {
    width: 100%;
  }
  
  input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }

  .nextui-popover-content-container {
    border-radius: 0;
    box-shadow: 0px 2px 6px rgba(22, 22, 22, 0.5) !important;
    background-color: transparent !important;
    max-height: unset !important;

    &.nextui-popover-content-enter {
      animation-duration: 0;
    }
  }

  @keyframes fadeAnimation {
    0% {
    opacity: 0;
    },
    60%: {
      opacity: 0.65;
    },
    100% {
      opacity: 1;
    },
  }

  .nextui-popover-content {
    .nextui-table-container {
      box-shadow: none;
    }
    animation-name: fadeAnimation;
    animation-timing-function: ease-out;
    animation-duration: 250ms;
  }

  .tooltip-wrapper, .tooltip-pixel-disabled {
    padding: 0.5rem;
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    max-width: 15rem;
    color: ${(props: ThemeProp) => props.theme.colors.primary.c100};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
    }

  }

  .tooltip-kpi-info, .tooltip-kpi-type {
    border-radius: 0.125rem;
    padding: 0.5rem;
    max-width: 13.5rem;
    z-index: 10000;
    width: 100%;
    text-align: center;
    background: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    }
  }

  .tooltip-small-info {
    max-width: 12rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.125rem;
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.fontSize};
    line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.lineHeight};

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    }
  }

  .tooltip-kpi-type{
    width: unset;
  }

  .tooltip-pixel-disabled {
    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
      max-width: 12rem;
    }
  }

  .nextui-tooltip-button {
    width: unset;
  }

  #nextui-modal {
    .nextui-modal {
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
      overflow: unset;
    }
    .nextui-backdrop {
      backdrop-filter: blur(2px);
    }
  }

  &::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH};
    background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}0D;
    &:hover {
      background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}33;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c600};
    &:hover {
      background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c300};
    }
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c600} ${({ theme }: ThemeProp): string =>
  theme.colors.neutrals.c050}0D;
  }
  &::-webkit-scrollbar:horizontal {
    height: ${SCROLLBAR_WIDTH};
    background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}0D;
    &:hover {
      background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}33;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}0D;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c050}33;
  }
`;

export default GlobalStyles;
