import { Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import Link from '../Link';

export const TabsInnerContainer = styled.div`
  overflow-x: auto;
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  display: flex;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    height: 0px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Tab = styled(Link).attrs((props) => {
  const isActive = props.status === 'active';
  const color = isActive ? '$text' : '$grayScaleText';
  return {
    ...props,
    css: {
      ...props.css,
      '&': {
        ...props.css?.['&'],
        color,
        padding: '0.5rem 0.25rem',
        fontSize: props.theme.fontVariants.mobile.buttons.sm.fontSize,
        lineHeight: props.theme.lineHeight.m,
        fontFamily: '$dmSans',
        fontWeight: '$medium',
        borderBottom: isActive ? '2px solid white' : '2px solid transparent',
        marginRight: '1rem',
        zIndex: '2',
        whiteSpace: 'nowrap',
        svg: {
          path: {
            fill: color,
          },
        },
      },
    },
  };
})`
  svg {
    margin: auto 0.5rem auto 0;
  }
`;

export const Title = styled.div`
  margin: auto 0;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  min-width: 100vw;
`;

export const PageContainer = styled.div`
  width: 100%;
  padding: 2rem 5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`;
