import React, { SVGProps } from 'react';

const BlackBookLoadingPageLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="207" height="44" viewBox="0 0 207 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1186_9380)">
      <path
        d="M195.63 0H0L10.63 43.11H206.26L195.63 0ZM193.65 2.53L203.03 40.58H12.61L3.23 2.53H193.64H193.65Z"
        fill="white"
      />
      <path
        d="M26.01 24.46C26.01 23.12 25 22.57 23.66 22.57H21.06V26.34H23.66C25 26.34 26.01 25.79 26.01 24.45M23.15 20.25C24.51 20.25 25.28 19.74 25.28 18.5C25.28 17.26 24.35 16.75 23.15 16.75H21.06V20.24H23.15V20.25ZM18.63 14.45H23.24C25.55 14.45 27.77 15.53 27.77 18.51C27.77 20.23 26.49 21.07 26.29 21.13C26.49 21.17 28.5 21.9 28.5 24.46C28.5 27.02 26.43 28.66 23.75 28.66H18.64V14.46L18.63 14.45Z"
        fill="white"
      />
      <path d="M35.43 14.45H37.86V26.34H44.8L43.91 28.66H35.43V14.45Z" fill="white" />
      <path
        d="M55.26 23.06H59.52L57.39 17.52L55.26 23.06ZM56.15 14.46H58.63L64.15 28.66H61.69L60.39 25.29H54.38L53.08 28.66H50.62L56.14 14.46H56.15Z"
        fill="white"
      />
      <path
        d="M81.21 24.88L82.83 26.48C82.83 26.48 80.96 28.9 77.37 28.9C73.27 28.9 70.17 25.75 70.17 21.55C70.17 17.35 73.27 14.2 77.37 14.2C80.78 14.2 82.63 16.43 82.63 16.43L80.99 18.03C80.99 18.03 79.71 16.47 77.38 16.47C74.58 16.47 72.65 18.8 72.65 21.54C72.65 24.28 74.58 26.61 77.38 26.61C79.88 26.61 81.22 24.87 81.22 24.87"
        fill="white"
      />
      <path
        d="M92.51 20.83L97.58 14.45H100.62L95.06 21.43L101.31 28.66H98.09L92.51 22.25V28.66H90.07V14.45H92.51V20.83Z"
        fill="white"
      />
      <path
        d="M124.2 24.46C124.2 23.12 123.18 22.57 121.85 22.57H119.25V26.34H121.85C123.19 26.34 124.2 25.79 124.2 24.45M121.34 20.25C122.7 20.25 123.47 19.74 123.47 18.5C123.47 17.26 122.54 16.75 121.34 16.75H119.25V20.24H121.34V20.25ZM116.81 14.45H121.42C123.73 14.45 125.95 15.53 125.95 18.51C125.95 20.23 124.67 21.07 124.47 21.13C124.67 21.17 126.68 21.9 126.68 24.46C126.68 27.02 124.61 28.66 121.93 28.66H116.82V14.46L116.81 14.45Z"
        fill="white"
      />
      <path
        d="M145.24 21.56C145.24 18.82 143.39 16.53 140.53 16.53C137.67 16.53 135.82 18.82 135.82 21.56C135.82 24.3 137.67 26.59 140.53 26.59C143.39 26.59 145.24 24.3 145.24 21.56ZM147.72 21.56C147.72 25.76 144.68 28.91 140.54 28.91C136.4 28.91 133.36 25.76 133.36 21.56C133.36 17.36 136.4 14.21 140.54 14.21C144.68 14.21 147.72 17.36 147.72 21.56Z"
        fill="white"
      />
      <path
        d="M166.36 21.56C166.36 18.82 164.51 16.53 161.65 16.53C158.79 16.53 156.94 18.82 156.94 21.56C156.94 24.3 158.79 26.59 161.65 26.59C164.51 26.59 166.36 24.3 166.36 21.56ZM168.84 21.56C168.84 25.76 165.8 28.91 161.66 28.91C157.52 28.91 154.48 25.76 154.48 21.56C154.48 17.36 157.52 14.21 161.66 14.21C165.8 14.21 168.84 17.36 168.84 21.56Z"
        fill="white"
      />
      <path
        d="M178.83 20.83L183.9 14.45H186.95L181.39 21.43L187.64 28.66H184.41L178.83 22.25V28.66H176.4V14.45H178.83V20.83Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1186_9380">
        <rect width="206.26" height="43.11" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BlackBookLoadingPageLogo;
