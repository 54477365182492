import React, { ChangeEventHandler } from 'react';

import { uploadFile } from '../../utils/Amplify';

type FileUploaderProps = {
  inputRef?: React.LegacyRef<HTMLInputElement>;
  setLoading?: (loading: boolean) => void;
  onUploadFinish: (url: string) => void;
  accept?: string;
};

const FileUploader = ({ accept, onUploadFinish, setLoading, inputRef }: FileUploaderProps) => {
  const onFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    void (async () => {
      const file: File = event.target.files[0];
      if (!file) return;
      setLoading?.(true);
      const url = await uploadFile(file);
      if (url) {
        onUploadFinish(url);
      }
      setLoading?.(false);
    })();
  };

  return <input type="file" onChange={onFileChange} hidden ref={inputRef} accept={accept} />;
};

export default FileUploader;
