import React from 'react';

const FilterIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.76776 4.06561C7.74407 5.04191 7.74407 6.62483 6.76776 7.60114C5.79146 8.57745 4.20854 8.57745 3.23223 7.60114C2.25592 6.62483 2.25592 5.04191 3.23223 4.06561C4.20854 3.0893 5.79146 3.0893 6.76776 4.06561"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.6667 5.83341H7.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.7678 12.399C17.7441 13.3753 17.7441 14.9582 16.7678 15.9345C15.7915 16.9108 14.2085 16.9108 13.2322 15.9345C12.2559 14.9582 12.2559 13.3753 13.2322 12.399C14.2085 11.4227 15.7915 11.4227 16.7678 12.399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3.33325 14.1667H12.4999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default FilterIcon;
