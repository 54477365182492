import React, { SVGProps } from 'react';

const FiltersIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={159} height={122} fill="none" {...props}>
    <g filter="url(#a)">
      <ellipse
        cx={11.532}
        cy={20.408}
        fill="url(#b)"
        rx={11.532}
        ry={20.408}
        transform="matrix(.8055 -.59258 .86786 .4968 36.469 50.668)"
      />
    </g>
    <g filter="url(#c)">
      <ellipse cx={79.5} cy={65} fill="url(#d)" rx={38.5} ry={16} />
    </g>
    <g filter="url(#e)">
      <rect
        width={48}
        height={48}
        x={56.113}
        y={41}
        fill="#262626"
        fillOpacity={0.8}
        rx={24}
        shapeRendering="crispEdges"
      />
      <path
        fill="#fff"
        d="M86.863 65a.75.75 0 0 1-.75.75h-12a.75.75 0 1 1 0-1.5h12a.75.75 0 0 1 .75.75Zm3-5.25h-19.5a.75.75 0 1 0 0 1.5h19.5a.75.75 0 1 0 0-1.5Zm-7.5 9h-4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={121.71}
        height={106.155}
        x={2.614}
        y={0.895}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_6576_108044" stdDeviation={20.425} />
      </filter>
      <filter
        id="c"
        width={158.702}
        height={113.702}
        x={0.149}
        y={8.149}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_6576_108044" stdDeviation={20.425} />
      </filter>
      <filter
        id="e"
        width={56.69}
        height={58.863}
        x={51.768}
        y={38.828}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.086} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6576_108044" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4.345} />
        <feGaussianBlur stdDeviation={2.173} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend in2="effect1_backgroundBlur_6576_108044" result="effect2_dropShadow_6576_108044" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="effect2_dropShadow_6576_108044" result="effect3_dropShadow_6576_108044" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_6576_108044" result="shape" />
      </filter>
      <linearGradient id="b" x1={11.532} x2={11.532} y1={0} y2={40.815} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF5100" stopOpacity={0.9} />
        <stop offset={1} stopColor="#FF5100" />
      </linearGradient>
      <linearGradient id="d" x1={105.808} x2={86.593} y1={79.667} y2={29.149} gradientUnits="userSpaceOnUse">
        <stop offset={0.373} stopColor="#30D9C0" />
        <stop offset={1} stopColor="#145D52" />
      </linearGradient>
    </defs>
  </svg>
);

export default FiltersIllustration;
