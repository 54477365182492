import styled from 'styled-components';

import { CalendarIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Input from '../Input';

export const StyledInput = styled(Input)`
  .nextui-input-content {
    width: 1.25rem;
  }
  .nextui-input {
    margin-left: 0.75rem;
  }
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.primary.c400};
`;
