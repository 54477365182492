export enum CANADA_PROVINCES {
  AB = 'Alberta',
  BC = 'British Columbia',
  MB = 'Manitoba',
  NB = 'New Brunswick',
  NL = 'Newfoundland and Labrador',
  NS = 'Nova Scotia',
  NT = 'Northwest Territories',
  NU = 'Nunavut',
  ON = 'Ontario',
  PE = 'Prince Edward Island',
  QC = 'Quebec',
  SK = 'Saskatchewan',
  YT = 'Yukon',
}

export const PROVINCES_WITH_NAME = [
  {
    name: 'Alberta',
    abbreviation: 'AB',
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC',
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB',
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL',
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS',
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT',
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU',
  },
  {
    name: 'Ontario',
    abbreviation: 'ON',
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE',
  },
  {
    name: 'Quebec',
    abbreviation: 'QC',
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK',
  },
  {
    name: 'Yukon',
    abbreviation: 'YT',
  },
];
