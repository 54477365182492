import { Dropdown } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  margin: 0 0 0.25rem 0rem;
  display: flex;
  align-items: center;
`;

export const StyledDropdown = styled(Dropdown).attrs((props) => ({
  ...props,
  offset: 8,
}))``;

const calculateDropdownColor = (isDisabled: boolean, isPlaceholder: boolean, props: any) => {
  if (isDisabled) return props.theme.colors.neutrals.c600;
  if (isPlaceholder) return props.theme.colors.neutrals.c400;
  return props.theme.colors.neutrals.white;
};

export const DropDownButton = styled(Dropdown.Button).attrs((props) => {
  const isDisabled = props.disabled;
  const isPlaceholder = props.$noOptionsSelected;

  return {
    css: {
      color: props.theme.colors.neutrals.c400,
      'border-width': isDisabled ? '0' : '1px',
      'border-radius': 0,
      'border-color': props.theme.colors.neutrals.c500,
      'padding-left': '$6',
      'padding-right': '$6',
      fontSize: '$input',
      fontWeight: '$normal',
      '&.nextui-dropdown-button': {
        justifyContent: 'space-between',
        background: isDisabled ? `${props.theme.colors.neutrals.c600 as string}99` : 'transparent',
        color: calculateDropdownColor(isDisabled as boolean, isPlaceholder as boolean, props),
        'border-width': isDisabled ? '0' : '1px',
        'border-color': props.theme.colors.neutrals.c500,
      },
      '&.nextui-dropdown-button[aria-haspopup="true"].nextui-dropdown-button[aria-expanded="true"]': {
        transform: 'unset',
      },
      '.nextui-button-text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        color: isDisabled ? props.theme.colors.neutrals.c300 : 'unset',
      },
      '.nextui-button-icon-right': {
        stroke: isDisabled ? props.theme.colors.neutrals.c300 : props.theme.colors.neutrals.white,
      },
      minWidth: 'unset',
      minHeight: 'unset',
      ...props.css,
    },
    borderRadius: 0,
    bordered: true,
    ...props,
  };
})``;

interface DropDownMenuProps {
  width: number;
}

export const DropDownMenu = styled(Dropdown.Menu).attrs((props) => ({
  css: {
    ...props.css,
    'border-radius': '5.5px',
  },
}))<DropDownMenuProps>`
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  max-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: ${(props: DropDownMenuProps) => props.width}px;
  border-radius: 0;

  & .nextui-dropdown-item {
    height: unset;
    padding: 0 0.5rem;
    border-radius: 0;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};

    // Styles of the divider
    &::before {
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
      margin: 0 0.5rem;
    }

    &:hover {
      background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
    }
    &:focus {
      background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
    }
  }

  .nextui-dropdown-item-content {
    overflow: hidden;
  }

  & .is-focused {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  }
`;

export const DropDownItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 2rem;
`;

export const DropDownItemText = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownItemDescription = styled(DropDownItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.primary.c300};
  font-style: italic;
`;
