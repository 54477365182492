import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import Button, { ButtonProps } from '..';

const SecondaryButton = styled(Button)`
  color: ${(props: ThemeProp & ButtonProps) => props.color || props.theme.colors.neutrals.white};
  border: 1px solid ${(props: ThemeProp & ButtonProps) => props.theme.colors.neutrals.white};
  background: transparent;

  svg {
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  }

  &:hover {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    svg {
      stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
      path {
        fill: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
      }
    }
  }

  &:disabled {
    border: none;
  }
`;

export default SecondaryButton;
