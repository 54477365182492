import React, { SVGProps } from 'react';

const SortDescendingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="16" width="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.35354 10.6462C7.40003 10.6927 7.43691 10.7478 7.46207 10.8085C7.48723 10.8692 7.50018 10.9343 7.50018 11C7.50018 11.0657 7.48723 11.1308 7.46207 11.1915C7.43691 11.2522 7.40003 11.3073 7.35354 11.3538L5.35354 13.3538C5.3071 13.4002 5.25196 13.4371 5.19126 13.4623C5.13056 13.4874 5.0655 13.5004 4.99979 13.5004C4.93408 13.5004 4.86902 13.4874 4.80832 13.4623C4.74762 13.4371 4.69248 13.4002 4.64604 13.3538L2.64604 11.3538C2.59958 11.3073 2.56273 11.2521 2.53759 11.1914C2.51245 11.1308 2.49951 11.0657 2.49951 11C2.49951 10.9343 2.51245 10.8692 2.53759 10.8086C2.56273 10.7479 2.59958 10.6927 2.64604 10.6462C2.73986 10.5524 2.86711 10.4997 2.99979 10.4997C3.06549 10.4997 3.13054 10.5127 3.19124 10.5378C3.25193 10.5629 3.30708 10.5998 3.35354 10.6462L4.49979 11.7931V3C4.49979 2.86739 4.55247 2.74021 4.64624 2.64645C4.74 2.55268 4.86718 2.5 4.99979 2.5C5.1324 2.5 5.25957 2.55268 5.35334 2.64645C5.44711 2.74021 5.49979 2.86739 5.49979 3V11.7931L6.64604 10.6462C6.69248 10.5998 6.74762 10.5629 6.80832 10.5377C6.86902 10.5126 6.93408 10.4996 6.99979 10.4996C7.0655 10.4996 7.13056 10.5126 7.19126 10.5377C7.25196 10.5629 7.3071 10.5998 7.35354 10.6462Z"
        fill="white"
      />
      <path
        d="M11.354 2.64616L13.354 4.64615C13.4478 4.73998 13.5006 4.86722 13.5006 4.99991C13.5006 5.13259 13.4478 5.25984 13.354 5.35366C13.2602 5.44748 13.133 5.50018 13.0003 5.50018C12.8676 5.50018 12.7403 5.44748 12.6465 5.35366L11.5003 4.20678V12.9999C11.5003 13.1325 11.4476 13.2597 11.3538 13.3535C11.2601 13.4472 11.1329 13.4999 11.0003 13.4999C10.8677 13.4999 10.7405 13.4472 10.6467 13.3535C10.553 13.2597 10.5003 13.1325 10.5003 12.9999V4.20678L9.35403 5.35366C9.26021 5.44748 9.13296 5.50018 9.00028 5.50018C8.8676 5.50018 8.74035 5.44748 8.64653 5.35366C8.55271 5.25984 8.5 5.13259 8.5 4.99991C8.5 4.86722 8.55271 4.73998 8.64653 4.64615L10.6465 2.64616C10.693 2.59967 10.7481 2.56279 10.8088 2.53763C10.8695 2.51246 10.9346 2.49951 11.0003 2.49951C11.066 2.49951 11.131 2.51246 11.1917 2.53763C11.2524 2.56279 11.3076 2.59967 11.354 2.64616Z"
        fill="#6F6F6F"
      />
    </svg>
  );
};

export default SortDescendingIcon;
