import 'react-phone-input-2/lib/style.css';

import { Container as NextContainer } from '@nextui-org/react';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import ChevronUrl from '../../assets/chevron.svg';
import Input from '../Input';
import Text from '../Text';

export const Container = styled(NextContainer).attrs((props) => ({
  ...props,
  display: 'flex',
  direction: 'column',
  css: {
    ...props.css,
    gap: '$xs',
    marginBottom: props.$noMargin ? '' : '$8',
    marginRight: 0,
    marginLeft: 0,
    padding: 0,
    maxWidth: 'unset !important',
  },
}))``;

export const Label = styled(Text).attrs((props) => ({
  ...props,
  css: {
    ...props.css,
    color: props.theme.colors.neutrals.c200,
    fontFamily: '$sans',
    fontSize: '$sm',
    lineHeight: '$xs',
    letterSpacing: 'normal',
    '&:after': {
      content: props.$required ? (props.$requiredLabel ? props.$requiredLabel : '*') : '',
      paddingLeft: '0.25rem',
      fontStyle: 'italic',
      color: props.theme.colors.neutrals.c400,
      fontSize: '$xs',
    },
  },
}))``;

export const PhoneInputContainer = styled(NextContainer).attrs((props) => ({
  ...props,
  display: 'flex',
  wrap: 'nowrap',
  css: {
    ...props.css,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '$md',
    padding: 0,
    transition: 'none',
    '& .react-tel-input.phone-container': {
      // 2px to match the regular input height
      height: 'calc($15 + 2px)',
      maxWidth: '$31',
    },
    '& .react-tel-input.phone-container .selected-flag': {
      paddingLeft: '$sm',
    },
    '& .react-tel-input.phone-container .selected-flag:focus': {
      backgroundColor: 'transparent',
    },
    '& .react-tel-input.phone-container .selected-flag:hover': {
      backgroundColor: 'transparent',
    },
    '& .react-tel-input.phone-container .selected-flag.open': {
      backgroundColor: 'transparent',
    },
    '& .react-tel-input.phone-container .form-control': {
      color: '$grayScaleText',
      border: `$borderWeights$normal solid ${props.error ? '$inputErrorBorder' : '$inputBorder'}`,
      height: '$15',
      fontFamily: '$sans',
      fontSize: '$input',
      lineHeight: '$xs',
      maxWidth: '$31',
      position: 'unset',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
    '& .react-tel-input.phone-container .flag-dropdown': {
      backgroundColor: 'transparent',
      borderTopLeftRadius: props.theme.borderRadius,
      borderBottomLeftRadius: props.theme.borderRadius,
      borderRight: 'none',
      border: 0,
    },
    '& .react-tel-input.phone-container .flag-dropdown.open .selected-flag': {
      border: 0,
    },
    '& .react-tel-input.phone-container .arrow': {
      backgroundImage: `url(${ChevronUrl})`,
      backgroundSize: '$6 $3',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      border: 0,
      left: '$20',
      marginTop: '-$6',
      width: '$10',
      height: '$10',
    },
    '& .react-tel-input.phone-container .arrow.up': {
      backgroundImage: `url(${ChevronUrl})`,
      backgroundSize: '$6 $3',
      backgroundPosition: 'center center',
      border: 0,
      left: '$20',
      marginTop: '-$6',
      width: '$10',
      height: '$10',
      transform: 'rotate(180deg)',
    },
    '& .react-tel-input.phone-container .country-list': {
      zIndex: 9,
    },
  },
}))``;

export const StyledPhoneInput = styled(PhoneInput).attrs((props) => ({
  ...props,
  className: 'phone-container',
}))``;

export const StyledInput = styled(Input).attrs((props) => ({
  ...props,
  css: {
    ...props.css,
    flexGrow: 1,
    width: 'unset !important',
    marginBottom: props.$noMargin ? '0 !important' : '$8',
    '& > .nextui-input-container': {
      ...props.css?.['& .nextui-input-container'],
      marginTop: '0 !important',
    },
  },
}))``;
