import React, { SVGProps } from 'react';

const LocationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="20" width="21" fill="none" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.4141 8.66797C16.4141 13.3346 10.4141 17.3346 10.4141 17.3346C10.4141 17.3346 4.41406 13.3346 4.41406 8.66797C4.41406 7.07667 5.0462 5.55055 6.17142 4.42533C7.29664 3.30011 8.82276 2.66797 10.4141 2.66797C12.0054 2.66797 13.5315 3.30011 14.6567 4.42533C15.7819 5.55055 16.4141 7.07667 16.4141 8.66797Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M10.4141 10.668C11.5186 10.668 12.4141 9.77254 12.4141 8.66797C12.4141 7.5634 11.5186 6.66797 10.4141 6.66797C9.30949 6.66797 8.41406 7.5634 8.41406 8.66797C8.41406 9.77254 9.30949 10.668 10.4141 10.668Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LocationIcon;
