import { Modal } from '@nextui-org/react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import styled, { css } from 'styled-components';

import { CloseIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Button from '../Button';
import SecondaryButton from '../Button/SecondaryButton';

export const StyledTitle = styled.span`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.l};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  margin: 0.75rem auto 0.5rem auto;
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  align-items: center;
  background: ${(props: ThemeProp) => props.theme.colors.primary.c200};
  margin-bottom: 0;
`;

export const StyledSubTitle = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.primary.c400};
  text-align: center;
`;

export const StyledModal = styled(Modal)`
  display: flex;
  &.nextui-modal {
    border-radius: 0.375rem;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2.25rem;
  width: 100%;
`;

const customButtonStyle = css`
  height: 2.5rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    height: 3rem;
  }
`;

export const StyledButton = styled(Button)`
  ${customButtonStyle}
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  ${customButtonStyle}
`;

export const StyledBottomSheet = styled(BottomSheet)`
  display: flex;
  align-items: center;
  justify-content: center;

  [data-rsbs-backdrop] {
    z-index: 9998;
  }
  [data-rsbs-overlay] {
    right: unset;
    left: unset;
    z-index: 9999;
    background: ${(props: ThemeProp) => props.theme.colors.primary.c200};
    max-height: calc(100% - 1.5rem);
    border-radius: 12px 12px 0px 0px;
  }
  [data-rsbs-content] {
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    [data-rsbs-overlay] {
      right: 0;
      left: 0;
      max-width: unset;
      width: 100%;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 1.5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 1rem 1.5rem 2rem 1.5rem;
  }
`;

export const FreePlanTag = styled.div`
  padding: 0.125rem 0.5rem;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border-radius: 0.25rem;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.primary.c200};
  color: ${(props: ThemeProp) => props.theme.colors.primary.c400};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xxs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  width: fit-content;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 0.25rem 0.5rem;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.primary.c300};
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
