import React, { SVGProps } from 'react';

const CondenseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.7148 8.08115L12.608 8.18785H12.7589H15.0003C15.1495 8.18785 15.2926 8.24711 15.3981 8.3526C15.5036 8.45809 15.5628 8.60116 15.5628 8.75035C15.5628 8.89953 15.5036 9.04261 15.3981 9.1481C15.2926 9.25358 15.1495 9.31285 15.0003 9.31285H11.2503C11.1012 9.31285 10.9581 9.25358 10.8526 9.1481C10.7471 9.04261 10.6878 8.89953 10.6878 8.75035V5.00035C10.6878 4.85116 10.7471 4.70809 10.8526 4.6026C10.9581 4.49711 11.1012 4.43785 11.2503 4.43785C11.3995 4.43785 11.5426 4.49711 11.6481 4.6026C11.7536 4.70809 11.8128 4.85116 11.8128 5.00035V7.24175V7.39266L11.9195 7.28594L15.8524 3.35235C15.8524 3.35235 15.8524 3.35235 15.8524 3.35235C15.9579 3.2468 16.1011 3.1875 16.2503 3.1875C16.3996 3.1875 16.5428 3.2468 16.6483 3.35235C16.7539 3.45791 16.8132 3.60107 16.8132 3.75035C16.8132 3.89962 16.7539 4.04279 16.6483 4.14834L12.7148 8.08115ZM7.28594 11.9195L7.39266 11.8128H7.24175H5.00035C4.85116 11.8128 4.70809 11.7536 4.6026 11.6481C4.49711 11.5426 4.43785 11.3995 4.43785 11.2503C4.43785 11.1012 4.49711 10.9581 4.6026 10.8526C4.70809 10.7471 4.85116 10.6878 5.00035 10.6878H8.75035C8.89953 10.6878 9.04261 10.7471 9.1481 10.8526C9.25358 10.9581 9.31285 11.1012 9.31285 11.2503V15.0003C9.31285 15.1495 9.25358 15.2926 9.1481 15.3981C9.04261 15.5036 8.89953 15.5628 8.75035 15.5628C8.60116 15.5628 8.45809 15.5036 8.3526 15.3981C8.24711 15.2926 8.18785 15.1495 8.18785 15.0003V12.7589V12.608L8.08115 12.7148L4.14834 16.6483C4.04279 16.7539 3.89962 16.8132 3.75035 16.8132C3.60107 16.8132 3.45791 16.7539 3.35235 16.6483C3.2468 16.5428 3.1875 16.3996 3.1875 16.2503C3.1875 16.1011 3.2468 15.9579 3.35235 15.8524C3.35235 15.8524 3.35235 15.8524 3.35235 15.8524L7.28594 11.9195Z"
      strokeWidth="0.125"
    />
  </svg>
);

export default CondenseIcon;
