import { createTheme } from '@nextui-org/react';

interface Colors {
  primary: ColorVariants;
  neutrals: ColorVariants & { black: string; white: string };
  system: SystemColorVariants;
}

interface ColorVariants {
  c900?: string;
  c800: string;
  c700: string;
  c750?: string;
  c600: string;
  c500: string;
  c400: string;
  c300: string;
  c200: string;
  c100: string;
  c050: string;
}

interface SystemColorVariants {
  error: ColorVariants;
  warning: {
    c500: string;
    c300: string;
    c100: string;
  };
  success: ColorVariants & { c900: string };
  information: {
    c500: string;
    c300: string;
    c100: string;
  };
  overlay: string;
}

interface Gradients {
  primary: string;
  overflowGradient: string;
  shimmer: string;
}

interface FontSize {
  xxs: string;
  xs: string;
  s: string;
  m: string;
  ml: string;
  mlg: string;
  l: string;
  lxl: string;
  xl: string;
  '2xl': string;
  '3xl': string;
}

interface FontWeight {
  regular: number;
  medium: number;
  semibold: number;
  bold: number;
}

interface FontVariants {
  headline: {
    h1: FontVariant;
    h2: FontVariant;
    h3: FontVariant;
    h4: FontVariant;
  };
  body: {
    md: FontVariant;
    sm: FontVariant;
  };
  caption: {
    md: FontVariant;
    mdCaps?: FontVariant;
    sm?: FontVariant;
  };
  buttons: {
    md: FontVariant;
    sm?: FontVariant;
  };
}

interface FontVariant {
  fontSize: string;
  lineHeight: string;
}

export interface Breakpoints {
  smallMobile: string;
  mobile: string;
  xlMobile: string;
  tablet: string;
  desktop: string;
  large: string;
  'extra-large': string;
}

export interface Shadow {
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;
  '2xl': string;
}

export interface Fonts {
  primary: string;
  secondary: string;
}

export interface ThemeType {
  name: string;
  colors: Colors;
  gradients: Gradients;
  fontSize: FontSize;
  lineHeight: FontSize;
  fontWeight: FontWeight;
  fontVariants: {
    desktop: FontVariants;
    mobile: FontVariants;
  };
  breakpoints: Breakpoints;
  fonts: Fonts;
  borderRadius: string;
}

export type ThemeProp = { theme: ThemeType };

export const lightTheme: ThemeType = {
  name: 'Light',
  colors: {
    primary: {
      c900: '#145D52',
      c800: '#197668',
      c700: '#219C89',
      c600: '#2AC6AE',
      c500: '#30D9C0',
      c400: '#56E1CC',
      c300: '#74E7D5',
      c200: '#A0EEE2',
      c100: '#BEF3EB',
      c050: '#EAFBF9',
    },
    neutrals: {
      black: '#161616',
      white: '#FFFFFF',
      c800: '#262626',
      c750: '#303030',
      c700: '#393939',
      c600: '#525252',
      c500: '#6F6F6F',
      c400: '#8D8D8D',
      c300: '#A8A8A8',
      c200: '#C6C6C6',
      c100: '#E0E0E0',
      c050: '#F4F4F4',
    },
    system: {
      error: {
        c800: '#750E13',
        c700: '#A2191F',
        c600: '#DA1E28',
        c500: '#ef4444',
        c400: '#FA4D56',
        c300: '#FF8389',
        c200: '#FFB3B8',
        c100: '#FFD7D9',
        c050: '#FFF1F1',
      },
      warning: {
        c500: '#eab308',
        c300: '#fde047',
        c100: '#fef9c3',
      },
      success: {
        c900: '#022D0D',
        c800: '#044317',
        c700: '#0E6027',
        c600: '#198038',
        c500: '#24A148',
        c400: '#42BE65',
        c300: '#6FDC8C',
        c200: '#A7F0BA',
        c100: '#D1FAE5',
        c050: '#EDFDF5',
      },
      information: {
        c500: '#3b82f6',
        c300: '#bfdbfe',
        c100: '#dbeafe',
      },
      overlay: 'rgba(156, 163, 175, 0.4)',
    },
  },
  gradients: {
    primary: 'linear-gradient(90deg, #5EB4F9 -0.29%, #796DF6 100%)',
    overflowGradient: 'linear-gradient(270deg, #ffffff 0%, #ffffff00 100%)',
    shimmer:
      'linear-gradient(90deg, rgba(141, 141, 141, 0.6) 0%, rgba(111, 111, 111, 0.6) 100%, rgba(111, 111, 111, 0.6) 100%)',
  },
  fonts: {
    primary: 'Inter',
    secondary: 'DM Sans',
  },
  fontSize: {
    xxs: '0.625rem',
    xs: '0.75rem',
    s: '0.875rem',
    m: '0.9375rem',
    ml: '1rem',
    mlg: '1.125rem',
    l: '1.25rem',
    lxl: '1.375rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '2.5rem',
  },
  lineHeight: {
    xxs: '0.875rem',
    xs: '1.25rem',
    s: '1.375rem',
    m: '1.5rem',
    ml: '1.25rem',
    mlg: '1.5rem',
    l: '1.75rem',
    lxl: '2rem',
    xl: '2rem',
    '2xl': '2.5rem',
    '3xl': '3.75rem',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontVariants: {
    desktop: {
      headline: {
        h1: {
          fontSize: '2rem',
          lineHeight: '2.375rem',
        },
        h2: {
          fontSize: '1.5rem',
          lineHeight: '1.8125rem',
        },
        h3: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
        },
        h4: {
          fontSize: '1.125rem',
          lineHeight: '1.375rem',
        },
      },
      body: {
        sm: {
          fontSize: '0.875rem',
          lineHeight: '1.3125rem',
        },
        md: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
      caption: {
        sm: {
          fontSize: '0.625rem',
          lineHeight: '0.9375rem',
        },
        md: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
        },
        mdCaps: {
          fontSize: '0.688rem',
          lineHeight: '1rem',
        },
      },
      buttons: {
        sm: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        md: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
    },
    mobile: {
      headline: {
        h1: {
          fontSize: '1.5rem',
          lineHeight: '1.8125rem',
        },
        h2: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
        },
        h3: {
          fontSize: '1.125rem',
          lineHeight: '1.375rem',
        },
        h4: {
          fontSize: '1rem',
          lineHeight: '1.1875rem',
        },
      },
      body: {
        sm: {
          fontSize: '0.875rem',
          lineHeight: '1.3125rem',
        },
        md: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
      caption: {
        md: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
        },
      },
      buttons: {
        sm: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        md: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
    },
  },
  breakpoints: {
    smallMobile: '375px',
    mobile: '480px',
    xlMobile: '598px',
    tablet: '768px',
    desktop: '1054px',
    large: '1200px',
    'extra-large': '1400px',
  },
  borderRadius: '6px',
};
export interface PropsTheme {
  theme: ThemeType;
}

export const darkTheme: ThemeType = {
  ...lightTheme,
  name: 'Dark',
  gradients: {
    ...lightTheme.gradients,
    shimmer: 'linear-gradient(to right, #4a5064 8%, #3c4456 18%, #4a5064 100%)',
  },
};

const fonts = {
  sans: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
  dmSans: 'DM Sans, sans-serif;',
};

export const nextUIThemeMap = (theme: ThemeType) => {
  return createTheme({
    type: theme.name.toLowerCase(), // it could be "light" or "dark"
    theme: {
      colors: {
        // brand colors
        primaryLight: '$green200',
        primaryLightHover: '$green300',
        primaryLightActive: theme.colors.neutrals.c600,
        primaryLightContrast: '$green600',
        primary: theme.colors.primary.c400,
        primaryBorder: theme.colors.primary.c400,
        primaryBorderHover: theme.colors.primary.c400,
        primarySolidHover: theme.colors.primary.c400,
        primarySolidContrast: theme.colors.neutrals.white,
        primaryShadow: theme.colors.primary.c500,
        gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
        inputBorder: theme.colors.neutrals.c500,
        inputBackground: theme.colors.neutrals.white,
        inputLabelText: theme.colors.neutrals.c200,
        link: theme.colors.primary.c400,
        text: theme.colors.neutrals.white,
        textSecondary: theme.colors.primary.c400,
        grayScaleText: theme.colors.neutrals.c300,
        disabledBackground: theme.colors.neutrals.c700,
        inputErrorBorder: theme.colors.system.error.c400,
        inputErrorIcon: theme.colors.neutrals.white,
      },
      borderWeights: {
        normal: '1px',
      },
      lineHeights: {
        xs2: '1rem',
        xs3: '1.125rem',
        sm2: '1.25rem',
        base2: '1.5rem',
      },
      radii: {
        xxs: '6px',
      },
      space: {
        '6a': '0.8125rem',
        '10a': '1.625rem',
        25: '6.125rem',
        31: '7.875rem',
      },
      fonts,
      fontSizes: {
        checkbox: '$base',
        input: '1rem',
      },
    },
  });
};
