import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { BBox } from '@turf/helpers';

import { AutocompletePlace } from '../../components/PlacesAutocomplete';
import { GeoFilter } from '../../contexts/KPIsAndFilterContext';
// import { GeoFilter as GeoFilterGraphQl, PeopleSnaphostGeoResult, PersonFilterInput } from '../../types/api.graphql';
import { createGeoFilterHelper } from '.';

export interface DrawProps {
  map: mapboxgl.Map;
  drawer: MapboxDraw;
  color: string;
}

export interface MarkerProps {
  map: mapboxgl.Map;
  position: number[];
  markerImg: HTMLImageElement;
}

export abstract class GeoFilterHelper<T = any> {
  protected geoFilter: GeoFilter<T>;
  // public geoFilterGraphQL?: GeoFilterGraphQl;
  public geoFilterGraphQL?: any;

  public constructor(geoFilter?: GeoFilter<T>) {
    this.geoFilter = geoFilter;
  }

  public static isEqualFilters(geoFilter1: GeoFilter, geoFilter2: GeoFilter): boolean {
    if (geoFilter1.type !== geoFilter2.type) return false;
    return createGeoFilterHelper(geoFilter1).isEqual(geoFilter2);
  }

  private static mergeBoundingBoxes(boundingBoxes: BBox[]) {
    let minLeft = 180;
    let minBottom = 90;
    let maxRight = -180;
    let maxTop = -90;

    boundingBoxes.forEach((box) => {
      if (Array.isArray(box) && box.length === 4) {
        const [left, bottom, right, top] = box;
        if (left < minLeft) minLeft = left;
        if (bottom < minBottom) minBottom = bottom;
        if (right > maxRight) maxRight = right;
        if (top > maxTop) maxTop = top;
      }
    });

    return [
      [minLeft, minBottom],
      [maxRight, maxTop],
    ];
  }

  public static filtersBoundingBox(geoFilters: GeoFilter[], isCanada?: boolean) {
    if (geoFilters?.length > 1) {
      const geoFiltersBoundingBoxes = geoFilters.map((filter) => {
        return createGeoFilterHelper(filter).getBoundingBox(isCanada);
      });

      const finalBoundingBox = this.mergeBoundingBoxes(geoFiltersBoundingBoxes);
      return finalBoundingBox;
    } else if (geoFilters?.length === 1) {
      const bbox = createGeoFilterHelper(geoFilters[0]).getBoundingBox(isCanada);
      return bbox;
    }

    return null;
  }

  abstract generateId(): number;
  abstract isEqual(geoFilter: GeoFilter): boolean;
  abstract toGraphQLGeoFilter(skipExcludedZips?: boolean, isCanada?: boolean): any;
  abstract generateIdForGeoFilterGraphQl(isCanada?: boolean): number;
  abstract fromGraphQLGeoFilter(isCanada?: boolean): GeoFilter<T>;
  getLocation(): AutocompletePlace {
    return undefined;
  }
  getFullAddress(): string {
    return undefined;
  }
  getInfoText(): string {
    return undefined;
  }
  abstract getCenter(isCanada?: boolean): { longitude: number; latitude: number };
  abstract getBoundingBox(isCanada?: boolean): BBox;
  abstract drawLayer(props: DrawProps): { layerFillId?: string };
  abstract drawMarker(props: MarkerProps): any;

  geoFilterFromGeoResult = (
    geoResult: any,
    countByPostalCode: object,
    vinsByPostalCode: object,
    totalsByPostalCode: object,
  ): GeoFilter => ({
    ...this.geoFilter,
    id: this.geoFilter.id || this.generateId(),
    zipCodes: geoResult.postalCodes.map((postalCode) => ({
      zip: postalCode,
      count: countByPostalCode[postalCode] || 0,
      tooltipCount: vinsByPostalCode[postalCode] || 0,
      tooltipTotal: totalsByPostalCode[postalCode] || 0,
    })),
    count: geoResult.count,
  });

  geoFilterFromSingleValueGeoResult = (geoResult: any, valueByPostalCode: object): GeoFilter => ({
    ...this.geoFilter,
    id: this.geoFilter.id || this.generateId(),
    zipCodes: geoResult.postalCodes.map((postalCode) => ({
      zip: postalCode,
      value: valueByPostalCode[postalCode] || 0,
    })),
    count: geoResult.count,
  });
}

export default GeoFilterHelper;
