import * as turf from '@turf/turf';
import { Units } from '@turf/turf';

// This is needed to excluding GL JS explicitly from transpilation
// See https://docs.mapbox.com/mapbox-gl-js/guides/install/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { GeoFilter, GeoFilterType } from '../../contexts/KPIsAndFilterContext';
// import { PersonFilterInput } from '../../types/api.graphql';
import { hashCode } from '../helpers';
import { DrawProps, GeoFilterHelper, MarkerProps } from './GeoFilterHelper';

export interface GeoFilterPolygonData {
  polygonCoordinates?: { longitude: number; latitude: number }[];
}

export default class PolygonGeoFilterHelper extends GeoFilterHelper<GeoFilterPolygonData> {
  generateId(): number {
    return hashCode(
      `${this.geoFilter.data.polygonCoordinates?.[0]?.latitude}, ${this.geoFilter.data.polygonCoordinates?.[0]?.longitude}`,
    );
  }

  isEqual(geoFilter: GeoFilter): boolean {
    if (geoFilter.type !== this.geoFilter.type) return false;

    if (geoFilter.data.polygonCoordinates.length !== this.geoFilter.data.polygonCoordinates.length) return false;
    for (let i = 0; i < geoFilter.data.polygonCoordinates.length; i++) {
      if (
        geoFilter.data.polygonCoordinates[i].latitude !== this.geoFilter.data.polygonCoordinates[i].latitude ||
        geoFilter.data.polygonCoordinates[i].longitude !== this.geoFilter.data.polygonCoordinates[i].longitude
      )
        return false;
    }
    if (geoFilter.data.zipCodes?.length !== this.geoFilter.zipCodes?.length) return false;
    for (let i = 0; i < geoFilter.data.zipCodes.length; i++) {
      if (geoFilter.data.zipCodes[i] !== this.geoFilter.zipCodes[i]) return false;
    }
    return true;
  }

  toGraphQLGeoFilter(skipExcludedZips?: boolean): any {
    return {
      geoFilter: {
        operator: {
          polygon: this.geoFilter.data.polygonCoordinates?.map((coordinate) => ({
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
          })),
        },
        ...(!skipExcludedZips && { excludedZipCodes: this.geoFilter.excludedZipCodes }),
      },
    };
  }

  generateIdForGeoFilterGraphQl(): number {
    return hashCode(
      `${this.geoFilterGraphQL.operator.polygon[0]?.latitude as string}, ${
        this.geoFilterGraphQL.operator.polygon[0]?.longitude as string
      }`,
    );
  }

  fromGraphQLGeoFilter(): GeoFilter<GeoFilterPolygonData> {
    const data: GeoFilterPolygonData = {
      polygonCoordinates: this.geoFilterGraphQL.operator.polygon,
    };
    return {
      id: this.generateIdForGeoFilterGraphQl(),
      type: GeoFilterType.Polygon,
      data,
      excludedZipCodes: this.geoFilterGraphQL.excludedZipCodes,
    };
  }

  private generateCenterOfPolygon = () => {
    const polygonCoordinates = this.geoFilter?.data.polygonCoordinates?.map((coord) => {
      return [coord.longitude, coord.latitude];
    });

    const polygon = turf.polygon([polygonCoordinates]);
    return turf.center(polygon);
  };

  getCenter() {
    const center = this.generateCenterOfPolygon();
    return { latitude: center.geometry.coordinates[1], longitude: center.geometry.coordinates[0] };
  }

  getBoundingBox() {
    const polygonCoordinates = this.geoFilter.data.polygonCoordinates?.map((coord) => {
      return [coord.longitude, coord.latitude];
    });

    const center = this.generateCenterOfPolygon();
    let furthestPointFromCenter = polygonCoordinates[0];
    const distanceMax = turf.distance(center, furthestPointFromCenter, { units: 'miles' });
    polygonCoordinates.forEach((coord) => {
      const distance = turf.distance(center, coord, { units: 'miles' });
      if (distance > distanceMax) furthestPointFromCenter = coord;
    });

    const distance = turf.distance(center, furthestPointFromCenter, { units: 'miles' });
    const options = { units: 'miles' as Units };
    const buffered = turf.buffer(center, distance, options);
    return turf.bbox(buffered);
  }

  drawLayer(props: DrawProps) {
    const polygonData = {
      type: 'Feature',
      id: this.geoFilter.id,
      geometry: {
        type: 'Polygon',
        coordinates: [
          this.geoFilter.data.polygonCoordinates.map((coordinate) => [coordinate.longitude, coordinate.latitude]),
        ],
      },
    };
    props.drawer.add(polygonData);
    return {};
  }

  drawMarker(props: MarkerProps) {
    return new mapboxgl.Marker(null).setLngLat(props.position).addTo(props.map);
  }
}
