import { Tooltip } from '@nextui-org/react';
import React from 'react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const StyledTextTooltip = styled(Tooltip).attrs((props: ThemeProp & { css }) => ({
  ...props,
  css: {
    ...props.css,
    div: {
      width: 'max-content',
    },
    background: props.theme.colors.neutrals.black,
    padding: '0.5rem',
    borderRadius: '2px',
    fontSize: props.theme.fontVariants.desktop.caption.md.fontSize,
    lineHeight: props.theme.fontVariants.desktop.caption.md.lineHeight,
    color: props.theme.colors.neutrals.white,

    span: {
      background: props.theme.colors.neutrals.black,
    },
  },
}))`
  min-width: 0%;
`;

export type Placement =
  | 'top'
  | 'topStart'
  | 'topEnd'
  | 'left'
  | 'leftStart'
  | 'leftEnd'
  | 'bottom'
  | 'bottomStart'
  | 'bottomEnd'
  | 'right'
  | 'rightStart'
  | 'rightEnd';

interface TextTooltipProps {
  children: React.ReactElement;
  tooltipText: string;
  isDisabled?: boolean;
  placement?: Placement;
}

const TextTooltip = ({ children, tooltipText, isDisabled, placement }: TextTooltipProps) => {
  return (
    <StyledTextTooltip content={tooltipText} isDisabled={isDisabled} placement={placement || 'top'}>
      {children}
    </StyledTextTooltip>
  );
};

export default TextTooltip;
