import React, { SVGProps } from 'react';

const VinTypeKpiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.75 8.75H17.9062L15.7359 3.86719C15.6377 3.64627 15.4775 3.45858 15.2748 3.32685C15.0721 3.19512 14.8355 3.125 14.5938 3.125H5.40625C5.16448 3.125 4.92791 3.19512 4.72518 3.32685C4.52245 3.45858 4.36228 3.64627 4.26406 3.86719L2.09375 8.75H1.25C1.08424 8.75 0.925268 8.81585 0.808058 8.93306C0.690848 9.05027 0.625 9.20924 0.625 9.375C0.625 9.54076 0.690848 9.69973 0.808058 9.81694C0.925268 9.93415 1.08424 10 1.25 10H1.875V16.25C1.875 16.5815 2.0067 16.8995 2.24112 17.1339C2.47554 17.3683 2.79348 17.5 3.125 17.5H5C5.33152 17.5 5.64946 17.3683 5.88388 17.1339C6.1183 16.8995 6.25 16.5815 6.25 16.25V15H13.75V16.25C13.75 16.5815 13.8817 16.8995 14.1161 17.1339C14.3505 17.3683 14.6685 17.5 15 17.5H16.875C17.2065 17.5 17.5245 17.3683 17.7589 17.1339C17.9933 16.8995 18.125 16.5815 18.125 16.25V10H18.75C18.9158 10 19.0747 9.93415 19.1919 9.81694C19.3092 9.69973 19.375 9.54076 19.375 9.375C19.375 9.20924 19.3092 9.05027 19.1919 8.93306C19.0747 8.81585 18.9158 8.75 18.75 8.75ZM5.40625 4.375H14.5938L16.5383 8.75H3.46172L5.40625 4.375ZM16.875 16.25H15V14.375C15 14.2092 14.9342 14.0503 14.8169 13.9331C14.6997 13.8158 14.5408 13.75 14.375 13.75H5.625C5.45924 13.75 5.30027 13.8158 5.18306 13.9331C5.06585 14.0503 5 14.2092 5 14.375V16.25H3.125V10H16.875V16.25Z" />
    </svg>
  );
};

export default VinTypeKpiIcon;
