import React from 'react';

const ExportIcon = (props: any) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.33691 1.75H12.2536V4.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.16992 5.83333L12.2533 1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.0868 8.16675V11.0834C11.0868 11.728 10.5647 12.2501 9.92009 12.2501H2.92008C2.2755 12.2501 1.75342 11.728 1.75342 11.0834V4.08341C1.75342 3.43883 2.2755 2.91675 2.92008 2.91675H5.83675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExportIcon;
