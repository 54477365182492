import React, { SVGProps } from 'react';

const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.6875 5.25859V5.10768L15.5808 5.2144L11.648 9.148C11.5424 9.25355 11.3993 9.31285 11.25 9.31285C11.1007 9.31285 10.9576 9.25355 10.852 9.14799C10.7465 9.04244 10.6872 8.89928 10.6872 8.75C10.6872 8.60073 10.7465 8.45756 10.852 8.35201C10.852 8.35201 10.852 8.35201 10.852 8.35201L14.7856 4.4192L14.8923 4.3125H14.7414H12.5C12.3508 4.3125 12.2077 4.25324 12.1023 4.14775C11.9968 4.04226 11.9375 3.89918 11.9375 3.75C11.9375 3.60082 11.9968 3.45774 12.1023 3.35225C12.2077 3.24676 12.3508 3.1875 12.5 3.1875H16.25C16.3992 3.1875 16.5423 3.24676 16.6477 3.35225C16.7532 3.45774 16.8125 3.60082 16.8125 3.75V7.5C16.8125 7.64918 16.7532 7.79226 16.6477 7.89775C16.5423 8.00324 16.3992 8.0625 16.25 8.0625C16.1008 8.0625 15.9577 8.00324 15.8523 7.89775C15.7468 7.79226 15.6875 7.64918 15.6875 7.5V5.25859ZM4.3125 14.7414V14.8923L4.4192 14.7856L8.35201 10.852C8.35201 10.852 8.35201 10.852 8.35201 10.852C8.45756 10.7465 8.60073 10.6872 8.75 10.6872C8.89928 10.6872 9.04244 10.7465 9.14799 10.852C9.25355 10.9576 9.31285 11.1007 9.31285 11.25C9.31285 11.3993 9.25355 11.5424 9.148 11.648L5.2144 15.5808L5.10768 15.6875H5.25859H7.5C7.64918 15.6875 7.79226 15.7468 7.89775 15.8523C8.00324 15.9577 8.0625 16.1008 8.0625 16.25C8.0625 16.3992 8.00324 16.5423 7.89775 16.6477C7.79226 16.7532 7.64918 16.8125 7.5 16.8125H3.75C3.60082 16.8125 3.45774 16.7532 3.35225 16.6477C3.24676 16.5423 3.1875 16.3992 3.1875 16.25V12.5C3.1875 12.3508 3.24676 12.2077 3.35225 12.1023C3.45774 11.9968 3.60082 11.9375 3.75 11.9375C3.89918 11.9375 4.04226 11.9968 4.14775 12.1023C4.25324 12.2077 4.3125 12.3508 4.3125 12.5V14.7414Z"
      strokeWidth="0.125"
    />
  </svg>
);

export default ExpandIcon;
