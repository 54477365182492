import { Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface ItemsProps {
  $top?: number;
}
export const Items = styled.div.attrs((props: ItemsProps) => ({
  style: {
    top: `${props.$top}px`,
  },
}))<ItemsProps>`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  box-shadow: 0px 8px 16px rgba(22, 22, 22, 0.2), 0px 3px 10px rgba(22, 22, 22, 0.3);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem;
  position: fixed;
  z-index: 99999;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    position: static;
    border: unset;
    padding: 0;
    box-shadow: none;
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  white-space: nowrap;

  svg {
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  }
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 2;
  gap: 1rem;
  height: 2rem;
  justify-content: space-between;
`;

export const ItemText = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.lineHeight};
`;

export const ItemDescription = styled(ItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;

export const Separator = styled(Divider)`
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c050};
`;

export const BottomSheetContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
