import { GeoFilter, GeoFilterType } from '../../contexts/KPIsAndFilterContext';
// import { GeoFilter as GeoFilterGraphQl } from '../../types/api.graphql';
import CircularGeoFilterHelper, { GeoFilterCircleData } from './CircularGeoFilterHelper';
import GeoFilterHelper from './GeoFilterHelper';
import PolygonGeoFilterHelper, { GeoFilterPolygonData } from './PolygonGeoFilterHelper';
import RegionGeoFilterHelper, { RegionGeoFilterData } from './RegionGeoFilterHelper';

export function createGeoFilterHelper(geoFilter: GeoFilter): GeoFilterHelper {
  switch (geoFilter.type) {
    case GeoFilterType.Circle:
      return new CircularGeoFilterHelper(geoFilter as GeoFilter<GeoFilterCircleData>);
    case GeoFilterType.Polygon:
      return new PolygonGeoFilterHelper(geoFilter as GeoFilter<GeoFilterPolygonData>);
    case GeoFilterType.Region:
      return new RegionGeoFilterHelper(geoFilter as GeoFilter<RegionGeoFilterData>);
  }
}

export function createGeoFilterHelperFromGraphQL(geoFilterGraphQL: any): GeoFilterHelper {
  const instance: GeoFilterHelper = geoFilterGraphQL.operator.polygon
    ? new PolygonGeoFilterHelper()
    : geoFilterGraphQL.operator.distance
    ? new CircularGeoFilterHelper()
    : new RegionGeoFilterHelper();
  instance.geoFilterGraphQL = geoFilterGraphQL;
  return instance;
}

export function createParentGeoFilterHelperFromGraphQL(
  geoFilterGraphQL: any,
  props: Partial<GeoFilter> = undefined,
  isCanada?: boolean,
): GeoFilter {
  const geoFilter = createGeoFilterHelperFromGraphQL(geoFilterGraphQL).fromGraphQLGeoFilter(isCanada);
  return { ...geoFilter, ...props };
}

export default GeoFilterHelper;
