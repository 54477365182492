import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  ServerError,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Auth } from 'aws-amplify';
import { createClient as createWsClient } from 'graphql-ws';

const wsLink = new GraphQLWsLink(
  createWsClient({
    url: process.env.REACT_APP_GRAPHQL_SOCKET_URI,
    connectionParams: async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return {
        Authorization: token,
      };
    },
  }),
);

// don't send queries unless authenticated
let sendQueries = false;

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  split(() => sendQueries, httpLink),
);

type handleErrorType = (message: string, code?: string) => any;
class AuthMiddlewareLink extends ApolloLink {
  request(operation, forward) {
    return new Observable((observer) => {
      let handle;
      void (async () => {
        try {
          // Use Amplify's Auth module to retrieve the token asynchronously
          const token = await Auth.currentSession().then((data) => data.getIdToken().getJwtToken());

          // add the authorization to the headers
          operation.setContext({
            headers: {
              authorization: token || null,
            },
          });

          // Proceed with the request chain
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        } catch (error) {
          // Handle any error that might occur during the token retrieval
          console.error('Error while fetching token:', error);
        }
      })();
      // Cleanup function to unsubscribe from the request chain
      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    });
  }
}

export const refreshToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const currentSession = user.signInUserSession;
    user.refreshSession(currentSession.refreshToken, (error) => {
      if (error) {
        console.error('Error while refreshing token:', error);
      }
    });
  } catch (error) {
    console.error('Error while refreshing token:', error);
  }
};

export const createErrorLink = (handleError: handleErrorType) => {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    graphQLErrors?.forEach(({ message, extensions }) => {
      handleError(message, extensions.code as string);
    });

    if ((networkError as ServerError)?.statusCode === 401) {
      const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
        void (async () => {
          await refreshToken();
          // Retry the failed request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })();
      });

      return observable;
    }

    return null;
  });
};

export const createClient = (onError: handleErrorType, isAuthenticated: boolean) => {
  const errorLink = createErrorLink(onError);
  const links = [errorLink];
  sendQueries = isAuthenticated;
  links.push(new AuthMiddlewareLink());
  links.push(link);
  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            organization: {
              merge(existing = {}, incoming: any) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    connectToDevTools: process.env.REACT_APP_APOLLO_DEVTOOLS === 'true',
  });
};
