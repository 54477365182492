import i18next from 'i18next';
import React from 'react';

import i18n from '../../locale';
import { createUTCDate } from '../../utils/helpers';

const DEFAULT_DATA_LIMIT = 10;
const MIN_BAR_WIDTH = 70;

export const abbreviatedOneDigitNumberFormat = {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 1,
};

export const absoluteNumberFormat = {
  maximumFractionDigits: 0,
};

export const twoDecimalsNumberFormat = {
  maximumFractionDigits: 2,
};

export const getDataLimitForWidth = (width?: number) => {
  return width ? Math.min(Math.round(width / MIN_BAR_WIDTH), DEFAULT_DATA_LIMIT) : DEFAULT_DATA_LIMIT;
};

const formatStringValue = (key: string) => {
  const date = createUTCDate(key);

  // Check if the date is valid
  if (!isNaN(date.getTime())) {
    // Return the formatted date string in MM/DD format for EN-US
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    });
  }

  // Return the original string if it is not a valid date
  return key;
};

const formatRelativeRange = (key: { from: number; to: number } | string) => {
  if (typeof key === 'string') return formatStringValue(key);

  const formatNumber = (number: number) => i18n.format(number, 'number', undefined, abbreviatedOneDigitNumberFormat);
  return `${formatNumber(key.from)}-${formatNumber(key.to)}`;
};

const formatAbsoluteRange = (key: { from: number; to: number } | string) => {
  if (typeof key === 'string') return formatStringValue(key);

  const formatNumber = (number: number) => i18n.format(number, 'number', undefined, absoluteNumberFormat);
  return `${formatNumber(key.from)} - ${formatNumber(key.to)}`;
};

export const sanitizedData = (
  counts: Array<{
    key: { from: number; to: number } | string;
    count: number;
  }>,
  sortByCount = false,
  isOtherFirst = false,
  limit = DEFAULT_DATA_LIMIT,
  isHistogram = false,
): Array<{
  key: string;
  hoverKey: string;
  count: number;
  cumulative?: number;
  percentage?: number;
}> => {
  const sortedData = sortByCount ? [...counts].sort((a, b) => b.count - a.count) : counts;
  const totalCount = sortedData.reduce((acc, curr) => acc + curr.count, 0);

  if (sortedData.length <= limit) {
    if (isHistogram) {
      return sortedData.reduce((acc, curr) => {
        acc.push({
          key: formatRelativeRange(curr.key),
          hoverKey: formatAbsoluteRange(curr.key),
          count: curr.count,
          cumulative: curr.count + ((acc[acc.length - 1]?.cumulative as number) || 0),
          percentage: parseFloat(((curr.count / totalCount) * 100).toFixed(2)),
        });
        return acc;
      }, []);
    }

    return sortedData.map((sd) => ({
      ...sd,
      key: formatRelativeRange(sd.key),
      hoverKey: formatAbsoluteRange(sd.key),
    }));
  }

  if (isHistogram) {
    const cumulative = sortedData.reduce((acc, curr) => {
      acc.push({
        key: formatRelativeRange(curr.key),
        hoverKey: formatAbsoluteRange(curr.key),
        cumulative: curr.count + ((acc[acc.length - 1]?.cumulative as number) || 0),
        count: curr.count,
        percentage: parseFloat(((curr.count / totalCount) * 100).toFixed(2)),
      });
      return acc;
    }, []);
    return cumulative;
  }

  // We use "other" if is not an histogram
  const firstElements = [...sortedData.slice(0, limit - 1)];
  const sumForOthers = sortedData
    .slice(limit - 1, sortedData.length)
    .reduce((partialSum: number, element) => partialSum + element.count, 0);

  const dataOther = {
    key: i18next.t('common.other'),
    hoverKey: i18next.t('common.other'),
    count: sumForOthers,
  };

  const firstElementsMapped = firstElements.map((sd) => ({
    ...sd,
    key: formatRelativeRange(sd.key),
    hoverKey: formatAbsoluteRange(sd.key),
  }));

  return isOtherFirst ? [dataOther, ...firstElementsMapped] : [...firstElementsMapped, dataOther];
};

const maxSymbolsCount = 7;

export const truncatedValue = (v: string, count: number = maxSymbolsCount, shouldAbbreviate?: boolean) => {
  return v.length > count
    ? `${v.substring(0, count)}...`
    : shouldAbbreviate
    ? i18n.format(Number(v), 'number', undefined, abbreviatedOneDigitNumberFormat)
    : v;
};

export const formatValue = (v: string, shouldAbbreviate?: boolean) => {
  // Truncate long values
  return v.length > maxSymbolsCount ? (
    <tspan>
      {truncatedValue(v, undefined, shouldAbbreviate)}
      <title>
        {shouldAbbreviate ? i18n.format(Number(v), 'number', undefined, abbreviatedOneDigitNumberFormat) : v}
      </title>
    </tspan>
  ) : (
    <>{shouldAbbreviate ? i18n.format(Number(v), 'number', undefined, abbreviatedOneDigitNumberFormat) : v}</>
  );
};

const MAXIMUM_AMOUNT_OF_TICKS_TO_SHOW = 5;

export const shouldRenderTickValue = (data, value, formatFunction = undefined) => {
  const index = data?.findIndex((item) => item.key === value);
  const valueIfValid = formatFunction ? formatFunction(value) : formatValue(value as string);
  const valueIfInvalid = '';
  if (data?.length <= MAXIMUM_AMOUNT_OF_TICKS_TO_SHOW) {
    return valueIfValid;
  }

  if (index === 0) {
    return valueIfValid;
  }

  const step = Math.floor(data?.length / MAXIMUM_AMOUNT_OF_TICKS_TO_SHOW);
  const isStep = index % step === 0;

  return isStep ? valueIfValid : valueIfInvalid;
};
