import React, { SVGProps } from 'react';

const TagIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={131} height={100} fill="none" {...props}>
    <g filter="url(#a)">
      <ellipse
        cx={9.436}
        cy={16.697}
        fill="url(#b)"
        rx={9.436}
        ry={16.697}
        transform="matrix(.8055 -.59258 .86786 .4968 30.566 41.457)"
      />
    </g>
    <g filter="url(#c)">
      <ellipse cx={65.774} cy={53.181} fill="url(#d)" rx={31.5} ry={13.091} />
    </g>
    <g filter="url(#e)">
      <rect
        width={39.273}
        height={39.273}
        x={46.641}
        y={33.547}
        fill="#262626"
        fillOpacity={0.8}
        rx={19.637}
        shapeRendering="crispEdges"
      />
      <path
        fill="#fff"
        d="m75.123 53.794-7.618-7.618a1.216 1.216 0 0 0-.867-.36h-7.11a.614.614 0 0 0-.614.614v7.11a1.216 1.216 0 0 0 .36.867l7.617 7.618a1.228 1.228 0 0 0 1.736 0l6.496-6.495a1.228 1.228 0 0 0 0-1.736Zm-7.364 7.363L60.14 53.54v-6.496h6.497l7.617 7.617-6.496 6.496Zm-3.936-11.352a.921.921 0 1 1-1.842 0 .921.921 0 0 1 1.842 0Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={99.578}
        height={86.855}
        x={2.869}
        y={0.732}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_6576_109073" stdDeviation={16.712} />
      </filter>
      <filter
        id="c"
        width={129.848}
        height={93.031}
        x={0.85}
        y={6.666}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_6576_109073" stdDeviation={16.712} />
      </filter>
      <filter
        id="e"
        width={46.384}
        height={48.161}
        x={43.086}
        y={31.769}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.889} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6576_109073" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={3.555} />
        <feGaussianBlur stdDeviation={1.778} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend in2="effect1_backgroundBlur_6576_109073" result="effect2_dropShadow_6576_109073" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={3.273} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="effect2_dropShadow_6576_109073" result="effect3_dropShadow_6576_109073" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_6576_109073" result="shape" />
      </filter>
      <linearGradient id="b" x1={9.436} x2={9.436} y1={0} y2={33.394} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF5100" stopOpacity={0.9} />
        <stop offset={1} stopColor="#FF5100" />
      </linearGradient>
      <linearGradient id="d" x1={87.299} x2={71.577} y1={65.181} y2={23.848} gradientUnits="userSpaceOnUse">
        <stop offset={0.373} stopColor="#30D9C0" />
        <stop offset={1} stopColor="#145D52" />
      </linearGradient>
    </defs>
  </svg>
);

export default TagIllustration;
