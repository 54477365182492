import styled from 'styled-components';

import { ChevronIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import DateRangePicker from '../DateRangePicker';

interface StyledButtonProps {
  $isActive: boolean;
}

const getHoverColor = (props: ThemeProp & StyledButtonProps) => {
  if (props.$isActive) {
    return props.theme.colors.primary.c500;
  }
  return props.theme.colors.neutrals.white;
};

export const StyledButton = styled.div<StyledButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props: ThemeProp & StyledButtonProps) =>
    props?.$isActive ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.c300};
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.lineHeight};
  padding: 0.25rem;
  height: 2rem;
  cursor: pointer;
  min-width: 8rem;
  gap: 0.5rem;

  svg {
    fill: ${(props: ThemeProp) => props.theme.colors.primary.c500};
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    color: ${(props: ThemeProp & StyledButtonProps) => getHoverColor(props)};
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c600};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const SelectedOptionButton = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.375rem;
  padding: 0.625rem 0.75rem;
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.lineHeight};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  cursor: pointer;
  height: 2.5rem;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: visible;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
    margin-left: 0;
  }
`;

interface StyledChevronIconProps {
  $shouldRotate: boolean;
}

export const StyledChevronIcon = styled(ChevronIcon)`
  flex: 1 0 0.625rem;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  align-self: center;
  width: 0.75rem;
  height: 0.75rem;
  ${(props: StyledChevronIconProps) => props.$shouldRotate && 'transform: rotate(180deg);'}
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  padding-top: 1rem;
  border-left: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c700};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    border-left: none;
    padding-top: 0;
  }
`;

export const MobileOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const ButtonExtraDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};

  svg {
    width: 1.25rem;
    stroke: white;
  }
`;
