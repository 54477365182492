import { Loading } from '@nextui-org/react';
import React from 'react';
import styled, { css } from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { lightTheme } from '../../theme/Themes';
import Ripple from './Ripple';

enum ButtonRoleEnum {
  Cancel,
  Destructive,
  Primary,
}

export class ButtonRole {
  static Cancel: ButtonRole = new ButtonRole(ButtonRoleEnum.Cancel);
  static Destructive: ButtonRole = new ButtonRole(ButtonRoleEnum.Destructive);
  static Primary: ButtonRole = new ButtonRole(ButtonRoleEnum.Primary);

  private buttonRole: ButtonRoleEnum;

  constructor(buttonRole: ButtonRoleEnum) {
    this.buttonRole = buttonRole;
  }

  backgroundColor(theme = lightTheme): string {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.system.error.c600;
      case ButtonRoleEnum.Cancel:
        return 'transparent';
      default:
        return theme.colors.primary.c400;
    }
  }

  backgroundColorHover = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.system.error.c500;
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.white;
      default:
        return theme.colors.primary.c600;
    }
  };

  backgroundColorActive = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.system.error.c700;
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.c100;
      default:
        return theme.colors.primary.c700;
    }
  };

  colorHover = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.neutrals.white;
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.black;
      default:
        return theme.colors.neutrals.black;
    }
  };

  colorActive = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.neutrals.white;
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.black;
      default:
        return theme.colors.neutrals.black;
    }
  };

  color = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.neutrals.white;
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.white;
      default:
        return theme.colors.neutrals.black;
    }
  };

  borderColor = (theme = lightTheme): string => {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return 'transparent';
      case ButtonRoleEnum.Cancel:
        return theme.colors.neutrals.white;
      default:
        return 'transparent';
    }
  };
}

export interface ButtonProps {
  loading?: boolean;
  loadingSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disableMinWidth?: boolean;
  $width?: string;
  $height?: string;
  color?: string;
  children: React.ReactNode;
  disabled?: boolean;
  $mobileRemoveBorder?: boolean;
  $buttonRole?: any;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  $noBorder?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props: ButtonProps) => (props.loading ? 'none' : 'auto')};
  border-radius: 0;
  border: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.md.fontSize};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  height: 2.5rem;
  min-width: ${(props: ButtonProps) => (props.disableMinWidth ? undefined : '6.125rem')};
  width: ${(props: ButtonProps) => props.$width || 'unset'};
  color: ${(props: ButtonProps & ThemeProp) =>
    props.loading ? 'transparent' : props.color || props.theme.colors.neutrals.black};
  gap: 1.25rem;
  background: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  min-width: ${(props) => props.disableMinWidth && '0px !important;'};
  padding: 0 0.625rem;

  svg {
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  }

  :hover {
    background-color: ${(props: ThemeProp) => props.theme.colors.primary.c600};
  }

  & > span {
    z-index: 4;
    pointer-events: none;
    display: flex;
    align-items: center;
  }
  .nextui-loading {
    --nextui--loadingColor: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  }
  .nextui-button-icon {
    svg {
      stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    }
    margin-right: 0;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    width: ${(props: ButtonProps) => props.$width || '100%'};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.c600};
      opacity: 0.7;
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
      cursor: not-allowed;
      :hover {
        background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c600};
      }
    `}
`;

const Button = ({ children, loading, loadingSize = 'sm', ...props }: ButtonProps) => {
  const randomId = Math.random().toString(36).substring(7);
  return (
    <ButtonStyled {...props} id={`button-custom-${randomId}`}>
      {!props.disabled && <Ripple randomId={randomId} />}
      {loading ? <StyledLoading size={loadingSize} /> : <span>{children}</span>}
    </ButtonStyled>
  );
};

export default Button;
