import React, { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="9.21552" cy="9.21552" r="5.88495" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.6695 16.6695L13.3765 13.3765" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SearchIcon;
