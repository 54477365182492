import { Popover as NextUIPopover, PopoverPlacement } from '@nextui-org/react';
import React from 'react';

import { PopoverContainer, StyledPopoverContent, StyledVertDotsButton, StyledVertDotsIcon } from './Popover.styles';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  hidePopover: (value: boolean) => void;
  triggerComponent?: React.ReactNode;
  showBorder?: boolean;
  minWidth?: string;
  placement?: PopoverPlacement;
  noPadding?: boolean;
  offset?: number;
}

const Popover = ({
  children,
  isOpen,
  hidePopover,
  triggerComponent,
  showBorder,
  minWidth,
  placement = 'bottom-right',
  offset = 6,
  noPadding,
}: Props) => {
  return (
    <NextUIPopover offset={offset} isOpen={isOpen} onOpenChange={hidePopover} placement={placement}>
      <NextUIPopover.Trigger>
        {triggerComponent ?? <StyledVertDotsButton icon={<StyledVertDotsIcon $active={isOpen} />} />}
      </NextUIPopover.Trigger>
      <StyledPopoverContent>
        <PopoverContainer $minWidth={minWidth} $showBorder={showBorder} $noPadding={noPadding}>
          {children}
        </PopoverContainer>
      </StyledPopoverContent>
    </NextUIPopover>
  );
};

export default Popover;
