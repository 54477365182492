import { Amplify, Storage } from 'aws-amplify';
import { MutableRefObject } from 'react';

export function configureAmplify() {
  Amplify.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      ...(process.env.REACT_APP_COGNITO_ENDPOINT && {
        endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
      }),
    },
    Storage: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_COGNITO_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
  });
}

function generateName(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export interface UploadFileConfig {
  bucket?: string;
  url?: string;
  path?: string;
  private?: boolean;
}

export async function uploadFile(
  file: File,
  progressCallback?: (progress: any) => void,
  uploadOperationRef?: MutableRefObject<any>,
  config?: UploadFileConfig,
): Promise<string> {
  const fileName = `${config?.path || ''}${generateName()}`;
  try {
    const existingFile = await Storage.get(fileName, { download: true });
    if (existingFile) {
      // File already exist so trigger a new name
      return uploadFile(file, progressCallback, uploadOperationRef, config);
    }
  } catch {
    const level = config?.private ? 'private' : 'public';
    const bucket = config?.bucket || process.env.REACT_APP_S3_BUCKET_NAME;

    // File doesn't exist
    const uploadOperation = Storage.put(fileName, file, {
      progressCallback,
      level,
      contentType: file.type,
      bucket,
    });
    if (uploadOperationRef) {
      uploadOperationRef.current = uploadOperation;
    }
    await uploadOperation;

    // Get URL File based on the config
    const uploadedFile = await Storage.get(fileName, {
      download: false,
      level,
      bucket,
    });
    const url = new URL(uploadedFile);

    // Return the url without the extra params (token and configs)
    return `${url.origin}${url.pathname}`;
  }
  return '';
}

export async function cancelStorage(operation: any): Promise<boolean> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return await Storage.cancel(operation);
}
