import styled from 'styled-components';

import IconButton from '../../../../components/IconButton';
import { ThemeProp } from '../../../../theme/Themes';

export const Container = styled.div`
  display: none;
  align-items: center;
  position: relative;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const Button = styled(IconButton)`
  border-radius: 0;
  border-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  background-color: transparent;
  :focus {
    box-shadow: unset;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
`;
