import { Input as NextInput } from '@nextui-org/react';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import HelpTooltip from '../HelpTooltip';

const applyStyle = (component: any) => {
  return styled(component).attrs((props) => {
    const isError = props.status === 'error';
    return {
      ...props,
      animated: false,
      css: {
        ...props.css,
        svg: {
          stroke: props.theme.colors.neutrals.c400,
        },
        '&': {
          marginBottom: isError ? '$5' : props.marginBottom || '$8',
          width: '100%',
        },
        '& .nextui-input-container--disabled': {
          background: '$disabledBackground !important',
          color: '$grayScaleText !important',
        },
        '& .nextui-input': {
          margin: props.iconLeft ? '0' : '0 $5',
          '&:not(:placeholder-shown)': {
            marginRight: props.clearable ? '$12' : undefined,
          },
        },
        '& input': {
          ...(isError && { color: '$text' }),
          fontSize: '$input',
          lineHeight: '$sm2',

          '&:disabled': {
            background: '$disabledBackground !important',
            color: '$grayScaleText !important',
            opacity: '1 !important',
          },
          '&:focus::placeholder': {
            opacity: 1,
          },
          '&::placeholder': {
            color: props.theme.colors.neutrals.c400,
          },
        },
        '& .nextui-input-block-label:after': {
          content: props.required ? props.$requiredLabel || '' : '',
          paddingLeft: '0.25rem',
          fontStyle: 'italic',
          color: props.theme.colors.neutrals.c400,
          fontSize: '$xs',
        },
        '& .nextui-input-block-label': {
          color: '$inputLabelText',
          fontSize: '$sm',
          lineHeight: '$xs',
          paddingLeft: '0',
        },
        '& .nextui-input-container': {
          marginTop: props?.containerMarginTop || '$1',
          height: '$15',
          border: `$borderWeights$normal solid ${isError ? '$inputErrorBorder' : '$inputBorder'}`,
          borderRadius: '0',
        },
        '& .nextui-input-container .nextui-input-wrapper': {
          background: 'transparent',
          borderRadius: '0',
        },
        '.nextui-input-wrapper--disabled': {
          background: '$disabledBackground !important',
          opacity: '1 !important',
        },
        '& .nextui-input-helper-text-container': {
          position: 'unset',
          marginTop: `${props.helpText ? '$1' : '0'}`,
        },
        '& .nextui-input-helper-text-container p': {
          fontSize: 'calc($sm - 1px)',
          lineHeight: '$xs3',
          color: isError ? '$inputErrorBorder !important' : '$grayScaleText !important',
        },
        '& .nextui-input-password-icon': {
          stroke: '$inputErrorIcon',
        },
        '& .nextui-input-content--right': {
          width: 'unset',
        },
      },
    };
  })`
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices: large password dots fix */
      & input[type='password']:not(:placeholder-shown) {
        font: small-caption;
        font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
      }
    }
  `;
};

const Input = applyStyle(NextInput);

Input.Password = applyStyle(NextInput.Password);

const HelperWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputErrorWrapper = forwardRef<typeof Input, any>((props, ref) => {
  const label = props.label;

  const allowOnlyLetters = props.$allowOnlyLetters;

  const onChangeOnlyLetter = (event: any) => {
    event.target.value = event.target.value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑüÜ. -]/g, '');
    props.onChange?.(event);
  };

  const onKeyDownOnlyLetter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/^[a-zA-Z-. ]*$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onChange = allowOnlyLetters ? onChangeOnlyLetter : props.onChange;
  const onKeyDown = allowOnlyLetters ? onKeyDownOnlyLetter : props.onKeyDown;

  const labelWithHelper = (
    <HelperWrapper>
      {label} {<HelpTooltip $helpText={props.helpText} />}
    </HelperWrapper>
  );

  return (
    <>
      <Input
        {...props}
        label={props.helpText ? labelWithHelper : label}
        ref={ref}
        contentLeft={props.iconLeft || null}
        status={props.error ? 'error' : 'default'}
        helperColor={props.error ? 'error' : 'default'}
        helperText={!props.hideErrorText && props.error}
        required={props.required}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </>
  );
}) as any;

InputErrorWrapper.Password = (props) => {
  return (
    <Input.Password
      {...props}
      status={props.error ? 'error' : 'default'}
      helperColor={props.error ? 'error' : 'default'}
      helperText={!props.hideErrorText && props.error}
    />
  );
};

export default InputErrorWrapper;
