import styled from 'styled-components';

import { CheckToastIcon, CrossToastIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const CustomToastContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  gap: 0.75rem;
  width: 22.25rem;
  max-width: 22.25rem;
  min-height: 2.75rem;

  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.ml};

  border: 1px solid;
  border-image-slice: 1 1;
  border-image-source: ${(props: ThemeProp) =>
    `linear-gradient(90.94deg, ${props.theme.colors.neutrals.c800} 0%, ${props.theme.colors.neutrals.c700} 99.28%)`};

  background: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  box-shadow: 0px 8px 16px rgba(22, 22, 22, 0.2), 0px 3px 10px rgba(22, 22, 22, 0.3);

  button {
    margin-left: auto;
    border: none;
    font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    opacity: 0.5;
    background: transparent;
    cursor: pointer;
  }
`;

export const CustomToastText = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 14.5rem;
`;

export const CustomSuccessToastContainer = styled(CustomToastContainer)`
  background: ${(props: ThemeProp) => props.theme.colors.system.success.c300};
  border-image-source: ${(props: ThemeProp) =>
    `linear-gradient(90.9deg, ${props.theme.colors.system.success.c100} 0%, ${props.theme.colors.system.success.c200} 100%)`};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};

  button {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  }
`;

export const CustomErrorToastContainer = styled(CustomToastContainer)`
  background: ${(props: ThemeProp) => props.theme.colors.system.error.c800};
  border-image-source: ${(props: ThemeProp) =>
    `linear-gradient(90.9deg, ${props.theme.colors.system.error.c700} 0%, ${props.theme.colors.system.error.c700} 100.47%)`};
`;

export const StyledCheckToastIcon = styled(CheckToastIcon).attrs((props: ThemeProp) => ({
  color: props.theme.colors.neutrals.black,
}))``;

export const StyledCrossToastIcon = styled(CrossToastIcon).attrs((props: ThemeProp) => ({
  color: props.theme.colors.system.error.c300,
}))``;
