import React from 'react';
import styled from 'styled-components';

import { SearchIcon } from '../../assets';
import { Input } from '../../components';
import { ThemeProp } from '../../theme/Themes';

const Container = styled.div<{ $height?: string; $maxWidth?: string }>`
  display: flex;
  flex: 1;
  height: ${(props) => props.$height || '2.5rem;'};
  max-height: ${(props) => props.$height || '2.5rem;'};
  max-width: ${(props) => props.$maxWidth || 'unset'};
  & .nextui-input-main-container {
    height: 100%;
    border-radius: 0;
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
    margin: 0;
  }

  // Styles when input is active.
  & .nextui-input-main-container--hover {
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  }

  & .nextui-input-clear-button {
    svg {
      stroke: transparent;
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }
`;

const SearchInputComp = styled(Input)`
  && {
    margin: 0;
    max-height: ${(props) => props.height || 'unset'};
    border: none;
    input {
      font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.md.fontSize};
      line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.md.lineHeight};
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  fill: none !important;
  stroke: ${(props: ThemeProp) => props.theme.colors.primary.c400};
`;

const SearchInput = (props) => {
  return (
    <Container $height={props.height} $maxWidth={props.maxWidth}>
      <SearchInputComp label="" name="search" aria-label="searchInput" iconLeft={<StyledSearchIcon />} {...props} />
    </Container>
  );
};

export default SearchInput;
