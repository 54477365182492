import React from 'react';

import { CustomRadioButtonInside, CustomRadioButtonOutside } from './CustomRadioButton.styles';

interface CustomRadioButtonProps {
  isItemSelected: boolean;
  index: number;
  disabled?: boolean;
  onChange: (index: number) => void;
  invertColors?: boolean;
}

const CustomRadioButton = ({ isItemSelected, index, disabled, onChange, invertColors }: CustomRadioButtonProps) => (
  <CustomRadioButtonOutside
    key={index}
    $selected={isItemSelected}
    $disabled={disabled}
    onClick={() => onChange(index)}
    $invertColors={invertColors}
  >
    {isItemSelected && <CustomRadioButtonInside $invertColors={invertColors} />}
  </CustomRadioButtonOutside>
);

export default CustomRadioButton;
