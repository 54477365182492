import 'react-spring-bottom-sheet/dist/style.css';

import { BottomSheet } from 'react-spring-bottom-sheet';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface StyledBottomSheetProps {
  zIndex?: number;
  $fullScreen?: boolean;
  $headerWithoutStyles?: boolean;
  $showDragIndicator?: boolean;
  $notVisible?: boolean;
  $disableBackdrop?: boolean;
  $useTransparentBackground?: boolean;
}

const getBackgroundColor = (props: ThemeProp & StyledBottomSheetProps) => {
  if (props.$useTransparentBackground) {
    return `${props.theme.colors.neutrals.c800}e6`;
  }
  return props.theme.colors.neutrals.c800;
};

export const StyledBottomSheet = styled(BottomSheet)<StyledBottomSheetProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: backdrop-filter 3s ease-out;
  [data-rsbs-scroll] {
    ${(props) => props.$notVisible && 'visibility: hidden;'};
  }

  [data-rsbs-content] {
    ${(props) => props.$fullScreen && 'height: 100%;'};
    ${(props) => props.$notVisible && 'visibility: hidden;'};
  }
  [data-rsbs-backdrop] {
    ${(props) => props.$disableBackdrop && 'display: none;'};
    z-index: ${(props) => props.zIndex || 9999};
    backdrop-filter: blur(4px);
    ${(props) => props.$notVisible && 'visibility: hidden;'};
  }
  [data-rsbs-header] {
    border-bottom: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
    border-bottom: ${(props) => props.$headerWithoutStyles && 'none'};
    padding: ${(props) => (props.$fullScreen ? '1.125rem 1.5rem;' : '1.75rem 1.5rem 1.5rem 1.5rem;')};
    padding: ${(props) => props.$headerWithoutStyles && '1.5rem 1.5rem 0 1.5rem'};
  }
  [data-rsbs-header]:before {
    display: none;
    ${(props) => props.$showDragIndicator && 'display: block;'};
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c600};
    height: 0.375rem;
  }
  [data-rsbs-overlay] {
    ${(props) => props.$notVisible && 'visibility: hidden;'};
    right: unset;
    left: unset;
    border-radius: 0;
    z-index: ${(props) => (props.zIndex ? Number(props.zIndex) + 1 : 9999)};
    ${(props) => props.$fullScreen && 'min-height: 100%;'};
    border-radius: ${(props) => (props.$fullScreen ? '0' : '12px 12px 0px 0px;')};
    background: ${(props: ThemeProp & StyledBottomSheetProps) => getBackgroundColor(props)};
    backdrop-filter: blur(11px);
  }
  [data-rsbs-content] {
    padding: ${(props) => (props.noPadding ? '0;' : props.padding ?? '0.75rem 1.5rem 0.75rem 1.5rem;')};
    overflow: auto;
  }
  [data-rsbs-scroll] {
    overflow: ${(props) => props.scrollOverflow ?? 'auto'};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    [data-rsbs-overlay] {
      border-radius: 0;
      right: 0;
      left: 0;
      max-width: unset;
      width: 100%;
    }
  }
`;
