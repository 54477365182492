import * as turf from '@turf/turf';
import { Position, Units } from '@turf/turf';

// This is needed to excluding GL JS explicitly from transpilation
// See https://docs.mapbox.com/mapbox-gl-js/guides/install/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { GeoFilter, GeoFilterType } from '../../contexts/KPIsAndFilterContext';
import { PROVINCES_WITH_NAME } from '../canProvinces';
import { hashCode } from '../helpers';
import { STATES_WITH_NAME } from '../usaStates';
import provincesGeoJson from './canada.json';
import { GeoFilterHelper, MarkerProps } from './GeoFilterHelper';
import statesGeoJson from './states.json';
export interface RegionGeoFilterData {
  region?: string;
}

export default class RegionGeoFilterHelper extends GeoFilterHelper<RegionGeoFilterData> {
  generateId(): number {
    return hashCode(`region-${this.geoFilter.data.region}`);
  }

  isEqual(geoFilter: GeoFilter): boolean {
    if (geoFilter.type !== this.geoFilter.type) return false;

    if (geoFilter.data.region !== this.geoFilter.data.region) return false;
    if (geoFilter.data.zipCodes?.length !== this.geoFilter.zipCodes?.length) return false;
    for (let i = 0; i < geoFilter.data.zipCodes.length; i++) {
      if (geoFilter.data.zipCodes[i] !== this.geoFilter.zipCodes[i]) return false;
    }
    return true;
  }

  toGraphQLGeoFilter(skipExcludedZips?: boolean, isCanada?: boolean): any {
    const data = this.geoFilter.data;
    const stateAbb = isCanada
      ? PROVINCES_WITH_NAME.find((prov) => prov.name.toLowerCase() === data.region.toLowerCase())?.abbreviation
      : STATES_WITH_NAME.find((state) => state.name.toLowerCase() === data.region.toLowerCase())?.abbreviation;
    return {
      geoFilter: {
        operator: {
          region: stateAbb,
        },
        ...(!skipExcludedZips && { excludedZipCodes: this.geoFilter.excludedZipCodes }),
      },
    };
  }

  generateIdForGeoFilterGraphQl(): number {
    return hashCode(`region-${this.geoFilterGraphQL.operator.region as string}`);
  }

  fromGraphQLGeoFilter(isCanada: boolean): GeoFilter<RegionGeoFilterData> {
    const stateName = isCanada
      ? PROVINCES_WITH_NAME.find(
          (prov) => prov.abbreviation.toLowerCase() === this.geoFilterGraphQL.operator.region.toLowerCase(),
        )?.name
      : STATES_WITH_NAME.find(
          (state) => state.abbreviation.toLowerCase() === this.geoFilterGraphQL.operator.region.toLowerCase(),
        )?.name;
    const data: RegionGeoFilterData = {
      region: stateName,
    };
    return {
      id: this.generateIdForGeoFilterGraphQl(),
      type: GeoFilterType.Region,
      data,
      excludedZipCodes: this.geoFilterGraphQL.excludedZipCodes,
    };
  }

  getCenter(isCanada: boolean) {
    const stateGeoJson = isCanada
      ? provincesGeoJson.features.find(
          (feature) => feature.properties.prov_name_en?.[0].toLowerCase() === this.geoFilter.data.region.toLowerCase(),
        )
      : statesGeoJson.features.find(
          (feature) => feature.properties.STATE_NAME.toLowerCase() === this.geoFilter.data.region.toLowerCase(),
        );

    const coordinatesOfState = (
      stateGeoJson?.geometry?.type === 'MultiPolygon'
        ? stateGeoJson.geometry.coordinates.flat().flat()
        : stateGeoJson.geometry.coordinates.flat()
    ) as Position[];

    const closedPolygon = [...coordinatesOfState, coordinatesOfState[0]];
    const polygon = turf.polygon([closedPolygon]);
    const center = turf.center(polygon);
    return { latitude: center.geometry.coordinates[1], longitude: center.geometry.coordinates[0] };
  }

  getBoundingBox(isCanada: boolean) {
    const stateGeoJson = isCanada
      ? provincesGeoJson.features.find(
          (feature) => feature.properties.prov_name_en?.[0].toLowerCase() === this.geoFilter.data.region.toLowerCase(),
        )
      : statesGeoJson.features.find(
          (feature) => feature.properties.STATE_NAME.toLowerCase() === this.geoFilter.data.region.toLowerCase(),
        );

    const coordinates = (
      stateGeoJson?.geometry?.type === 'MultiPolygon'
        ? stateGeoJson?.geometry.coordinates.flat().flat()
        : stateGeoJson?.geometry.coordinates.flat()
    ) as Position[];

    if (coordinates) {
      const closedPolygon = [...coordinates, coordinates[0]];
      const polygon = turf.polygon([closedPolygon]);
      const center = turf.center(polygon);
      let furthestPointFromCenter = coordinates?.[0];
      const distanceMax = turf.distance(center, furthestPointFromCenter, { units: 'miles' });

      coordinates.forEach((coord) => {
        const distance = turf.distance(center, coord, { units: 'miles' });
        if (distance > distanceMax) furthestPointFromCenter = coord;
      });

      const distance = turf.distance(center, furthestPointFromCenter, { units: 'miles' });
      const options = { units: 'miles' as Units };
      const buffered = turf.buffer(center, distance, options);
      return turf.bbox(buffered);
    }
    return null;
  }

  drawLayer() {
    return { layerFillId: '' };
  }

  drawMarker(props: MarkerProps) {
    return new mapboxgl.Marker(null).setLngLat(props.position).addTo(props.map);
  }
}
