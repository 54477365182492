import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheetProps } from 'react-spring-bottom-sheet';

import { inMobile, inTablet, useWindowDimensions } from '../../hooks/useDimensions';
import { ButtonRole } from '../Button';
import {
  StyledBottomSheet,
  StyledButton,
  StyledFooter,
  StyledGhostButton,
  StyledModal,
  StyledModalBody,
  StyledSecondaryButton,
} from './Modal.styles';
import ModalHeader from './ModalHeader';

export enum BottomSheetChangeBreakpoint {
  Tablet = 'tablet',
  Mobile = 'mobile',
}

export interface ModalProps extends Omit<BottomSheetProps, 'title'> {
  children: React.ReactNode;
  open: boolean;
  title?: string | ReactNode;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  reverseActionsOrder?: boolean;
  width?: string;
  loading?: boolean;
  onClose?: () => void; // Executes when dismissed, and when the close button is clicked if onCloseButtonClick is undefined.
  onCloseButtonClick?: () => void; // Used if we want a specific behavior for the button rather than the close/dismiss event itself.
  onSubmit?: () => void;
  onCancel?: () => void;
  onGoBack?: () => void;
  hideDesktopHeader?: boolean;
  hideMobileHeader?: boolean;
  hideFooter?: boolean;
  buttonRole?: ButtonRole;
  hideDesktopCloseButton?: boolean; // Hides the desktop dialog close button.
  hideMobileCloseButton?: boolean; // Hides the mobile bottom sheet close button.
  disabledSubmitButton?: boolean;
  noPadding?: boolean;
  mobileOverflow?: string;
  mobileFullScreen?: boolean;
  submitButtonCustomColor?: string;
  cancelButtonCustomColor?: string;
  mobileFooterColumnAlignment?: boolean;
  mobileSecondaryButtonNoBorder?: boolean;
  mobileCloseButtonAtEnd?: boolean;
  bottomSheetChangeBreakpoint?: BottomSheetChangeBreakpoint;
  showDragIndicator?: boolean;
  modalPadding?: string;
  bottomSheetPadding?: string;
  bottomSheetZIndex?: number;
  notVisibleBottomSheet?: boolean;
  disabledBackdrop?: boolean;
  ghostButtonTitle?: string;
  cancelButtonRole?: ButtonRole;
  isBottomSheetExpanded?: boolean;
  setBottomSheetExpanded?: (expanded: boolean) => void;
  mobileHeaderCustomIcon?: React.ReactNode;
  useBottomSheetTransparentBackground?: boolean;
  modalHeaderPadding?: string;
}

const Modal = ({
  open,
  children,
  title,
  cancelButtonTitle,
  submitButtonTitle,
  reverseActionsOrder,
  width = '32.5rem',
  loading,
  onClose,
  onCloseButtonClick,
  onSubmit,
  onCancel,
  onGoBack,
  hideDesktopHeader = false,
  hideMobileHeader = false,
  hideFooter = false,
  buttonRole = ButtonRole.Primary,
  hideDesktopCloseButton = true, // True by default to match designs.
  hideMobileCloseButton = false,
  disabledSubmitButton = false,
  noPadding,
  mobileOverflow = 'auto',
  mobileFullScreen = false,
  submitButtonCustomColor,
  mobileFooterColumnAlignment,
  mobileSecondaryButtonNoBorder,
  mobileCloseButtonAtEnd,
  bottomSheetChangeBreakpoint = BottomSheetChangeBreakpoint.Mobile,
  modalPadding,
  bottomSheetPadding,
  bottomSheetZIndex,
  notVisibleBottomSheet = false,
  disabledBackdrop = false,
  ghostButtonTitle,
  cancelButtonCustomColor,
  cancelButtonRole = ButtonRole.Cancel,
  isBottomSheetExpanded,
  setBottomSheetExpanded,
  mobileHeaderCustomIcon,
  useBottomSheetTransparentBackground,
  modalHeaderPadding,
  ...props
}: ModalProps) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const handleCancelClick = () => {
    onClose?.();
    onCancel?.();
  };

  const shouldHideCloseButton = () => {
    if (bottomSheetChangeBreakpoint === BottomSheetChangeBreakpoint.Tablet) {
      // Mobile and Tablet show the bottom sheet.
      return inTablet(windowWidth) ? hideMobileCloseButton : hideDesktopCloseButton;
    }
    // Only Mobile shows the bottom sheet.
    return inMobile(windowWidth) ? hideMobileCloseButton : hideDesktopCloseButton;
  };

  const Header = () =>
    !!title && (
      <ModalHeader
        title={title}
        hideCloseButton={shouldHideCloseButton()}
        bottomSheetChangeBreakpoint={bottomSheetChangeBreakpoint}
        closeButtonAtEnd={mobileCloseButtonAtEnd}
        onCloseButtonClick={onCloseButtonClick ?? onClose}
        onGoBack={onGoBack}
        customIcon={mobileHeaderCustomIcon}
        modalHeaderPadding={modalHeaderPadding}
      />
    );

  const GhostButton = () => (
    <StyledGhostButton $mobileRemoveBorder={mobileSecondaryButtonNoBorder} onClick={onClose}>
      {ghostButtonTitle}
    </StyledGhostButton>
  );

  const CancelButton = () => (
    <StyledSecondaryButton
      $buttonRole={cancelButtonRole}
      $mobileRemoveBorder={mobileSecondaryButtonNoBorder}
      onClick={handleCancelClick}
    >
      {cancelButtonTitle || t('common.cancel')}
    </StyledSecondaryButton>
  );
  const SubmitButton = () => (
    <StyledButton
      $color={submitButtonCustomColor}
      disabled={disabledSubmitButton}
      $buttonRole={buttonRole}
      loading={loading}
      onClick={onSubmit}
    >
      {submitButtonTitle || t('common.ok')}
    </StyledButton>
  );
  const Footer = () => {
    return (
      <StyledFooter reverse={reverseActionsOrder} mobileColumnAlignment={mobileFooterColumnAlignment}>
        {ghostButtonTitle && <GhostButton />}
        <CancelButton />
        <SubmitButton />
      </StyledFooter>
    );
  };

  const renderCondition =
    bottomSheetChangeBreakpoint === BottomSheetChangeBreakpoint.Tablet ? inTablet(windowWidth) : inMobile(windowWidth);

  return (
    <>
      {renderCondition ? (
        <StyledBottomSheet
          fullScreen={mobileFullScreen}
          open={open}
          onDismiss={mobileFullScreen ? undefined : onClose}
          headerWithoutStyles={!mobileFullScreen}
          header={!hideMobileHeader && <Header />}
          footer={!hideFooter && <Footer />}
          bottomSheetChangeBreakpoint={bottomSheetChangeBreakpoint}
          padding={noPadding ? '0' : bottomSheetPadding}
          zIndex={bottomSheetZIndex}
          notVisible={notVisibleBottomSheet}
          disabledBackdrop={disabledBackdrop}
          blocking={!disabledBackdrop}
          isBottomSheetExpanded={isBottomSheetExpanded}
          setBottomSheetExpanded={setBottomSheetExpanded}
          scrollOverflow={mobileOverflow}
          useTransparentBackground={useBottomSheetTransparentBackground}
          {...props}
        >
          {children}
        </StyledBottomSheet>
      ) : (
        <StyledModal open={open} width={width} onClose={onClose}>
          {!hideDesktopHeader && <Header />}
          <StyledModalBody $noPadding={noPadding} $padding={modalPadding}>
            {children}
          </StyledModalBody>
          {!hideFooter && <Footer />}
        </StyledModal>
      )}
    </>
  );
};

export default Modal;
