import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '../../components';
import ActionModal from '../Modal/ActionModal';
import { ActionModalComponentProps } from '../Modal/ActionModal/ActionModal';
import { StyledSearchIcon } from '../SearchInput/SearchInput';
import { InputContainer } from './SearchModal.styles';

interface SearchModalInputProps extends ActionModalComponentProps {
  searchTextLocal: string;
  searchModalPlaceholder?: string;
  onSearchChange: (text: string) => void;
}

const SearchModalInput = forwardRef(
  ({ onFinish, searchTextLocal, searchModalPlaceholder, onSearchChange }: SearchModalInputProps, ref): any => {
    const [text, setText] = useState(searchTextLocal);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      submit: () => {
        onSearchChange?.(text);
        onFinish?.();
      },
    }));

    return (
      <InputContainer>
        <Input
          placeholder={searchModalPlaceholder ?? t('common.search')}
          aria-label={t('common.search')}
          iconLeft={<StyledSearchIcon />}
          clearable
          onChange={(e: any) => setText(e.target.value as string)}
          value={text}
        />
        <Button
          disableMinWidth
          $width="100%"
          onClick={() => {
            onSearchChange?.(text);
            onFinish?.();
          }}
        >
          {t('common.search')}
        </Button>
      </InputContainer>
    );
  },
);

interface SearchModalProps {
  searchModalRef?: React.MutableRefObject<any>;
  searchText: string;
  searchModalPlaceholder?: string;
  onSearchChange: (text: string) => void;
}

const SearchModal = ({ searchModalRef, searchText, searchModalPlaceholder, onSearchChange }: SearchModalProps) => {
  const { t } = useTranslation();

  return (
    <ActionModal
      ref={searchModalRef}
      title={t('common.search')}
      submitButtonTitle={t('common.search')}
      mobileCloseButtonAtEnd
      hideFooter
      Component={SearchModalInput}
      componentProps={{ searchTextLocal: searchText, searchModalPlaceholder, onSearchChange }}
    />
  );
};

export default SearchModal;
