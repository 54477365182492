import 'react-daterange-picker/dist/css/react-calendar.css';

import DateRangePicker from 'react-daterange-picker';
import styled from 'styled-components';

import ChevronUrl from '../../assets/chevron.svg';
import { ThemeProp } from '../../theme/Themes';
import DateTimeInput from '../DateTimeInput';

const breakpoints = {
  oneColumn: '38.375rem',
  hiddenRightPanel: '53.375rem',
};

export const Container = styled.div`
  display: flex;
`;

export const RangeSelectionContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  width: 100%;
  min-height: 22.25rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

export const PredefinedSelectionsColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 10.5rem;
  border-right: 1px solid ${({ theme }: ThemeProp) => theme.colors.neutrals.c700};
  margin-right: 1rem;
  padding-right: 1rem;
  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    display: none;
  }
`;

export const PredefinedSelectionsRowContainer = styled.div`
  display: none;
  margin: 0 0.5rem;
  gap: 0.5rem;
  padding-bottom: 1rem;
  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    display: flex;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 0 0.5rem;
  @media (max-width: ${breakpoints.oneColumn}) {
    flex-direction: column;
    gap: 0;
  }
`;

interface DateRangePickerProps {
  skipPendingState?: boolean;
}

export const StyledDateRangePicker = styled(DateRangePicker)<DateRangePickerProps>`
  .DateRangePicker__Month {
    width: calc(50% - 1rem);
    margin: 0 0.5rem;
    @media (max-width: ${breakpoints.oneColumn}) {
      width: calc(100% - 1rem);
      &:nth-of-type(3) {
        margin-top: 1rem;
      }
    }
  }
  .DateRangePicker__MonthHeader {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
    font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.fontSize};
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 0.3rem;
  }
  .DateRangePicker__PaginationArrow:hover {
    background-color: transparent;
  }
  .DateRangePicker__PaginationArrow--previous {
    left: 0.5rem;
  }
  .DateRangePicker__PaginationArrow--next {
    right: 0.5rem;
  }
  .DateRangePicker__PaginationArrowIcon {
    width: 1.5rem;
    background-image: url(${ChevronUrl});
    background-repeat: no-repeat;
  }
  .DateRangePicker__PaginationArrowIcon--previous {
    border-right: unset;
    transform: rotate(90deg);
  }
  .DateRangePicker__PaginationArrowIcon--next {
    border-left: unset;
    transform: rotate(270deg);
    top: 4px;
  }
  .DateRangePicker__Date--otherMonth {
    opacity: 1;
    .DateRangePicker__DateLabel {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
    }
    &.DateRangePicker__Date--is-selected {
      .DateRangePicker__DateLabel {
        color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
      }
    }
  }
  .DateRangePicker__Date {
    border: none;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  }
  .DateRangePicker__WeekdayHeading {
    border-bottom-width: 0;
    & > abbr[title] {
      font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
      font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.fontSize};
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
    }
  }
  .DateRangePicker__Date--is-disabled {
    .DateRangePicker__DateLabel {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
    }
  }
  .DateRangePicker__Date--weekend {
    background-color: transparent;
  }
  .DateRangePicker__MonthDates {
    border-bottom: unset;
    border-spacing: 0;
  }
  // Styles for the selected date range
  .DateRangePicker__CalendarSelection {
    background-color: #145d524d;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    border: unset;
    .DateRangePicker__Date--is-selected {
      background-color: #145d524d;
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }
  .DateRangePicker__Date--is-selected {
    .DateRangePicker__DateLabel {
      color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }

  // Styles for hovering over a date
  .DateRangePicker__CalendarHighlight--single {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
    border: none;
    border-radius: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  // Styles for start and end date
  .DateRangePicker__Date {
    &.DateRangePicker__Date {
      .DateRangePicker__CalendarSelection--start {
        background-color: ${(props: ThemeProp) => props.theme.colors.primary.c800};
      }

      .DateRangePicker__CalendarSelection--end {
        background-color: ${(props: ThemeProp) => props.theme.colors.primary.c800};
      }
    }
  }

  .DateRangePicker__CalendarSelection--single--is-pending {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    ${(props: DateRangePickerProps) => props.skipPendingState && 'background-color: transparent;'}

    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
      background-color: ${(props: ThemeProp) => props.theme.colors.primary.c800};
    }
  }
`;

export const StyledDateTimeInput = styled(DateTimeInput)`
  & .nextui-input {
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  }
`;

interface PredefinedSelectionButtonProps {
  isFirst?: boolean;
  isSelected?: boolean;
}

type ButtonProps = PredefinedSelectionButtonProps & ThemeProp;

export const PredefinedSelectionButton = styled.div<PredefinedSelectionButtonProps>`
  display: flex;
  align-items: center;
  height: 2.875rem;
  padding: 0 1rem;
  cursor: pointer;
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.lineHeight};
  background-color: ${(props: ButtonProps) => (props.isSelected ? props.theme.colors.neutrals.c700 : 'transparent')};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};

  @media (max-width: ${breakpoints.hiddenRightPanel}) {
    height: 2.375rem;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.oneColumn}) {
    padding: 0 0.5rem;
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    flex-grow: 1;
  }
`;

export const CustomPredefinedRangeButton = styled(PredefinedSelectionButton)`
  @media (max-width: ${breakpoints.oneColumn}) {
    display: none;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 29.5rem;
  padding: 0.75rem;
  margin: 0 0.5rem 1rem 0.5rem;
  gap: 0.75rem;
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.lineHeight};
`;

export const MinimumDateErrorMessageContainer = styled(MessageContainer)`
  background: ${(props: ThemeProp) => `${props.theme.colors.system.error.c300}1A`};
  color: ${(props: ThemeProp) => props.theme.colors.system.error.c100};

  svg {
    path {
      fill: ${(props: ThemeProp) => props.theme.colors.system.error.c300};
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 1.125rem;
`;

export const MinimumDateInformationMessageContainer = styled(MessageContainer)`
  margin-top: 0.75rem;
  background: ${(props: ThemeProp) => `${props.theme.colors.primary.c500}14`};
  color: ${(props: ThemeProp) => props.theme.colors.primary.c100};

  svg {
    path {
      fill: ${(props: ThemeProp) => props.theme.colors.primary.c300};
    }
  }
`;
