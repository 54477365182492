import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { ListingsCountry, useMeQuery, User } from '../types/api.graphql';
import { useAuthContext } from './AuthContext';

type Nullable<T> = T | null;

interface SessionContextProps {
  me: Nullable<User>;
  selectedCountry: ListingsCountry;
  setSelectedCountry: (value: ListingsCountry) => void;
  hasOrgWithPaidSubscription: boolean;
}

interface SessionProviderProps {
  children: ReactNode;
}

const SessionContext = createContext<SessionContextProps>({
  me: null,
  selectedCountry: null,
  setSelectedCountry: null,
  hasOrgWithPaidSubscription: false,
});

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const { data, loading } = useMeQuery();
  const [selectedCountry, setSelectedCountry] = useState<ListingsCountry>(null);
  const { logout } = useAuthContext();

  useEffect(() => {
    if (!data?.me && !loading) {
      void logout();
    }
  }, [data, loading]);

  const hasOrgWithPaidSubscription = false;

  return (
    <SessionContext.Provider
      value={{
        me: data?.me || null,
        selectedCountry,
        setSelectedCountry,
        hasOrgWithPaidSubscription,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext: () => SessionContextProps = () => {
  return React.useContext(SessionContext);
};
