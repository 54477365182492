import * as turf from '@turf/turf';
import { Units } from '@turf/turf';

// This is needed to excluding GL JS explicitly from transpilation
// See https://docs.mapbox.com/mapbox-gl-js/guides/install/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { AutocompletePlace } from '../../components/PlacesAutocomplete';
import { GeoFilter, GeoFilterType } from '../../contexts/KPIsAndFilterContext';
import { DistanceUnit, ListingFilterInput } from '../../types/api.graphql';
import { hashCode } from '../helpers';
import { DrawProps, GeoFilterHelper, MarkerProps } from './GeoFilterHelper';

export interface GeoFilterCircleData {
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  radius?: number;
  unit?: DistanceUnit;
  coordinates?: { longitude: number; latitude: number };
}

export default class CircularGeoFilterHelper extends GeoFilterHelper<GeoFilterCircleData> {
  generateId(): number {
    return hashCode(
      `${this.geoFilter.data.coordinates?.latitude},${this.geoFilter.data.coordinates.longitude}, ${this.geoFilter.data.radius}, ${this.geoFilter.data?.unit}`,
    );
  }

  isEqual(geoFilter: GeoFilter): boolean {
    if (geoFilter.type !== this.geoFilter.type) return false;

    if (geoFilter.data.coordinates.latitude !== this.geoFilter.data.coordinates.latitude) return false;
    if (geoFilter.data.radius !== this.geoFilter.data.radius) return false;
    if (geoFilter.data.unit !== this.geoFilter.data.unit) return false;
    if (geoFilter.data.zipCodes?.length !== this.geoFilter.zipCodes?.length) return false;
    for (let i = 0; i < geoFilter.data.zipCodes.length; i++) {
      if (geoFilter.data.zipCodes[i] !== this.geoFilter.zipCodes[i]) return false;
    }

    return true;
  }

  toGraphQLGeoFilter(skipExcludedZips?: boolean): ListingFilterInput {
    const data = this.geoFilter.data;
    return {
      geoFilter: {
        operator: {
          distance: {
            distance: data.radius,
            location: data.coordinates,
            unit: data.unit,
          },
        },
        place: {
          postalCode: data.postalCode,
          address: data.address,
        },
        ...(!skipExcludedZips && { excludedZipCodes: this.geoFilter.excludedZipCodes }),
      },
    };
  }

  generateIdForGeoFilterGraphQl(): number {
    return hashCode(
      `${this.geoFilterGraphQL.operator.distance.location.latitude as string},${
        this.geoFilterGraphQL.operator.distance.location.longitude as string
      }, ${this.geoFilterGraphQL.operator.distance.unit as string}`,
    );
  }

  fromGraphQLGeoFilter(): GeoFilter<GeoFilterCircleData> {
    const data: GeoFilterCircleData = {
      radius: this.geoFilterGraphQL.operator.distance?.distance,
      coordinates: this.geoFilterGraphQL.operator.distance?.location,
      unit: this.geoFilterGraphQL.operator.distance?.unit,
      postalCode: this.geoFilterGraphQL.place?.postalCode,
      address: this.geoFilterGraphQL.place?.address,
    };
    return {
      id: this.generateIdForGeoFilterGraphQl(),
      type: GeoFilterType.Circle,
      data,
      excludedZipCodes: this.geoFilterGraphQL.excludedZipCodes,
    };
  }

  getLocation(): AutocompletePlace {
    return {
      address: this.geoFilter.data.address,
      coordinates: this.geoFilter.data.coordinates,
      zip: this.geoFilter.data.postalCode,
      country: '',
      city: this.geoFilter.data.city,
      state: this.geoFilter.data.state,
    };
  }

  getFullAddress(): string {
    return [
      this.geoFilter.data.address,
      this.geoFilter.data.city,
      this.geoFilter.data.state,
      this.geoFilter.data.postalCode,
    ]
      .map((component) => component?.trim())
      .filter((component) => !!component)
      .join(', ');
  }

  getCenter() {
    return this.geoFilter.data.coordinates;
  }

  getBoundingBox() {
    const pointCoordinates = [this.geoFilter.data.coordinates?.longitude, this.geoFilter.data.coordinates?.latitude];
    const point = turf.point(pointCoordinates);
    const units = this.geoFilter.data.unit.toLowerCase() || 'miles';
    const options = { units: units as Units };
    const buffered = turf.buffer(point, this.geoFilter?.data.radius, options);
    return turf.bbox(buffered);
  }

  getInfoText(): string {
    const units = this.geoFilter.data.unit?.toLowerCase() || 'miles';
    return `${this.geoFilter.data.radius} ${units}`;
  }

  drawLayer(props: DrawProps) {
    const units = this.geoFilter.data.unit.toLowerCase() || 'miles';
    const options = { units: units as Units };
    const center = this.getCenter();
    const position = [center.longitude, center.latitude];
    const circle = turf.circle(position, this.geoFilter.data.radius, options);

    const circleSourceId = `circle-${this.geoFilter.id}`;
    if (!props.map.getSource(circleSourceId)) {
      props.map.addSource(`circle-${this.geoFilter.id}`, {
        type: 'geojson',
        data: circle,
      });
    }

    const layerFillId = `circle-fill-${this.geoFilter.id}`;
    if (props.map.getLayer(layerFillId)) {
      props.map.removeLayer(layerFillId);
    }
    props.map.addLayer({
      id: layerFillId,
      type: 'fill',
      source: circleSourceId,
      paint: {
        'fill-color': props.color,
        'fill-opacity': 0.1,
      },
    });

    const layerStrokeId = `circle-stroke-${this.geoFilter.id}`;
    if (props.map.getLayer(layerStrokeId)) {
      props.map.removeLayer(layerStrokeId);
    }
    props.map.addLayer({
      id: layerStrokeId,
      type: 'line',
      source: circleSourceId,
      paint: {
        'line-color': props.color,
        'line-width': 1.5,
        'line-dasharray': [3, 3],
      },
    });

    return { layerFillId };
  }

  drawMarker(props: MarkerProps) {
    return new mapboxgl.Marker(null).setLngLat(props.position).addTo(props.map);
  }
}
