import i18next from 'i18next';

import { absoluteNumberFormat } from '../../../../../../components/charts/utils';
import { Filter } from '../../../../../../contexts/KPIsAndFilterContext';
import i18n from '../../../../../../locale';
import { FieldType, FilterField, FilterStringField, ListingFilter } from '../../../../../../types/api.graphql';

export interface FilterAnswer {
  title: string;
  disabled?: boolean;
}

export interface FilterAnswers {
  items: FilterAnswer[];
  separator?: string;
  disableEdit?: boolean;
}

abstract class FilterHelper {
  protected filter: Filter;

  constructor(filter: Filter) {
    this.filter = filter;
  }

  public static createInstance(filter: Filter): FilterHelper {
    if (!filter) return null;
    switch (filter.type) {
      case FieldType.Model: {
        return new ModelFilterHelper(filter);
      }
      case FieldType.Make: {
        return new MakeFilterHelper(filter);
      }
      case FieldType.String: {
        return new StringFilterHelper(filter);
      }
      case FieldType.Number:
        return new NumberFilterHelper(filter);
      case FieldType.Boolean:
        return new BooleanFilterHelper(filter);
      case FieldType.Date:
        return new DateFilterHelper(filter);
    }
    return null;
  }

  shouldLoadOptions = () => {
    return false;
  };

  shouldHavePagination = () => {
    return false;
  };

  shouldShowSearchBar = () => {
    return false;
  };

  shouldFormatNumberInputs = () => {
    return false;
  };

  // We include the optional parameter 'make' for the MakeModelFilterHelper.
  abstract getAnswers(selectedFilter: ListingFilter, make?: string, shouldFormatNumbers?: boolean): FilterAnswers;
  abstract removeAnswer(selectedFilter: ListingFilter, answer: FilterAnswer, make?: string): ListingFilter;
}

class ModelFilterHelper extends FilterHelper {
  getAnswers(selectedFilter: ListingFilter, make: string): FilterAnswers {
    const items = selectedFilter?.makeModelFilter?.combinations
      ?.find((combination) => combination.make === make)
      ?.model?.map((option) => ({ title: option }));
    const separator = 'or';

    return { items: items || [], separator };
  }

  removeAnswer(selectedFilter: ListingFilter, answer: FilterAnswer, make: string): ListingFilter {
    // deep copy
    const filterTemp = JSON.parse(JSON.stringify(selectedFilter));
    const index = filterTemp.makeModelFilter?.combinations
      ?.find((combination) => combination.make === make)
      ?.model?.indexOf(answer.title);

    // Now I have the index of the model that is being removed.
    if (index !== -1) {
      filterTemp.makeModelFilter?.combinations
        ?.find((combination) => combination.make === make)
        ?.model?.splice(index, 1);
    }
    return filterTemp;
  }
  shouldLoadOptions = () => {
    return true;
  };

  shouldShowSearchBar = () => {
    return true;
  };
}

class MakeFilterHelper extends FilterHelper {
  getAnswers(selectedFilter: ListingFilter): FilterAnswers {
    const items = selectedFilter?.makeModelFilter?.combinations?.map((combination) => ({
      title: combination.make,
    }));
    const separator = 'or';
    return { items: items || [], separator };
  }

  removeAnswer(selectedFilter: ListingFilter, answer: FilterAnswer): ListingFilter {
    // deep copy
    const filterTemp = JSON.parse(JSON.stringify(selectedFilter));
    const index = filterTemp.makeModelFilter?.combinations?.findIndex(
      (combination) => combination.make === answer.title,
    );
    if (index !== -1) {
      filterTemp.makeModelFilter?.combinations?.splice(index, 1);
    }
    return filterTemp.makeModelFilter?.combinations?.length > 0 && filterTemp;
  }

  shouldLoadOptions = () => {
    return true;
  };

  shouldHavePagination = () => {
    return true;
  };

  shouldShowSearchBar = () => {
    return true;
  };
}

class StringFilterHelper extends FilterHelper {
  getAnswers(selectedFilter: ListingFilter): FilterAnswers {
    const items = selectedFilter?.stringFilter?.operator?.in?.map((option) => ({ title: option }));
    const separator = this.filter.configuration?.stringFilterConfiguration.condition;
    return { items: items || [], separator };
  }
  removeAnswer(selectedFilter: ListingFilter, answer: FilterAnswer): ListingFilter {
    // deep copy
    const filterTemp = JSON.parse(JSON.stringify(selectedFilter));
    const index = filterTemp.stringFilter?.operator?.in?.indexOf(answer.title);
    if (index !== -1) {
      filterTemp.stringFilter?.operator?.in?.splice(index, 1);
    }
    return filterTemp.stringFilter?.operator?.in?.length > 0 && filterTemp;
  }

  shouldLoadOptions = () => {
    return Object.values(FilterStringField).includes(this.filter.filterField as unknown as FilterStringField);
  };

  shouldHavePagination = () => {
    // Include those filters for which we have BE support for it.
    const filtersThatShouldHavePagination = [
      FilterField.Series,
      FilterField.Style,
      FilterField.FuelTypeFromVin,
      FilterField.EngineFromVin,
      FilterField.DealerName,
      FilterField.DealerCity,
      FilterField.DealerState,
    ];

    return filtersThatShouldHavePagination.includes(this.filter.filterField);
  };

  shouldShowSearchBar = () => {
    // Include those specified in RT-691.
    const filtersThatShouldShowSearchBar = [
      FilterField.Series,
      FilterField.Style,
      FilterField.FuelTypeFromVin,
      FilterField.EngineFromVin,
      FilterField.DealerName,
      FilterField.DealerCity,
      FilterField.DealerState,
    ];
    return filtersThatShouldShowSearchBar.includes(this.filter.filterField);
  };
}

export interface NumberFilterBounds {
  min: number;
  max: number;
}

export class NumberFilterHelper extends FilterHelper {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAnswers(selectedFilter: ListingFilter, _make: string = undefined, shouldFormatNumbers?: boolean): FilterAnswers {
    const from = shouldFormatNumbers
      ? i18n.format(selectedFilter?.numberFilter?.operator.between.from, 'number', undefined, absoluteNumberFormat)
      : selectedFilter?.numberFilter?.operator.between.from;
    const to = shouldFormatNumbers
      ? i18n.format(selectedFilter?.numberFilter?.operator.between.to, 'number', undefined, absoluteNumberFormat)
      : selectedFilter?.numberFilter?.operator.between.to;
    const items = selectedFilter?.numberFilter?.operator.between ? [{ title: `${from} - ${to}` }] : [];
    return { items: items || [] };
  }

  removeAnswer(): ListingFilter {
    return null;
  }

  extractSegmentFilter(audienceFilter: ListingFilter, selectedFilter: ListingFilter): ListingFilter {
    return selectedFilter || audienceFilter;
  }

  getBounds = (): NumberFilterBounds => {
    return {
      min: this.filter.configuration?.numberFilterConfiguration?.from,
      max: this.filter.configuration?.numberFilterConfiguration?.to,
    };
  };

  shouldFormatNumberInputs = () => {
    const filtersThatShouldFormatNumberInputs = [FilterField.Price, FilterField.Mileage];
    return filtersThatShouldFormatNumberInputs.includes(this.filter.filterField);
  };
}

class BooleanFilterHelper extends FilterHelper {
  getAnswers(selectedFilter: ListingFilter): FilterAnswers {
    const items = selectedFilter?.booleanFilter
      ? [{ title: selectedFilter?.booleanFilter?.operator?.eq ? i18next.t('common.yes') : i18next.t('common.no') }]
      : [];
    return { items: items || [] };
  }

  removeAnswer(): ListingFilter {
    return null;
  }
}

class DateFilterHelper extends FilterHelper {
  getAnswers(selectedFilter: ListingFilter): FilterAnswers {
    const items = selectedFilter?.dateFilter ? [] : [];
    return { items: items || [] };
  }

  removeAnswer(): ListingFilter {
    throw new Error('Method not implemented.');
  }
}

export default FilterHelper;
