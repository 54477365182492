import { useEffect, useState } from 'react';

export const usePersistState = (defaultValue, key: string) => {
  const [value, setValue] = useState(() => {
    const persistedValue = window.localStorage.getItem(key);
    return persistedValue !== null ? JSON.parse(persistedValue) : defaultValue;
  });

  // Effect hook to update the internal state whenever the 'key' changes
  useEffect(() => {
    const persistedValue = window.localStorage.getItem(key);
    if (persistedValue !== null) {
      setValue(JSON.parse(persistedValue));
    }
  }, [key]);

  // Effect hook to update the localStorage whenever the 'key' or 'value' changes
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
