import React, { SVGProps } from 'react';

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.9352 5.04607L16.0536 4.9375H15.893H4.10703H3.94637L4.0648 5.04607L9.95777 10.4484L10 10.4871L10.0422 10.4484L15.9352 5.04607ZM16.875 15.0625H16.9375V15V5.79609V5.65401L16.8328 5.75002L10.3804 11.6649L10.3804 11.6649C10.2766 11.7602 10.1409 11.813 10 11.813C9.85913 11.813 9.72338 11.7602 9.61961 11.6649L9.61958 11.6649L3.16723 5.75002L3.0625 5.65401V5.79609V15V15.0625H3.125H16.875ZM2.5 3.8125H17.5C17.6492 3.8125 17.7923 3.87176 17.8977 3.97725C18.0032 4.08274 18.0625 4.22582 18.0625 4.375V15C18.0625 15.3149 17.9374 15.617 17.7147 15.8397C17.492 16.0624 17.1899 16.1875 16.875 16.1875H3.125C2.81006 16.1875 2.50801 16.0624 2.28531 15.8397C2.06261 15.617 1.9375 15.3149 1.9375 15V4.375C1.9375 4.22582 1.99676 4.08274 2.10225 3.97725C2.20774 3.87176 2.35082 3.8125 2.5 3.8125Z"
      strokeWidth="0.125"
    />
  </svg>
);

export default MailIcon;
