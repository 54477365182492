import React, { ReactNode } from 'react';

import { BottomSheetChangeBreakpoint } from '../Modal';
import {
  CloseButton,
  CloseButtonTabletBottomSheet,
  GoBackButton,
  HeaderContainer,
  StyledChevron,
  StyledCloseIcon,
  Title,
} from './ModalHeader.styles';

interface ModalHeaderProps {
  title?: string | ReactNode;
  hideCloseButton?: boolean;
  closeButtonAtEnd?: boolean; // If true, the close button will be at the end of the header
  bottomSheetChangeBreakpoint?: BottomSheetChangeBreakpoint;
  onCloseButtonClick?: () => void;
  customIcon?: React.ReactNode;
  modalHeaderPadding?: string;
  onGoBack?: () => void;
}

const ModalHeader = ({
  title,
  hideCloseButton = false,
  closeButtonAtEnd,
  bottomSheetChangeBreakpoint,
  onCloseButtonClick,
  customIcon,
  modalHeaderPadding,
  onGoBack,
}: ModalHeaderProps) => {
  return (
    <HeaderContainer $padding={modalHeaderPadding} $closeButtonAtEnd={!hideCloseButton && closeButtonAtEnd}>
      {!hideCloseButton &&
        (bottomSheetChangeBreakpoint === BottomSheetChangeBreakpoint.Mobile ? (
          <CloseButton
            width="1.25rem"
            height="1.25rem"
            $closeButtonAtEnd={!hideCloseButton && closeButtonAtEnd}
            icon={customIcon || <StyledCloseIcon />}
            onClick={onCloseButtonClick}
          />
        ) : (
          <CloseButtonTabletBottomSheet icon={<StyledCloseIcon />} onClick={onCloseButtonClick} />
        ))}
      {title && (typeof title === 'string' ? <Title>{title}</Title> : title)}
      {onGoBack !== undefined &&
        (bottomSheetChangeBreakpoint === BottomSheetChangeBreakpoint.Mobile ? (
          <GoBackButton icon={<StyledChevron />} onClick={onGoBack} />
        ) : (
          <CloseButtonTabletBottomSheet icon={<StyledChevron />} onClick={onGoBack} />
        ))}
    </HeaderContainer>
  );
};

export default ModalHeader;
