import React, { SVGProps } from 'react';

const ListingVolumeTypeKpiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 2.25C3.0335 2.25 2.25 3.0335 2.25 4V20C2.25 20.9665 3.0335 21.75 4 21.75H9C9.41421 21.75 9.75 21.4142 9.75 21C9.75 20.5858 9.41421 20.25 9 20.25H4C3.86193 20.25 3.75 20.1381 3.75 20V4C3.75 3.86193 3.86193 3.75 4 3.75H20C20.1381 3.75 20.25 3.86193 20.25 4V10C20.25 10.4142 20.5858 10.75 21 10.75C21.4142 10.75 21.75 10.4142 21.75 10V4C21.75 3.0335 20.9665 2.25 20 2.25H4Z" />
      <path d="M10.3071 7.17076C10.5839 7.47894 10.5584 7.95313 10.2502 8.22989L8.41212 9.88058C8.12703 10.1366 7.69478 10.1366 7.40974 9.88046L6.49667 9.06006C6.18856 8.78323 6.16321 8.30903 6.44004 8.00092C6.71688 7.6928 7.19108 7.66745 7.49919 7.94429L7.91113 8.31441L9.24795 7.11387C9.55614 6.83711 10.0303 6.86258 10.3071 7.17076Z" />
      <path d="M12.2505 9C12.2505 8.58579 12.5862 8.25 13.0005 8.25H17.0021C17.4163 8.25 17.7521 8.58579 17.7521 9C17.7521 9.41421 17.4163 9.75 17.0021 9.75H13.0005C12.5862 9.75 12.2505 9.41421 12.2505 9Z" />
      <path d="M10.2502 13.2299C10.5584 12.9531 10.5838 12.4789 10.3071 12.1708C10.0303 11.8626 9.55612 11.8371 9.24795 12.1139L7.91112 13.3145L7.49919 12.9443C7.19108 12.6675 6.71688 12.6929 6.44004 13.001C6.16321 13.3091 6.18856 13.7833 6.49667 14.0601L7.40974 14.8805C7.69478 15.1366 8.12704 15.1367 8.41213 14.8806L10.2502 13.2299Z" />
      <path
        d="M12.9346 13.4954L11.7553 16.25H11.5C11.0858 16.25 10.75 16.5858 10.75 17C10.75 17.4142 11.0858 17.75 11.5 17.75V21C11.5 21.6942 12.0558 22.25 12.75 22.25H13.75C14.4442 22.25 15 21.6942 15 21V20.75H18.5V21C18.5 21.6942 19.0558 22.25 19.75 22.25H20.75C21.4442 22.25 22 21.6942 22 21V17.75C22.4142 17.75 22.75 17.4142 22.75 17C22.75 16.5858 22.4142 16.25 22 16.25H21.7448L20.5695 13.5048L20.5654 13.4954C20.3672 13.0494 19.9217 12.75 19.42 12.75H14.08C13.5783 12.75 13.1328 13.0494 12.9346 13.4954ZM14.2432 14.25L13.3869 16.25H20.1131L19.2568 14.25H14.2432ZM13 20.75V17.75H20.5V20.75H20V20.5C20 19.8058 19.4442 19.25 18.75 19.25H14.75C14.0558 19.25 13.5 19.8058 13.5 20.5V20.75H13Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ListingVolumeTypeKpiIcon;
