import React, { SVGProps } from 'react';

const CardsViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Leading icon">
      <path
        id="Vector"
        d="M14.375 5.625H3.125C2.79348 5.625 2.47554 5.7567 2.24112 5.99112C2.0067 6.22554 1.875 6.54348 1.875 6.875V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H14.375C14.7065 16.875 15.0245 16.7433 15.2589 16.5089C15.4933 16.2745 15.625 15.9565 15.625 15.625V6.875C15.625 6.54348 15.4933 6.22554 15.2589 5.99112C15.0245 5.7567 14.7065 5.625 14.375 5.625ZM14.375 15.625H3.125V6.875H14.375V15.625ZM18.125 4.375V13.75C18.125 13.9158 18.0592 14.0747 17.9419 14.1919C17.8247 14.3092 17.6658 14.375 17.5 14.375C17.3342 14.375 17.1753 14.3092 17.0581 14.1919C16.9408 14.0747 16.875 13.9158 16.875 13.75V4.375H5C4.83424 4.375 4.67527 4.30915 4.55806 4.19194C4.44085 4.07473 4.375 3.91576 4.375 3.75C4.375 3.58424 4.44085 3.42527 4.55806 3.30806C4.67527 3.19085 4.83424 3.125 5 3.125H16.875C17.2065 3.125 17.5245 3.2567 17.7589 3.49112C17.9933 3.72554 18.125 4.04348 18.125 4.375Z"
      />
    </g>
  </svg>
);

export default CardsViewIcon;
