import styled from 'styled-components';

import { ThinPenIcon } from '../../assets';
import { Avatar } from '../../components';
import IconButton from '../../components/IconButton';
import { ThemeProp } from '../../theme/Themes';

interface StyledAvatarProps {
  avatarSize: string;
}

export const AvatarContainer = styled.div<StyledAvatarProps>`
  display: flex;
  position: relative;
  width: ${(props: StyledAvatarProps) => props.avatarSize};
  height: ${(props: StyledAvatarProps) => props.avatarSize};
  cursor: pointer;
`;

export const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const EditIcon = styled(ThinPenIcon)`
  fill: none !important;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
`;

export const EditAvatarButton = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
  z-index: 100;
`;

export const UploadImageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  gap: 1rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
`;

export const UploadImageTextContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 1.125rem 0;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0;
  }
`;

export const UploadImageTextTitle = styled.p`
  letter-spacing: normal;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.lineHeight};
  margin-bottom: 0.5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
    font-size: ${(props: ThemeProp) => props.theme.fontVariants.mobile.buttons.sm.fontSize};
    line-height: ${(props: ThemeProp) => props.theme.fontVariants.mobile.buttons.sm.lineHeight};
  }
`;

export const UploadImageTextSubtitle = styled.p`
  letter-spacing: normal;
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.lineHeight};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const UploadPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.75rem;
  height: 5.75rem;
  border: 2px dashed ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  :hover {
    border: 2px dashed ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
    cursor: pointer;
    svg {
      path {
        fill: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
      }
    }
  }
  border-radius: 0.18rem;
  svg {
    path {
      fill: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
    }
  }
`;

export const EditAvatarButtonTopRight = styled<any>(IconButton)`
  position: absolute;
  right: -0.75rem;
  top: -0.5rem;
  width: ${(props: { $size: string }) => props.$size || '1.8rem'};
  height: ${(props: { $size: string }) => props.$size || '1.8rem'};
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  border-radius: ${(props) => (props.squared ? '0.375rem' : '50%')};
  z-index: 100;
  border: 3px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  box-sizing: content-box;
`;
