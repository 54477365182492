import React, { ReactNode } from 'react';

import { Badge, Button } from './IconButton.styles';

interface IconButtonProps {
  width?: string;
  height?: string;
  icon?: ReactNode;
  showBadge?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primaryBackground?: boolean;
  borderColor?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  noBorder?: boolean;
}

const IconButton = ({
  children,
  width,
  height,
  icon,
  showBadge,
  onClick,
  primaryBackground,
  borderColor,
  disabled,
  noBorder = true,
  ...rest
}: IconButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => onClick(e)}
      $borderColor={borderColor}
      $width={width}
      $height={height}
      $noBorder={noBorder}
      $primaryBackground={primaryBackground}
      {...rest}
    >
      {icon || children}
      {showBadge && <Badge>1</Badge>}
    </Button>
  );
};

export default IconButton;
