import { Loading } from '@nextui-org/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadIcon } from '../../assets';
import FileUploader from '../FileUploader';
import {
  AvatarContainer,
  EditAvatarButtonTopRight,
  EditIcon,
  LoadingWrapper,
  StyledAvatar,
  UploadImageContainer,
  UploadImageTextContainer,
  UploadImageTextSubtitle,
  UploadImageTextTitle,
  UploadPlaceholder,
} from './AvatarUploader.styles';

interface AvatarUploaderProps {
  avatarSize?: string;
  imageUrl?: string;
  onUploadFinish: (fileUrl: string) => void;
  avatarInitials: string;
  avatarInitialsSize?: string;
  showUploadLogoContainer?: boolean;
  showEditButton?: boolean;
  editButtonSize?: string;
  editButtonSquared?: boolean;
}

const AvatarUploader = ({
  imageUrl,
  onUploadFinish,
  avatarSize = '10rem',
  avatarInitials,
  avatarInitialsSize,
  showUploadLogoContainer,
  showEditButton,
  editButtonSize,
  editButtonSquared,
}: AvatarUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  const onAvatarClick = (e?: any) => {
    e.preventDefault();
    fileInputRef?.current.click();
  };

  const { t } = useTranslation();

  return (
    <>
      <FileUploader
        inputRef={fileInputRef}
        setLoading={setUploadingAvatar}
        onUploadFinish={onUploadFinish}
        accept="image/*"
      />
      {!imageUrl && showUploadLogoContainer && (
        <UploadImageContainer>
          <UploadPlaceholder onClick={onAvatarClick}>
            {uploadingAvatar ? <Loading size="lg" /> : <UploadIcon />}
          </UploadPlaceholder>
          <UploadImageTextContainer>
            <UploadImageTextTitle>{t('components.avatarUploader.uploadLogo')}</UploadImageTextTitle>
            <UploadImageTextSubtitle>{t('components.avatarUploader.uploadLogoSubtitle1')}</UploadImageTextSubtitle>
            <UploadImageTextSubtitle>{t('components.avatarUploader.uploadLogoSubtitle2')}</UploadImageTextSubtitle>
          </UploadImageTextContainer>
        </UploadImageContainer>
      )}
      {(imageUrl || !showUploadLogoContainer) && (
        <>
          <AvatarContainer avatarSize={avatarSize} onClick={(e) => onAvatarClick(e)}>
            <StyledAvatar
              fontSize={avatarInitialsSize || '4.5rem'}
              squared
              text={!uploadingAvatar && avatarInitials}
              src={imageUrl}
            />
            {showEditButton && (
              <EditAvatarButtonTopRight
                $size={editButtonSize}
                squared={editButtonSquared}
                icon={<EditIcon />}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onAvatarClick(e)}
              />
            )}
          </AvatarContainer>
        </>
      )}
      {uploadingAvatar && !showUploadLogoContainer && (
        <LoadingWrapper>
          <Loading size="lg" />
        </LoadingWrapper>
      )}
    </>
  );
};

export default AvatarUploader;
