import styled from 'styled-components';

import { ChevronIcon, CloseIcon } from '../../../assets';
import { ThemeProp } from '../../../theme/Themes';
import IconButton from '../../IconButton';

interface HeaderContainerProps {
  $mobileModal?: boolean;
  $closeButtonAtEnd?: boolean;
  $padding?: string;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.$closeButtonAtEnd ? 'row-reverse' : 'row')};
  justify-content: ${(props) => (props.$closeButtonAtEnd ? 'flex-end' : 'flex-start')};
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  position: relative;
  padding: ${(props: HeaderContainerProps) => props.$padding ?? '2rem 2rem 0 2rem'};
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 0.25rem 0;
    justify-content: ${(props) => (props.$closeButtonAtEnd ? 'space-between' : 'flex-start')};
  }
`;

export const Title = styled.span`
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  align-self: flex-end;
  text-align: center;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    font-size: ${(props: ThemeProp) => props.theme.fontVariants.mobile.headline.h2.fontSize};
    line-height: ${(props: ThemeProp) => props.theme.fontVariants.mobile.headline.h2.lineHeight};
  }
`;

export const CloseButton = styled(IconButton)<{ $closeButtonAtEnd?: boolean }>`
  ${(props: { $closeButtonAtEnd?: boolean }) => (props.$closeButtonAtEnd ? 'margin-left: auto;' : '')}

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: none !important;
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    /* Ignore absolute positioning, and arrange it with margin and padding */
    position: static;
    right: 0;
    top: 0;
    padding-top: 1px;

    svg {
      stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }
`;

export const CloseButtonTabletBottomSheet = styled(CloseButton)`
  // We must show the same button we show at mobile
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    /* Ignore absolute positioning, and arrange it with margin and padding */
    position: static;
    right: 0;
    top: 0;
    margin-left: -0.5rem;
    padding-top: 1px;

    svg {
      stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
`;

export const StyledChevron = styled(ChevronIcon)`
  rotate: 90deg;
`;

export const GoBackButton = styled(CloseButton)`
  margin-left: unset;
  svg {
    margin-top: 0.125rem;
    width: 1rem;
    height: 1rem;
  }
`;
