import { ApolloClient } from '@apollo/client';

import { KPIsData } from '../contexts/KpiMetricsContext';
import { DateRangeValue } from '../contexts/KPIsAndFilterContext';
import {
  FieldValueMultiMetricDocument,
  KpiLevel,
  KpiMultiMetricDocument,
  ListingDaysOnMarketMultiMetricDocument,
  ListingMarketDaysSupplyMultiMetricDocument,
  ListingVolumeMultiMetricDocument,
} from '../types/api.graphql';
import { chunkArray } from '../utils/helpers';

export interface KPIMultiMetricsPosition {
  field: ValidKPIField;
  position: number;
}

export interface KPIStandardMultiMetricData {
  loading: boolean;
  activeValue: number;
  soldValue: number;
  totalValue: number;
}

export interface KPIFieldValueMultiMetricData extends KPIStandardMultiMetricData {
  activePercentage: number;
  soldPercentage: number;
  totalPercentage: number;
}

export type KPIMultiMetricSegmentData = KPIStandardMultiMetricData | KPIFieldValueMultiMetricData;
interface KPIMultiMetricData {
  [key: string]: KPIMultiMetricSegmentData;
}
export interface KPIMultiMetric {
  name: string;
  filterField: ValidKPIField;
  data: KPIMultiMetricData;
}

// Custom enum to keep track of valid KPI fields.
// These MUST match the ones that are sent in the 'filterField' param in the KpisList query.
export enum ValidKPIField {
  Price = 'price',
  Mileage = 'mileage',
  DaysOnMarket = 'daysOnMarket',
  ListingVolumeMetric = 'dailyListingVolume',
  ListingByFranchise = 'dealerType',
  ListingByCPO = 'certified',
  MarketDaysSupply = 'marketDaysSupply',
  VehicleVolumeMetric = 'dailyVehicleVolume',
  VehiclePrice = 'vehiclePrice',
  VehicleMileage = 'vehicleMileage',
  VehicleMarketDaysSupply = 'vehicleMarketDaysSupply',
  VehicleDaysOnMarket = 'vehicleDaysOnMarket',
  VehicleByFranchise = 'vehicleDealerType',
  VehicleByCPO = 'vehicleCertified',
}

export type KPIFieldTypeType = {
  [key in ValidKPIField]: KpiLevel;
};

export const KPIFieldType: KPIFieldTypeType = {
  [ValidKPIField.Price]: KpiLevel.Listing,
  [ValidKPIField.Mileage]: KpiLevel.Listing,
  [ValidKPIField.ListingVolumeMetric]: KpiLevel.Listing,
  [ValidKPIField.DaysOnMarket]: KpiLevel.Listing,
  [ValidKPIField.ListingByFranchise]: KpiLevel.Listing,
  [ValidKPIField.ListingByCPO]: KpiLevel.Listing,
  [ValidKPIField.MarketDaysSupply]: KpiLevel.Listing,
  [ValidKPIField.VehicleVolumeMetric]: KpiLevel.Vin,
  [ValidKPIField.VehiclePrice]: KpiLevel.Vin,
  [ValidKPIField.VehicleMileage]: KpiLevel.Vin,
  [ValidKPIField.VehicleMarketDaysSupply]: KpiLevel.Vin,
  [ValidKPIField.VehicleDaysOnMarket]: KpiLevel.Vin,
  [ValidKPIField.VehicleByFranchise]: KpiLevel.Vin,
  [ValidKPIField.VehicleByCPO]: KpiLevel.Vin,
};

/**
 * Merges data of each chunk into one unique array of data.
 * @param multiMetricsResponses - Response from the backend query with every KPI requested, divided in chunks.
 */
export function mergeMultiMetricsResponses(multiMetricsResponses: Array<any>): Array<any> {
  const data = [];
  multiMetricsResponses.forEach((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const dataKey = Object.keys(item.data)?.[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    data.push(...(item.data?.[dataKey] || []));
  });

  return data;
}

export function mergeObjectsWithLatest(obj1: KPIMultiMetricData, obj2: KPIMultiMetricData): KPIMultiMetricData {
  const mergedObject: KPIMultiMetricData = { ...obj1 };

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      // If the key exists in obj1, update the value with the one from obj2
      // Otherwise, add the key-value pair to the mergedObject
      mergedObject[key] = obj2[key];
    }
  }

  return mergedObject;
}

const calculatePercentage = (valueCount: number, count: number) => {
  if (valueCount == null || count == null) {
    return null;
  }

  if (count === 0 || valueCount === 0) {
    return 0;
  }

  return valueCount / count;
};

const getFormattedDataForPercentages = (data) => {
  const activeValue = data?.stats?.active?.valueCount as number;
  const soldValue = data?.stats?.sold?.valueCount as number;
  const totalValue = data?.stats?.all?.valueCount as number;
  const activeCount = data?.stats?.active?.count as number;
  const soldCount = data?.stats?.sold?.count as number;
  const totalCount = data?.stats?.all?.count as number;

  return {
    loading: false,
    activeValue,
    soldValue,
    totalValue,
    activePercentage: calculatePercentage(activeValue, activeCount),
    soldPercentage: calculatePercentage(soldValue, soldCount),
    totalPercentage: calculatePercentage(totalValue, totalCount),
  } as KPIFieldValueMultiMetricData;
};

export const formatMultiMetricsData = (multiMetricsRawData: Array<any>, kpi: KPIsData): KPIMultiMetricData => {
  const formattedData: KPIMultiMetricData = {};
  multiMetricsRawData.forEach((data) => {
    if (
      kpi.field === ValidKPIField.ListingByFranchise ||
      kpi.field === ValidKPIField.ListingByCPO ||
      kpi.field === ValidKPIField.VehicleByFranchise ||
      kpi.field === ValidKPIField.VehicleByCPO
    ) {
      formattedData[data.segmentId] = getFormattedDataForPercentages(data);
    } else if (kpi.field === ValidKPIField.MarketDaysSupply || kpi.field === ValidKPIField.VehicleMarketDaysSupply) {
      formattedData[data.segmentId] = {
        loading: false,
        activeValue: data?.value,
      } as KPIStandardMultiMetricData;
    } else {
      formattedData[data.segmentId] = {
        loading: false,
        activeValue: data?.stats?.active?.avg?.value,
        soldValue: data?.stats?.sold?.avg?.value,
        totalValue: data?.stats?.all?.avg?.value,
      } as KPIStandardMultiMetricData;
    }
  });

  return formattedData;
};

const MULTI_METRIC_PROMISE_CHUNK_SIZE = 10;

abstract class KPIsHelper {
  protected kpi: ValidKPIField;
  protected client: ApolloClient<object>;

  constructor(kpi: ValidKPIField, client?: ApolloClient<object>) {
    this.kpi = kpi;
    this.client = client;
  }

  public static createInstance(kpi: ValidKPIField, client?: ApolloClient<object>): KPIsHelper {
    if (!kpi) return null;
    switch (kpi) {
      case ValidKPIField.Price:
        return new PriceKPIHelper(ValidKPIField.Price, client);
      case ValidKPIField.Mileage:
        return new MileageKPIHelper(ValidKPIField.Mileage, client);
      case ValidKPIField.ListingVolumeMetric:
        return new ListingVolumeKPIHelper(ValidKPIField.ListingVolumeMetric, client);
      case ValidKPIField.DaysOnMarket:
        return new ListingDaysOnMarketKPIHelper(ValidKPIField.DaysOnMarket, client);
      case ValidKPIField.ListingByFranchise:
        return new ListingByFranchiseKPIHelper(ValidKPIField.ListingByFranchise, client);
      case ValidKPIField.ListingByCPO:
        return new ListingByCPOKPIHelper(ValidKPIField.ListingByCPO, client);
      case ValidKPIField.MarketDaysSupply:
        return new MarketDaysSupplyKPIHelper(ValidKPIField.MarketDaysSupply, client);
      case ValidKPIField.VehicleVolumeMetric:
        return new VehicleVolumeKPIHelper(ValidKPIField.VehicleVolumeMetric, client);
      case ValidKPIField.VehiclePrice:
        return new VehiclePriceKPIHelper(ValidKPIField.VehiclePrice, client);
      case ValidKPIField.VehicleMileage:
        return new VehicleMileageKPIHelper(ValidKPIField.VehicleMileage, client);
      case ValidKPIField.VehicleMarketDaysSupply:
        return new VehicleDaysSupplyKPIHelper(ValidKPIField.VehicleMarketDaysSupply, client);
      case ValidKPIField.VehicleDaysOnMarket:
        return new VehicleDaysOnMarketKPIHelper(ValidKPIField.VehicleDaysOnMarket, client);
      case ValidKPIField.VehicleByFranchise:
        return new VehicleByFranchiseKPIHelper(ValidKPIField.VehicleByFranchise, client);
      case ValidKPIField.VehicleByCPO:
        return new VehicleByCPOKPIHelper(ValidKPIField.VehicleByCPO, client);
      default:
        return null;
    }
  }

  /**
   * Returns an array of promises of the multi metric query for the given KPI.
   * The array is chunked by 10 segment ids per promise.
   * @param dateRange - date range selected by the user.
   * @param segmentIds - list of segment ids to which the multiMetric query will ask data for.
   * @param avoidCachedResults - if true, the query will not use cached results.
   */
  abstract getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean, // Enforce bringing new data.
  ): Array<{ id: ValidKPIField; query: any }>;
}

class PriceKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: KpiMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          kpi: this.kpi,
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class MileageKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: KpiMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          kpi: this.kpi,
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class ListingVolumeKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingVolumeMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class ListingDaysOnMarketKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingDaysOnMarketMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class ListingByFranchiseKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: FieldValueMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          field: ValidKPIField.ListingByFranchise,
          value: 'Franchise',
        },
      }),
    }));
  }
}

class ListingByCPOKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: FieldValueMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          field: ValidKPIField.ListingByCPO,
          value: 'true',
        },
      }),
    }));
  }
}

class MarketDaysSupplyKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingMarketDaysSupplyMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class VehicleVolumeKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingVolumeMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          kpiLevel: KpiLevel.Vin,
          dateRange,
          segmentIds: segmentIdsChunk,
        },
      }),
    }));
  }
}

class VehiclePriceKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: KpiMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          kpi: ValidKPIField.Price,
          dateRange,
          segmentIds: segmentIdsChunk,
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

class VehicleMileageKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: KpiMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          kpi: ValidKPIField.Mileage,
          dateRange,
          segmentIds: segmentIdsChunk,
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

class VehicleDaysOnMarketKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingDaysOnMarketMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

class VehicleByFranchiseKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: FieldValueMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          field: ValidKPIField.ListingByFranchise,
          value: 'Franchise',
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

class VehicleByCPOKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: FieldValueMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          field: ValidKPIField.ListingByCPO,
          value: 'true',
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

class VehicleDaysSupplyKPIHelper extends KPIsHelper {
  public getMultiMetricQueryPromises(
    dateRange: DateRangeValue,
    segmentIds: Array<string>,
    avoidCachedResults?: boolean,
  ) {
    return chunkArray(segmentIds, MULTI_METRIC_PROMISE_CHUNK_SIZE)?.map((segmentIdsChunk) => ({
      id: this.kpi,
      query: this.client.query({
        query: ListingMarketDaysSupplyMultiMetricDocument,
        fetchPolicy: avoidCachedResults ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
          segmentIds: segmentIdsChunk,
          kpiLevel: KpiLevel.Vin,
        },
      }),
    }));
  }
}

export default KPIsHelper;
