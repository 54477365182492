import { useMemo } from 'react';

import { FilterField, useListingFieldsLazyQuery } from '../types/api.graphql';

const useListingFilters = (): {
  loadFilters: any;
  loading: boolean;
  filters: any;
} => {
  const [loadFilters, { data, loading }] = useListingFieldsLazyQuery({});
  const propertyFilters = useMemo(
    () => data?.listingFields.filter((filter) => filter.filterField !== FilterField.Doors),
    [data],
  );
  return {
    loadFilters,
    loading,
    filters: propertyFilters,
  };
};

export default useListingFilters;
