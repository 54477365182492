import React, { SVGProps } from 'react';

const CheckToastIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={props.color}
      d="M21.5306 7.28061L9.53063 19.2806C9.46098 19.3503 9.37826 19.4057 9.28721 19.4434C9.19616 19.4812 9.09857 19.5006 9 19.5006C8.90144 19.5006 8.80385 19.4812 8.7128 19.4434C8.62175 19.4057 8.53903 19.3503 8.46938 19.2806L3.21938 14.0306C3.07865 13.8899 2.99959 13.699 2.99959 13.5C2.99959 13.301 3.07865 13.1101 3.21938 12.9694C3.36011 12.8286 3.55098 12.7496 3.75001 12.7496C3.94903 12.7496 4.1399 12.8286 4.28063 12.9694L9 17.6897L20.4694 6.21936C20.6101 6.07863 20.801 5.99957 21 5.99957C21.199 5.99957 21.3899 6.07863 21.5306 6.21936C21.6714 6.36009 21.7504 6.55097 21.7504 6.74999C21.7504 6.94901 21.6714 7.13988 21.5306 7.28061Z"
    />
  </svg>
);

export default CheckToastIcon;
