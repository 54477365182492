import styled from 'styled-components';

import { SearchIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

interface ContainerProps {
  $width?: string;
  $height?: string;
  $primaryBackground?: boolean;
  $borderColor?: string;
  $noBorder?: boolean;
  disabled?: boolean;
}

export const Button = styled.button<ContainerProps>`
  background-color: ${(props: ThemeProp & ContainerProps) =>
    props.$primaryBackground
      ? props.theme.colors.primary.c500
      : props.disabled
      ? props.theme.colors.neutrals.c600
      : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props: ContainerProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 0;
  border: ${(props: ThemeProp & ContainerProps) =>
    props.$noBorder ? 'none' : `1px solid ${props.$borderColor || props.theme.colors.neutrals.c500}`};

  width: ${(props: ContainerProps) => props.$width || '2rem'};
  height: ${(props: ContainerProps) => props.$height || '2rem'};
  padding: 0;

  svg {
    fill: ${(props: ThemeProp & ContainerProps) =>
      props.$primaryBackground ? props.theme.colors.neutrals.black : props.theme.colors.neutrals.white};
  }
  :focus {
    box-shadow: unset;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 1rem;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  fill: none !important;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
`;
