import React, { SVGProps } from 'react';

const CrossToastIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={props.color}
      d="M19.2806 18.2194C19.3503 18.289 19.4056 18.3718 19.4433 18.4628C19.481 18.5539 19.5004 18.6514 19.5004 18.75C19.5004 18.8485 19.481 18.9461 19.4433 19.0372C19.4056 19.1282 19.3503 19.2109 19.2806 19.2806C19.2109 19.3503 19.1282 19.4056 19.0372 19.4433C18.9461 19.481 18.8486 19.5004 18.75 19.5004C18.6515 19.5004 18.5539 19.481 18.4628 19.4433C18.3718 19.4056 18.2891 19.3503 18.2194 19.2806L12 13.0603L5.78063 19.2806C5.6399 19.4213 5.44903 19.5004 5.25 19.5004C5.05098 19.5004 4.86011 19.4213 4.71938 19.2806C4.57865 19.1399 4.49959 18.949 4.49959 18.75C4.49959 18.551 4.57865 18.3601 4.71938 18.2194L10.9397 12L4.71938 5.78061C4.57865 5.63988 4.49959 5.44901 4.49959 5.24999C4.49959 5.05097 4.57865 4.8601 4.71938 4.71936C4.86011 4.57863 5.05098 4.49957 5.25 4.49957C5.44903 4.49957 5.6399 4.57863 5.78063 4.71936L12 10.9397L18.2194 4.71936C18.3601 4.57863 18.551 4.49957 18.75 4.49957C18.949 4.49957 19.1399 4.57863 19.2806 4.71936C19.4214 4.8601 19.5004 5.05097 19.5004 5.24999C19.5004 5.44901 19.4214 5.63988 19.2806 5.78061L13.0603 12L19.2806 18.2194Z"
    />
  </svg>
);

export default CrossToastIcon;
