import { NetworkStatus } from '@apollo/client';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { DateRange } from 'moment-range';
import React, { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  blackBookGeoFilters,
  blackBookInitialFilters,
  blackBookPropertyFilters,
  blackBookSegmentsDateRange,
  localStorageKeysPrefix,
} from '../constants';
import { KPIFieldType, ValidKPIField } from '../helpers/KPIsHelper';
import { useDebounce } from '../hooks/useDebounce';
import useListingFilters from '../hooks/useListingFilters';
import { usePersistState } from '../hooks/usePersistState';
import {
  FieldValueGeoMetricQuery,
  FilterField,
  FilterNumberField,
  KpiGeoMetricsQuery,
  Listing,
  ListingFilter,
  ListingMarketDaysSupplyGeoMetricQuery,
  ListingsCountry,
  ListingsDaysOnMarketGeoMetricQuery,
  ListingVolumeGeoMetricQuery,
  SegmentQuery,
  useFieldValueGeoMetricQuery,
  useKpiGeoMetricsQuery,
  useKpiListQuery,
  useListingMarketDaysSupplyGeoMetricQuery,
  useListingsDaysOnMarketGeoMetricQuery,
  useListingsSnapshotLazyQuery,
  useListingVolumeGeoMetricQuery,
  useVehiclesSnapshotLazyQuery,
  ValueField,
  ZoomLevelInput,
} from '../types/api.graphql';
import { createGeoFilterHelper, createParentGeoFilterHelperFromGraphQL } from '../utils/filters';
import { getGeofilterTypeLabel } from '../utils/geoFilter';
import { createUTCDate, getEnumKeyByValue, omitTypename } from '../utils/helpers';
import FilterHelper from '../views/segments/NewSegmentPage/components/PropertyFilterItem/helpers';
import { KPIsData } from './KpiMetricsContext';
import { useSessionContext } from './SessionContext';

type Nullable<T> = T | null;

export interface Filter {
  type: any;
  filterField: FilterField;
  title: string;
  configuration?: any;
  make?: string;
}

export enum GeoFilterType {
  Polygon = 'polygon',
  Circle = 'circle',
  Region = 'region',
}

export interface GeoFilter<T = any> {
  type: GeoFilterType;
  id: number;
  count?: number;
  zipCodes?: { zip: string; count: number; value: number; tooltipCount?: number; tooltipTotal?: number }[];
  excludedZipCodes?: string[];
  data: T;
}

export interface DateFilterOption {
  value: string;
  text: string;
  // If a prefilled date range is selected, this will be set
  days?: number;
  // If a custom date range is selected, this will be set
  range?: DateRange;
}

type KpiGeoMetrics =
  | KpiGeoMetricsQuery
  | ListingVolumeGeoMetricQuery
  | ListingsDaysOnMarketGeoMetricQuery
  | FieldValueGeoMetricQuery
  | ListingMarketDaysSupplyGeoMetricQuery;

interface FilterChip {
  title: string;
  value: string;
  removeFilter?: () => void;
}

export enum SortOrderOptions {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface SortOrder {
  [key: string]: SortOrderOptions;
}

interface KPIsAndFilterContextProps {
  filters: Nullable<Filter[]>;
  loadingFilters: boolean;
  selectedFilters: Record<string, any>;
  hasSelectedFilters: boolean;
  geoFilters: GeoFilter[];
  addGeoFilter: (geoFilter: GeoFilter) => void;
  updateGeoFilter: (filterId: number, geoFilter: GeoFilter, hitEndpoint?: boolean) => void;
  removeGeoFilter: (geoFilter: GeoFilter) => void;
  applyFilter: (filterField: FilterField, filter: any) => void;
  applyModelFilter: (filter: ListingFilter, make: string, isModelFilter?: boolean) => void;
  setSelectedFilters: (filters: Record<string, any>) => void;
  filtersQueryParam: () => any[];
  parentAudienceId: string;
  dateRangeFilter: DateFilterOption;
  setDateRangeFilter: (dateFilter: DateFilterOption) => void;
  segmentsDateRangeFilter: DateFilterOption;
  setSegmentsDateRangeFilter: (dateFilter: DateFilterOption) => void;
  getValueInDateRange: (filterOption: DateFilterOption) => { from: string; to: string };
  listings: Listing[];
  loadingListings: boolean;
  listingsTotalCount: number;
  setGeoFilters: (geoFilters: GeoFilter[]) => void;
  geoFiltersToLoad: GeoFilter[];
  setIsCreatingGeoFilter: (isCreatingGeoFilter: boolean) => void;
  isCreatingGeoFilter: boolean;
  selectedTypeGeoFilterCreation: GeoFilterType;
  setSelectedTypeGeoFilterCreation: (type: GeoFilterType) => void;
  kpiGeoMetrics: KpiGeoMetrics;
  loadingKPIMap: boolean;
  selectedKpiField: ValidKPIField;
  setSelectedKpiField: (KPI: ValidKPIField) => void;
  segmentsKPIs: KPIsData[];
  setSegmentsKPIs: (KPIs: KPIsData[]) => void;
  selectedSegmentsKPIs: KPIsData[];
  updateSegmentsKPIs: (KPIs: KPIsData[]) => void;
  hideSegmentsKPI: (KPI: KPIsData) => void;
  showSegmentsKPI: (KPI: KPIsData) => void;
  hideAllSegmentsKPIs: () => void;
  showAllSegmentsKPIs: () => void;
  KPIs: KPIsData[];
  updateKPIs: (KPIs: KPIsData[]) => void;
  selectedKPIs: KPIsData[];
  hiddenKPIs: KPIsData[];
  hideKPI: (KPI: KPIsData) => void;
  showKPI: (KPI: KPIsData) => void;
  loadingKPIs: boolean;
  hasUnsavedChanges: boolean;
  currentSegment: SegmentQuery['segment'];
  setCurrentSegment: (segment: SegmentQuery['segment']) => void;
  clearState: () => void;
  amountOfFilters: number;
  getFilterChips: () => Array<FilterChip>;
  loadMoreListings: () => Promise<void>;
  hasMoreListings: boolean;
  clearFiltersLocalStorage: () => void;
  defineStatsFromGeoMetricsResponse: (geoMetrics) => any;
  setLoadingKPIMap: (loading: boolean) => void;
  applyMakeFilter: (filter: ListingFilter) => void;
  loadingGeoMetrics: boolean;
  setWasSegmentRenamed: (wasSegmentRenamed: boolean) => void;
  isInitialized: boolean;
  setSegmentId: (segmentId: string) => void;
  listingsNetworkStatus: NetworkStatus;
  showListings: boolean;
  setShowListings: React.Dispatch<React.SetStateAction<boolean>>;
  loadingListingsWhenSwitchChanges: boolean;
  setLoadingListingsWhenSwitchChanges: React.Dispatch<React.SetStateAction<boolean>>;
  vehiclesTotalCount: number;
  listingsSearchQuery: string;
  setListingsSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  listingsSortOrder: SortOrder;
  setListingsSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
}

interface KPIsAndFilterProviderProps {
  children: ReactNode;
}

const KPIsAndFilterContext = createContext<KPIsAndFilterContextProps>({
  filters: null,
  loadingFilters: false,
  hasSelectedFilters: false,
  selectedFilters: {},
  geoFilters: [],
  addGeoFilter: null,
  updateGeoFilter: null,
  removeGeoFilter: null,
  applyFilter: null,
  applyModelFilter: null,
  setSelectedFilters: null,
  filtersQueryParam: null,
  parentAudienceId: null,
  dateRangeFilter: null,
  setDateRangeFilter: null,
  segmentsDateRangeFilter: null,
  setSegmentsDateRangeFilter: null,
  getValueInDateRange: null,
  listings: null,
  loadingListings: false,
  listingsTotalCount: null,
  setGeoFilters: null,
  geoFiltersToLoad: [],
  setIsCreatingGeoFilter: null,
  isCreatingGeoFilter: false,
  selectedTypeGeoFilterCreation: null,
  setSelectedTypeGeoFilterCreation: null,
  kpiGeoMetrics: null,
  loadingKPIMap: false,
  selectedKpiField: null,
  setSelectedKpiField: undefined,
  segmentsKPIs: null,
  setSegmentsKPIs: undefined,
  selectedSegmentsKPIs: null,
  updateSegmentsKPIs: undefined,
  hideSegmentsKPI: undefined,
  showSegmentsKPI: undefined,
  hideAllSegmentsKPIs: undefined,
  showAllSegmentsKPIs: undefined,
  KPIs: null,
  selectedKPIs: null,
  hiddenKPIs: null,
  hideKPI: undefined,
  showKPI: undefined,
  loadingKPIs: false,
  updateKPIs: undefined,
  hasUnsavedChanges: false,
  currentSegment: null,
  setCurrentSegment: null,
  clearState: null,
  amountOfFilters: 0,
  getFilterChips: undefined,
  loadMoreListings: null,
  hasMoreListings: false,
  clearFiltersLocalStorage: null,
  defineStatsFromGeoMetricsResponse: null,
  setLoadingKPIMap: null,
  applyMakeFilter: null,
  loadingGeoMetrics: false,
  setWasSegmentRenamed: null,
  isInitialized: false,
  setSegmentId: null,
  listingsNetworkStatus: null,
  showListings: false,
  setShowListings: null,
  loadingListingsWhenSwitchChanges: false,
  setLoadingListingsWhenSwitchChanges: null,
  vehiclesTotalCount: null,
  listingsSearchQuery: undefined,
  setListingsSearchQuery: null,
  listingsSortOrder: undefined,
  setListingsSortOrder: null,
});

export type PropertyFilterWithoutGeo = Omit<ListingFilter, 'geoFilter'>;
export type PropertyFilterWithoutGeoAndMakeModel = Omit<ListingFilter, 'geoFilter' | 'makeModelFilter'>;

export enum DateFilterOptionValue {
  Last7Days = 'last7days',
  Last15Days = 'last15days',
  Last30Days = 'last30days',
  Last45Days = 'last45days',
  Last90Days = 'last90days',
  CustomDate = 'customDate',
}

export type DateRangeValue = {
  from: string;
  to: string;
};

// We set "days" value as (days - 1) because we want to include the current day.
export const DATE_FILTER_OPTIONS = [
  { days: 6, value: DateFilterOptionValue.Last7Days, text: i18next.t('dateRanges.last7Days') },
  { days: 14, value: DateFilterOptionValue.Last15Days, text: i18next.t('dateRanges.last15Days') },
  { days: 29, value: DateFilterOptionValue.Last30Days, text: i18next.t('dateRanges.last30Days') },
  { days: 44, value: DateFilterOptionValue.Last45Days, text: i18next.t('dateRanges.last45Days') },
  { days: 89, value: DateFilterOptionValue.Last90Days, text: i18next.t('dateRanges.last90Days') },
  { value: DateFilterOptionValue.CustomDate, text: i18next.t('dateRanges.customDate') },
] as DateFilterOption[];

export const DATE_RANGE_FILTER_DEFAULT_VALUE = DATE_FILTER_OPTIONS[2];
const DATE_RANGE_FILTER_FORMAT = 'YYYY-MM-D';
const KPIS_LOCALSTORAGE_VERSION = '2';
const SEGMENTS_KPIS_LOCALSTORAGE_VERSION = '2';

// Only 6 months of data is available
export const getMaximumDateRangeFilter = (): DateRangeValue => {
  return {
    from: moment().subtract(6, 'months').startOf('day').format(DATE_RANGE_FILTER_FORMAT).toString(),
    to: moment().endOf('day').format(DATE_RANGE_FILTER_FORMAT).toString(),
  };
};

export const getValueInDateRange = (filterOption: DateFilterOption): DateRangeValue => {
  if (filterOption.value === DateFilterOptionValue.CustomDate) {
    return {
      from: moment(filterOption.range.start).format(DATE_RANGE_FILTER_FORMAT).toString(),
      to: moment(filterOption.range.end).format(DATE_RANGE_FILTER_FORMAT).toString(),
    };
  }

  const amountOfDays = filterOption.days;
  return {
    from: moment().subtract(amountOfDays, 'days').startOf('day').format(DATE_RANGE_FILTER_FORMAT).toString(),
    to: moment().endOf('day').format(DATE_RANGE_FILTER_FORMAT).toString(),
  };
};

export const getAmountOfDaysInDateRange = (filterOption: DateFilterOption) => {
  if (filterOption.value === DateFilterOptionValue.CustomDate) {
    return moment(filterOption.range.end).diff(moment(filterOption.range.start), 'days');
  }

  return filterOption.days;
};

const getMakeFilters = (listingFilters: Record<string, any>) =>
  listingFilters?.[FilterField.Make]?.makeModelFilter?.combinations.map((combination) => combination.make);

export const KPIsAndFilterProvider: FC<KPIsAndFilterProviderProps> = ({ children }) => {
  const { me, selectedCountry } = useSessionContext();
  const { t } = useTranslation();

  const [geoFilters, setGeoFilters] = useState<GeoFilter[]>([]);
  const [geoFiltersToLoad, setGeoFiltersToLoad] = useState<GeoFilter[]>([]);

  const [selectedTypeGeoFilterCreation, setSelectedTypeGeoFilterCreation] = useState<GeoFilterType>();
  const [isCreatingGeoFilter, setIsCreatingGeoFilter] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>({});
  const [dateRangeFilter, setDateRangeFilter] = useState<DateFilterOption>(DATE_RANGE_FILTER_DEFAULT_VALUE);
  const [initialSelectedFilters, setInitialSelectedFilters] = useState<Record<string, any>>({});
  const [initialGeoFilters, setInitialGeoFilters] = useState<GeoFilter[]>([]);
  const [initialDateRangeFilter, setInitialDateRangeFilter] = useState<DateFilterOption>(
    DATE_RANGE_FILTER_DEFAULT_VALUE,
  );
  const [isInitialized, setInitialized] = useState(false);
  const [loadingKPIMap, setLoadingKPIMap] = useState(false);
  const [wasSegmentRenamed, setWasSegmentRenamed] = useState(false);
  const [hasFinishedInitializing, setHasFinishedInitializing] = useState(false);
  const [segmentId, setSegmentId] = useState<string>(null);
  const [initializingSegment, setInitializingSegment] = useState(true);
  const AMOUNT_OF_ITEMS_PER_PAGE = 20;

  const { loadFilters, loading: loadingFilters, filters } = useListingFilters();

  const KPIsLocalStorageKey = `${localStorageKeysPrefix}-kpis-V${KPIS_LOCALSTORAGE_VERSION}`;
  const [KPIs, setKPIs] = usePersistState([], KPIsLocalStorageKey);

  const SegmentsKPIsLocalStorageKey = `${localStorageKeysPrefix}-segment-kpis-V${SEGMENTS_KPIS_LOCALSTORAGE_VERSION}`;
  const [segmentsKPIs, setSegmentsKPIs] = usePersistState(null, SegmentsKPIsLocalStorageKey);

  const [segmentsDateRangeFilter, setSegmentsDateRangeFilter] = usePersistState(
    DATE_RANGE_FILTER_DEFAULT_VALUE,
    blackBookSegmentsDateRange,
  );
  const [currentSegment, setCurrentSegment] = useState<SegmentQuery['segment']>();

  // Boolean state used to toggle the fetch of listings in the VehiclesSnapshot query.
  const [showListings, setShowListings] = useState(false);
  const [listingsSortOrder, setListingsSortOrder] = useState<SortOrder>();
  const [listingsSearchQuery, setListingsSearchQuery] = useState('');
  const debouncedListingsSearchQuery = useDebounce(listingsSearchQuery.trim(), 500) as string;
  const [loadingListingsWhenSwitchChanges, setLoadingListingsWhenSwitchChanges] = useState(false);

  const hasUnsavedChanges = useMemo(
    () =>
      !_.isEqual(initialSelectedFilters, selectedFilters) ||
      !_.isEqual(initialGeoFilters, geoFiltersToLoad) ||
      !_.isEqual(initialDateRangeFilter, dateRangeFilter),
    [
      initialSelectedFilters,
      selectedFilters,
      initialGeoFilters,
      geoFiltersToLoad,
      initialDateRangeFilter,
      dateRangeFilter,
    ],
  );

  useEffect(() => {
    if (!currentSegment && segmentId === '') {
      void loadFilters();
      setInitializingSegment(false);
    } else if (currentSegment && segmentId) {
      if (currentSegment?.filters && !wasSegmentRenamed) {
        let currentSegmentFilters: ListingFilter[] = [];
        //if segment was renamed, we need to keep the current unsaved filters because the segment was not saved yet

        // Set Property Filters
        const json = JSON.stringify(currentSegment.filters, omitTypename);
        currentSegmentFilters = JSON.parse(json);
        const propertyFiltersConverted: Record<string, PropertyFilterWithoutGeo> = {};
        currentSegmentFilters
          .filter((filter) => !filter.geoFilter)
          .forEach((filter) => {
            if (filter.makeModelFilter) {
              propertyFiltersConverted[FilterField.Make] = filter;
            } else {
              const filterEntry = Object.values(filter) as PropertyFilterWithoutGeoAndMakeModel;
              propertyFiltersConverted[filterEntry[0].field] = filter;
            }
          });
        setInitialSelectedFilters(propertyFiltersConverted);
        setSelectedFilters(propertyFiltersConverted);

        // Set Geo Filters
        if (!JSON.parse(localStorage.getItem(geoFiltersLocalStorage))?.length) {
          const geoFiltersFromSegment = currentSegmentFilters
            .filter((filter) => filter.geoFilter)
            .map((filter) =>
              createParentGeoFilterHelperFromGraphQL(filter.geoFilter, null, selectedCountry === ListingsCountry.Ca),
            );
          setGeoFiltersToLoad(geoFiltersFromSegment);
          setInitialGeoFilters(geoFiltersFromSegment);
        }
      }
      if (currentSegment?.dateRange) {
        // Set Date Range Filter
        const dateRangeFrom = createUTCDate(currentSegment.dateRange?.from as string);
        const dateRangeTo = createUTCDate(currentSegment.dateRange?.to as string);

        const currentSegmentDateRange: DateFilterOption = {
          value: DateFilterOptionValue.CustomDate,
          text: i18next.t('dateRanges.customDate'),
          range: new DateRange(dateRangeFrom, dateRangeTo),
        };
        setDateRangeFilter(currentSegmentDateRange);
        setInitialDateRangeFilter(currentSegmentDateRange);
      } else {
        // If no Date Range is provided, set default value.
        setDateRangeFilter(DATE_RANGE_FILTER_DEFAULT_VALUE);
        setInitialDateRangeFilter(DATE_RANGE_FILTER_DEFAULT_VALUE);
      }
      setInitializingSegment(false);
    }
  }, [currentSegment, segmentId]);

  const getFilterChips = (): Array<FilterChip> => {
    if (!filters) return [];
    const propertyFiltersChips = [];

    // Make and Model Filters
    const makeFilter = filters?.find((filter) => filter.filterField === FilterField.Make) as Filter;
    const makeFilterHelper = FilterHelper.createInstance(makeFilter);

    const makes = getMakeFilters(selectedFilters);
    makes?.forEach((make: string) => {
      const combination = selectedFilters[FilterField.Make]?.makeModelFilter?.combinations.find(
        (combination) => combination.make === make,
      );

      const makeAnswer = makeFilterHelper
        ?.getAnswers(selectedFilters[FilterField.Make] as ListingFilter)
        ?.items?.find((item) => item?.title === make)?.title;

      const makeChip = {
        title: makeFilter?.title,
        value: makeAnswer,
        removeFilter: () => {
          const removedAnswer = makeFilterHelper.removeAnswer(selectedFilters[FilterField.Make] as ListingFilter, {
            title: make,
          });
          applyFilter(FilterField.Make, removedAnswer);
        },
      } as FilterChip;
      propertyFiltersChips.push(makeChip);

      const models = combination?.model;
      if (models) {
        const modelFilter = filters?.find(
          (filter) =>
            filter.filterField === FilterField.Model && filter.configuration?.modelFilterConfiguration?.make === make,
        ) as Filter;
        const modelFilterHelper = FilterHelper.createInstance(modelFilter);
        const modelAnswers = modelFilterHelper?.getAnswers(selectedFilters[FilterField.Make] as ListingFilter, make);
        modelAnswers?.items?.forEach((modelAnswer) => {
          const modelChip = {
            title: modelFilter?.title,
            value: modelAnswer?.title,
            removeFilter: () => {
              const removedAnswer = modelFilterHelper.removeAnswer(
                selectedFilters[FilterField.Make] as ListingFilter,
                modelAnswer,
                make,
              );
              applyModelFilter(removedAnswer, make, true);
            },
          } as FilterChip;
          propertyFiltersChips.push(modelChip);
        });
      }
    });

    // Other Filters
    const propertyFiltersKeys = Object.keys(selectedFilters);
    propertyFiltersKeys.forEach((propFilter) => {
      if (propFilter !== FilterField.Make) {
        const filter = filters?.find((f) => f.filterField === propFilter) as Filter;
        if (filter) {
          const filterHelper = FilterHelper.createInstance(filter);
          const shouldFormatNumber =
            filter.filterField === FilterField.Price || filter.filterField === FilterField.Mileage;
          const answers = filterHelper?.getAnswers(
            selectedFilters[propFilter] as ListingFilter,
            undefined,
            shouldFormatNumber,
          );

          answers?.items?.forEach((ans) => {
            propertyFiltersChips.push({
              title: filter?.title,
              value: ans?.title,
              removeFilter: () => {
                const removedAnswer = filterHelper.removeAnswer(selectedFilters[propFilter] as ListingFilter, ans);
                applyFilter(filter.filterField, removedAnswer);
              },
            } as FilterChip);
          });
        }
      }
    });

    const geoFiltersChips = geoFilters?.map(
      (geoFilter, index) =>
        ({
          title: t('newSegment.geoFilter'),
          value: `${getGeofilterTypeLabel(geoFilter)} ${geoFilter.type === GeoFilterType.Region ? '' : `${index + 1}`}`,
          removeFilter: () => removeGeoFilter(geoFilter),
        } as FilterChip),
    );

    return [...propertyFiltersChips, ...geoFiltersChips];
  };

  const clearState = () => {
    setSelectedFilters({});
    setInitialSelectedFilters({});
    setGeoFilters([]);
    setGeoFiltersToLoad([]);
    setInitialGeoFilters([]);
    setCurrentSegment(null);
    setDateRangeFilter(DATE_RANGE_FILTER_DEFAULT_VALUE);
    setInitialDateRangeFilter(DATE_RANGE_FILTER_DEFAULT_VALUE);
    setWasSegmentRenamed(false);
    setHasFinishedInitializing(false);
    setSegmentId(null);
    setInitializingSegment(true);
    setListingsSearchQuery('');
  };

  // **********************
  // * KPIs Related *
  // **********************

  const getDefaultSelectedKPI = (): ValidKPIField =>
    KPIs.filter((k) => !k.hidden)?.find((KPI: KPIsData) => {
      const kpi = getEnumKeyByValue(ValidKPIField, KPI.field as string);
      const validKPI = ValidKPIField[kpi];
      return validKPI;
    })?.field;

  /**
   * Updates the KPIs list with the BE response.
   * @param KPIs - KPIs to be updated
   * @param setKPIs - setKPIs function from the KPIs to be updated
   * @param data - BE response
   */
  const updateKPILists = (KPIs: any, setKPIs: any, data: any) => {
    if (KPIs?.length > 0) {
      // KPIs that are present in LS
      let auxiliaryLocalKPIs = KPIs;

      // KPIs that are present in BE response
      const responseKPIs = data?.kpiList?.map((KPI, index) => ({
        name: KPI.title,
        field: KPI.filterField,
        position: index,
        hidden: false,
      }));

      // Remove KPIs that are not in the BE response anymore (if findIndex, means its present in BE response)
      auxiliaryLocalKPIs = auxiliaryLocalKPIs.filter(
        (kpi) => responseKPIs.findIndex((KPI) => KPI.name === kpi.name && KPI.field === kpi.field) >= 0,
      );

      // For the active KPIs present, update the position to be consecutive (to avoid holes after removing KPIs)
      let activeKPIs = auxiliaryLocalKPIs.filter((kpi) => !kpi.hidden);
      activeKPIs = activeKPIs
        .sort((a, b) => a.position - b.position)
        .map((item, index) => ({ ...item, position: index }));

      const isDeleted = auxiliaryLocalKPIs.length !== KPIs.length;

      // The KPIs that are present in BE response, but not in LS
      const KPIsToAdd = responseKPIs.filter(
        (kpi) => auxiliaryLocalKPIs.findIndex((KPI) => KPI.name === kpi.name && KPI.field === kpi.field) < 0,
      );
      KPIsToAdd.forEach((KPI, index: number) => {
        // insert the column with order = index
        activeKPIs.push({ ...KPI, position: (activeKPIs?.length as number) + index, hidden: false } as KPIsData);
      });

      if (isDeleted || KPIsToAdd.length > 0) {
        setKPIs([...activeKPIs, ...auxiliaryLocalKPIs.filter((kpi) => kpi.hidden)]);
      }
    } else {
      const KPIsToLoad = data?.kpiList?.map((KPI, index) => ({
        name: KPI.title,
        field: KPI.filterField,
        position: index,
        hidden: false,
      }));
      setKPIs(KPIsToLoad as KPIsData[]);
    }
  };

  const { loading: loadingKPIs } = useKpiListQuery({
    skip: !me?.id,
    onCompleted(data) {
      // Update KPIs and segmentsKPIs, which are independent from each other
      updateKPILists(KPIs, setKPIs, data);
      updateKPILists(segmentsKPIs, setSegmentsKPIs, data);
    },
  });

  const hiddenKPIs = useMemo(() => KPIs.filter((KPI) => KPI.hidden), [KPIs]) || [];
  const selectedKPIs: KPIsData[] =
    useMemo(() => KPIs.filter((KPI) => !KPI.hidden), [KPIs]).sort((a, b) => a.position - b.position) || [];
  const selectedSegmentsKPIs: KPIsData[] = useMemo(() => {
    if (!segmentsKPIs) return null;
    return segmentsKPIs?.filter((KPI) => !KPI.hidden)?.sort((a, b) => a.position - b.position);
  }, [segmentsKPIs]);

  const updateKPIs = (KPIs: KPIsData[]) => {
    setKPIs(KPIs);
  };

  const hideKPI = (KPI: KPIsData) => {
    if (KPI.field === selectedKpiField) {
      setSelectedKpiField(null);
    }

    const newKPIs = KPIs.map((kpi) => {
      if (kpi.name === KPI.name) {
        return {
          ...kpi,
          position: null,
          hidden: true,
        };
      }
      if (kpi.position > KPI.position) {
        return {
          ...kpi,
          position: kpi.position - 1,
        };
      }
      return kpi;
    });
    setKPIs(newKPIs);
  };

  const showKPI = (KPI: KPIsData) => {
    const newKPIs = KPIs.map((kpi) => {
      if (kpi.name === KPI.name) {
        return {
          ...kpi,
          hidden: false,
          position: selectedKPIs.length,
        };
      }
      return kpi;
    });
    setKPIs(newKPIs);
  };

  const updateSegmentsKPIs = (KPIs: KPIsData[]) => {
    setSegmentsKPIs(KPIs);
  };

  const hideSegmentsKPI = (KPI: KPIsData) => {
    const newKPIs = segmentsKPIs?.map((kpi) => {
      if (kpi.name === KPI.name) {
        return {
          ...kpi,
          hidden: true,
        };
      }
      return kpi;
    });
    setSegmentsKPIs(newKPIs);
  };

  const showSegmentsKPI = (KPI: KPIsData) => {
    const newKPIs = segmentsKPIs?.map((kpi) => {
      if (kpi.name === KPI.name) {
        return {
          ...kpi,
          hidden: false,
        };
      }
      return kpi;
    });
    setSegmentsKPIs(newKPIs);
  };

  const hideAllSegmentsKPIs = () => {
    const newKPIs = segmentsKPIs?.map((kpi) => ({
      ...kpi,
      hidden: true,
    }));
    setSegmentsKPIs(newKPIs);
  };

  const showAllSegmentsKPIs = () => {
    const newKPIs = segmentsKPIs?.map((kpi) => ({
      ...kpi,
      hidden: false,
    }));
    setSegmentsKPIs(newKPIs);
  };

  const [selectedKpiField, setSelectedKpiField] = useState<ValidKPIField>(getDefaultSelectedKPI() ?? null);

  // This useEffect guarantees that the selected KPI is always a valid and visible one.
  useEffect(() => {
    if (!selectedKpiField) {
      setSelectedKpiField(getDefaultSelectedKPI() ?? null);
    }
  }, [KPIs]);

  const { audienceId: parentAudienceId } = useParams();

  // **********************
  // * GeoFilters Related *
  // **********************
  const propertyFiltersLocalStorage = blackBookPropertyFilters;

  const geoFiltersLocalStorage = blackBookGeoFilters;

  useEffect(() => {
    localStorage.setItem(blackBookInitialFilters, JSON.stringify(initialSelectedFilters));
    const savedGeoFilters: GeoFilter[] = JSON.parse(localStorage.getItem(geoFiltersLocalStorage)) || [];
    if (savedGeoFilters.length) {
      setGeoFiltersToLoad(savedGeoFilters);
      setGeoFilters(savedGeoFilters);
      setInitialGeoFilters(savedGeoFilters);
    }

    const savedListingFilters: Record<string, any> = JSON.parse(localStorage.getItem(propertyFiltersLocalStorage));
    if (savedListingFilters) {
      const savedInitialFilters: Record<string, any> = JSON.parse(localStorage.getItem(blackBookInitialFilters));
      setInitialSelectedFilters(savedInitialFilters);
      setSelectedFilters(savedListingFilters);
    }

    // If any make filters were selected, send them as parameter to loadFilters query to get the models filter for each make.
    const makeFilters = getMakeFilters(savedListingFilters);
    void loadFilters({
      variables: {
        makeFilters,
      },
    });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const previouslySavedPropertyFilters: Record<string, any> = JSON.parse(
      localStorage.getItem(propertyFiltersLocalStorage),
    );

    // Update saved filters in local storage
    localStorage.setItem(propertyFiltersLocalStorage, JSON.stringify(selectedFilters));
    localStorage.setItem(geoFiltersLocalStorage, JSON.stringify(geoFilters));

    // If selected make filters were modified, we need to refetch filters to get makeModel filters.
    const oldMakeFilters = getMakeFilters(previouslySavedPropertyFilters);
    const newMakeFilters = getMakeFilters(selectedFilters);
    const areEqual = _.isEqual(oldMakeFilters, newMakeFilters);

    if (!areEqual) {
      void loadFilters({
        variables: {
          makeFilters: newMakeFilters,
        },
      });
    }
  }, [selectedFilters, geoFilters]);

  const applyFilter = (filterField: FilterField, filter: Nullable<any>) => {
    setLoadingKPIMap(true);
    const selectedFiltersTemp = _.cloneDeep(selectedFilters);
    if (filter) {
      selectedFiltersTemp[filterField] = filter;
    } else {
      delete selectedFiltersTemp[filterField];
    }
    setSelectedFilters(selectedFiltersTemp);
  };

  const applyMakeFilter = (filter: Nullable<any>) => {
    setLoadingKPIMap(true);
    const selectedFiltersTemp = _.cloneDeep(selectedFilters);
    if (filter?.makeModelFilter) {
      selectedFiltersTemp[FilterField.Make] = filter;
    } else if (filter) {
      const makeModelFilter = selectedFiltersTemp[FilterField.Make];
      const makeModelFilterCombinations: Array<any> = makeModelFilter?.makeModelFilter?.combinations || [];

      const existingMakes = new Set(makeModelFilterCombinations.map((combination) => combination.make));
      const addedMakeModelFilterCombinations = filter?.stringFilter?.operator?.in
        .filter((make) => !existingMakes.has(make))
        .map((make) => ({
          make,
          model: [],
        }));
      const removedMakeModelFilterCombinations = makeModelFilterCombinations.filter(
        (combination) => !filter?.stringFilter?.operator?.in.includes(combination.make),
      );

      // We remove the make filters that were deselected, and we add the ones that were added.
      const updatedCombinations = [
        ...makeModelFilterCombinations.filter((mm) => !removedMakeModelFilterCombinations.includes(mm)),
        ...addedMakeModelFilterCombinations,
      ];

      selectedFiltersTemp[FilterField.Make] = {
        ...makeModelFilter,
        makeModelFilter: {
          ...makeModelFilter?.makeModelFilter,
          combinations: updatedCombinations,
        },
      };
    } else {
      // If !filter, then the last selected make filter was removed.
      delete selectedFiltersTemp[FilterField.Make];
    }
    setSelectedFilters(selectedFiltersTemp);
  };

  const applyModelFilter = (filter: Nullable<any>, filterMake: string, isRemovingAnswer?: boolean) => {
    const selectedFiltersTemp = _.cloneDeep(selectedFilters);
    setLoadingKPIMap(true);
    if (filter) {
      // We need to check if there is already a Model filter selected.
      // If there is, we need to adjust it internally instead of overwriting the entire object.
      if (!isRemovingAnswer && selectedFiltersTemp[FilterField.Make]) {
        const existentMakeModelFilterIndex = selectedFiltersTemp[
          FilterField.Make
        ].makeModelFilter?.combinations?.findIndex((combination) => combination.make === filterMake);

        // Check if there is at least one model filter selected for the same make.
        if (existentMakeModelFilterIndex > -1) {
          // Replace the selected models on the existent make with the new ones.
          const newMakeModelFilter = filter?.makeModelFilter?.combinations?.find(
            (combination) => combination.make === filterMake,
          );
          selectedFiltersTemp[FilterField.Make].makeModelFilter.combinations[existentMakeModelFilterIndex] =
            newMakeModelFilter;
        } else {
          // Add the entire object.
          const newMakeModelFilter = filter?.makeModelFilter?.combinations?.find(
            (combination) => combination.make === filterMake,
          );
          selectedFiltersTemp[FilterField.Make].makeModelFilter.combinations.push(newMakeModelFilter);
        }
      } else {
        selectedFiltersTemp[FilterField.Make] = filter;
      }
    }

    setSelectedFilters(selectedFiltersTemp);
  };

  const addGeoFilter = (geoFilter: GeoFilter) => {
    setLoadingKPIMap(true);
    setGeoFiltersToLoad(geoFilters.concat(geoFilter));
  };

  const removeGeoFilter = (geoFilter: GeoFilter) => {
    setLoadingKPIMap(true);
    setGeoFiltersToLoad(geoFilters.filter((filter) => geoFilter.id !== filter.id));
  };

  const updateGeoFilter = (filterId: number, geoFilter: GeoFilter, hitEndpoint = true) => {
    setLoadingKPIMap(true);
    const index = geoFilters.findIndex((filter) => filter.id === filterId);
    if (index < 0) return;

    const geoFiltersTemp = [...geoFilters];
    geoFiltersTemp[index] = geoFilter;
    setGeoFiltersToLoad(geoFiltersTemp);

    if (!hitEndpoint) {
      setGeoFilters(geoFiltersTemp);
    }
  };

  const hasSelectedFilters = useMemo(() => {
    return Object.keys(selectedFilters).length > 0;
  }, [selectedFilters]);

  const amountOfFilters = useMemo(() => {
    let selectedModelFilters = 0;
    selectedModelFilters += selectedFilters[FilterField.Make]?.makeModelFilter?.combinations.filter(
      (combination) => combination.model.length > 0,
    ).length;
    return Object.keys(selectedFilters).length + selectedModelFilters + geoFiltersToLoad.length;
  }, [selectedFilters, geoFiltersToLoad]);

  const filtersQueryParam = useCallback(
    (skipExcludedZips?: boolean) => {
      return Object.values(selectedFilters).concat(
        geoFiltersToLoad.map((geoFilter) =>
          createGeoFilterHelper(geoFilter).toGraphQLGeoFilter(skipExcludedZips, selectedCountry === ListingsCountry.Ca),
        ),
      );
    },
    [selectedFilters, geoFiltersToLoad],
  );

  const listingsSortOrderParam = useMemo(() => {
    if (!listingsSortOrder) return null;
    return Object.entries(listingsSortOrder).map(([columnId, order]) => `${columnId}:${order}`);
  }, [listingsSortOrder]);

  // This loads vehicles and decides if it should load listings or not. From here we can get the total count of vehicles.
  const [loadListings, { data, loading: loadingListings, fetchMore, networkStatus }] = useVehiclesSnapshotLazyQuery();

  // This is used to get the total count of listings even when the switch is off.
  const [loadListingsCount, { data: dataCountListings }] = useListingsSnapshotLazyQuery();

  useEffect(() => {
    if (hasFinishedInitializing && selectedCountry) {
      void loadListingsCount({
        variables: {
          filters: filtersQueryParam(),
          dateRange: getValueInDateRange(dateRangeFilter),
          country: selectedCountry,
          searchQuery: debouncedListingsSearchQuery,
          ...(listingsSortOrder && { sort: listingsSortOrderParam }),
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      });
      void loadListings({
        variables: {
          first: AMOUNT_OF_ITEMS_PER_PAGE,
          filters: filtersQueryParam(),
          searchQuery: debouncedListingsSearchQuery,
          dateRange: getValueInDateRange(dateRangeFilter),
          country: selectedCountry,
          getListings: showListings,
          ...(listingsSortOrder && { sort: listingsSortOrderParam }),
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      });

      if (loadingListingsWhenSwitchChanges) setLoadingListingsWhenSwitchChanges(false);
    }
  }, [
    hasFinishedInitializing,
    filtersQueryParam,
    dateRangeFilter,
    selectedCountry,
    showListings,
    debouncedListingsSearchQuery,
    listingsSortOrderParam,
  ]);

  const loadMoreListings = async () => {
    if (!loadingListings) {
      await fetchMore({
        variables: {
          first: AMOUNT_OF_ITEMS_PER_PAGE,
          after: data?.vehiclesSnapshot?.pageInfo?.endCursor,
          filters: filtersQueryParam(),
          dateRange: getValueInDateRange(dateRangeFilter),
          country: selectedCountry,
          getListings: showListings,
          ...(listingsSortOrder && { sort: listingsSortOrderParam }),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            vehiclesSnapshot: {
              nodes: [...(prev.vehiclesSnapshot?.nodes || []), ...(fetchMoreResult.vehiclesSnapshot?.nodes || [])],
              pageInfo: fetchMoreResult.vehiclesSnapshot?.pageInfo,
              totalCount: fetchMoreResult.vehiclesSnapshot?.totalCount,
            },
          };
        },
      });
    }
  };

  const defineStatsFromGeoMetricsResponse = (value) => {
    return (
      value?.kpiGeoMetrics ||
      value?.listingVolumeGeoMetric ||
      value?.listingDaysOnMarketGeoMetric ||
      value?.fieldValueGeoMetric ||
      value?.listingMarketDaysSupplyGeoMetric
    );
  };

  const variablesForGeoMetrics = useMemo(
    () => ({
      country: selectedCountry,
      filters: filtersQueryParam(),
      dateRange: getValueInDateRange(dateRangeFilter),
      zoomLevel: geoFiltersToLoad?.length > 0 ? ZoomLevelInput.Zipcode : ZoomLevelInput.State,
      kpiLevel: KPIFieldType[selectedKpiField],
    }),
    [filtersQueryParam, dateRangeFilter, geoFiltersToLoad, selectedKpiField, selectedCountry],
  );

  const processGeoMetricsData = (data: KpiGeoMetrics) => {
    const correspondingData = defineStatsFromGeoMetricsResponse(data);
    const countByPostalCode = correspondingData?.geo.reduce((object, { key, avg }) => {
      object[key] = avg.value;
      return object;
    }, {}) as object;
    const vinsByPostalCode = correspondingData?.geo.reduce((object, { key, count }) => {
      object[key] = count;
      return object;
    }, {}) as object;
    const totalsByPostalCode = correspondingData?.geo.reduce((object, { key, avg }) => {
      object[key] = avg.value;
      return object;
    }, {}) as object;
    const geoResult = correspondingData?.geoResults;
    const resultGeoFilters = geoFiltersToLoad.map((geoFilter, i) =>
      createGeoFilterHelper(geoFilter).geoFilterFromGeoResult(
        geoResult?.[i],
        countByPostalCode,
        vinsByPostalCode,
        totalsByPostalCode,
      ),
    );
    setGeoFilters(resultGeoFilters);
  };

  const processGeoMetricsDataForFieldValue = (data: KpiGeoMetrics) => {
    const correspondingData = defineStatsFromGeoMetricsResponse(data);
    const countByPostalCode = correspondingData?.geo.reduce((object, { key, count }) => {
      object[key] = count;
      return object;
    }, {}) as object;
    const vinsByPostalCode = correspondingData?.geo.reduce((object, { key, count }) => {
      object[key] = count;
      return object;
    }, {}) as object;
    const totalsByPostalCode = correspondingData?.geo.reduce((object, { key, valueCount }) => {
      object[key] = valueCount;
      return object;
    }, {}) as object;
    const geoResult = correspondingData?.geoResults;
    const resultGeoFilters = geoFiltersToLoad.map((geoFilter, i) =>
      createGeoFilterHelper(geoFilter).geoFilterFromGeoResult(
        geoResult?.[i],
        countByPostalCode,
        vinsByPostalCode,
        totalsByPostalCode,
      ),
    );
    setGeoFilters(resultGeoFilters);
  };

  const processGeoMetricsSingleValueDataForFieldValue = (data: KpiGeoMetrics) => {
    const correspondingData = defineStatsFromGeoMetricsResponse(data);
    const valueByPostalCode = correspondingData?.geo.reduce((object, { key, value }) => {
      object[key] = value;
      return object;
    }, {}) as object;
    const geoResult = correspondingData?.geoResults;
    const resultGeoFilters = geoFiltersToLoad.map((geoFilter, i) =>
      createGeoFilterHelper(geoFilter).geoFilterFromSingleValueGeoResult(geoResult?.[i], valueByPostalCode),
    );
    setGeoFilters(resultGeoFilters);
  };

  const getKpiField = (kpi: ValidKPIField): FilterNumberField => {
    switch (kpi) {
      case ValidKPIField.Mileage:
      case ValidKPIField.VehicleMileage:
        return FilterNumberField.Mileage;
      case ValidKPIField.Price:
      case ValidKPIField.VehiclePrice:
        return FilterNumberField.Price;
      default:
        return null;
    }
  };

  const { data: geoMetricsData, loading: loadingKpiGeoMetric } = useKpiGeoMetricsQuery({
    skip:
      !selectedKpiField ||
      !(
        selectedKpiField === ValidKPIField.Mileage ||
        selectedKpiField === ValidKPIField.Price ||
        selectedKpiField === ValidKPIField.VehicleMileage ||
        selectedKpiField === ValidKPIField.VehiclePrice
      ) ||
      !selectedCountry,
    variables: {
      ...variablesForGeoMetrics,
      kpi: getKpiField(selectedKpiField),
    },
    onCompleted(data) {
      processGeoMetricsData(data);
    },
  });

  const { data: geoMetricsDataListingVolume, loading: loadingListingVolumeGeoMetric } = useListingVolumeGeoMetricQuery({
    skip:
      !selectedKpiField ||
      !(
        selectedKpiField === ValidKPIField.ListingVolumeMetric || selectedKpiField === ValidKPIField.VehicleVolumeMetric
      ) ||
      !selectedCountry,
    variables: variablesForGeoMetrics,
    onCompleted(data) {
      processGeoMetricsData(data);
    },
  });

  const { data: geoMetricsDataListingDaysOnMarket, loading: loadingListingsDaysOnMarketGeoMetric } =
    useListingsDaysOnMarketGeoMetricQuery({
      skip:
        !selectedCountry ||
        !selectedKpiField ||
        !(selectedKpiField === ValidKPIField.DaysOnMarket || selectedKpiField === ValidKPIField.VehicleDaysOnMarket),
      variables: variablesForGeoMetrics,
      onCompleted(data) {
        processGeoMetricsData(data);
      },
    });

  const getValueField = (kpi: ValidKPIField): ValueField => {
    switch (kpi) {
      case ValidKPIField.ListingByCPO:
      case ValidKPIField.VehicleByCPO:
        return ValueField.Certified;
      case ValidKPIField.ListingByFranchise:
      case ValidKPIField.VehicleByFranchise:
        return ValueField.DealerType;
      default:
        return null;
    }
  };

  const { data: fieldValueGeoMetricData, loading: loadingFieldValueGeoMetric } = useFieldValueGeoMetricQuery({
    skip:
      !selectedCountry ||
      !selectedKpiField ||
      !(
        selectedKpiField === ValidKPIField.ListingByCPO ||
        selectedKpiField === ValidKPIField.ListingByFranchise ||
        selectedKpiField === ValidKPIField.VehicleByCPO ||
        selectedKpiField === ValidKPIField.VehicleByFranchise
      ),
    variables: {
      ...variablesForGeoMetrics,
      value:
        selectedKpiField === ValidKPIField.ListingByCPO || selectedKpiField === ValidKPIField.VehicleByCPO
          ? 'true'
          : 'Franchise',
      field: getValueField(selectedKpiField),
    },
    onCompleted(data) {
      processGeoMetricsDataForFieldValue(data);
    },
  });

  const { data: listingMarketDaysSupplyMetricData, loading: loadingListingMarketDaysSupplyMetric } =
    useListingMarketDaysSupplyGeoMetricQuery({
      skip:
        !selectedCountry ||
        !selectedKpiField ||
        !(
          selectedKpiField === ValidKPIField.MarketDaysSupply ||
          selectedKpiField === ValidKPIField.VehicleMarketDaysSupply
        ),
      variables: {
        ...variablesForGeoMetrics,
      },
      onCompleted(data) {
        processGeoMetricsSingleValueDataForFieldValue(data);
      },
    });

  const geoData =
    geoMetricsData ||
    geoMetricsDataListingVolume ||
    geoMetricsDataListingDaysOnMarket ||
    fieldValueGeoMetricData ||
    listingMarketDaysSupplyMetricData;
  const loadingGeoMetrics =
    loadingKpiGeoMetric ||
    loadingListingVolumeGeoMetric ||
    loadingListingsDaysOnMarketGeoMetric ||
    loadingFieldValueGeoMetric ||
    loadingListingMarketDaysSupplyMetric;

  const clearFiltersLocalStorage = () => {
    [blackBookGeoFilters, blackBookPropertyFilters, blackBookInitialFilters].forEach((key) =>
      localStorage.removeItem(key),
    );
  };

  useEffect(() => {
    if (!isInitialized) return;
    if (!selectedCountry) return;
    setHasFinishedInitializing(
      !loadingFilters && !loadingGeoMetrics && segmentId !== null && !initializingSegment && selectedCountry !== null,
    );
  }, [isInitialized, loadingFilters, loadingGeoMetrics, segmentId, initializingSegment, selectedCountry]);

  return (
    <KPIsAndFilterContext.Provider
      value={{
        filters,
        loadingFilters,
        selectedFilters,
        hasSelectedFilters,
        setSelectedFilters,
        addGeoFilter,
        updateGeoFilter,
        removeGeoFilter,
        geoFilters,
        setGeoFilters,
        geoFiltersToLoad,
        applyFilter,
        applyModelFilter,
        filtersQueryParam,
        parentAudienceId,
        dateRangeFilter,
        setDateRangeFilter,
        segmentsDateRangeFilter,
        setSegmentsDateRangeFilter,
        getValueInDateRange,
        loadingListings,
        isCreatingGeoFilter,
        setIsCreatingGeoFilter,
        selectedTypeGeoFilterCreation,
        setSelectedTypeGeoFilterCreation,
        listings: data?.vehiclesSnapshot?.nodes,
        listingsTotalCount: dataCountListings?.listingsSnapshot?.totalCount ?? -1,
        vehiclesTotalCount: data?.vehiclesSnapshot?.totalCount ?? -1,
        kpiGeoMetrics: geoData,
        loadingKPIMap,
        selectedKpiField,
        setSelectedKpiField,
        segmentsKPIs,
        setSegmentsKPIs,
        selectedSegmentsKPIs,
        updateSegmentsKPIs,
        hideSegmentsKPI,
        showSegmentsKPI,
        hideAllSegmentsKPIs,
        showAllSegmentsKPIs,
        KPIs,
        updateKPIs,
        selectedKPIs,
        hiddenKPIs,
        hideKPI,
        showKPI,
        loadingKPIs,
        hasUnsavedChanges,
        currentSegment,
        setCurrentSegment,
        clearState,
        amountOfFilters,
        getFilterChips,
        loadMoreListings,
        hasMoreListings: data?.vehiclesSnapshot?.pageInfo?.hasNextPage,
        clearFiltersLocalStorage,
        defineStatsFromGeoMetricsResponse,
        setLoadingKPIMap,
        applyMakeFilter,
        setWasSegmentRenamed,
        loadingGeoMetrics,
        isInitialized: hasFinishedInitializing,
        setSegmentId,
        listingsNetworkStatus: networkStatus,
        showListings,
        setShowListings,
        loadingListingsWhenSwitchChanges,
        setLoadingListingsWhenSwitchChanges,
        listingsSearchQuery,
        setListingsSearchQuery,
        listingsSortOrder,
        setListingsSortOrder,
      }}
    >
      {children}
    </KPIsAndFilterContext.Provider>
  );
};

export const useKPIsAndFilterContext: () => KPIsAndFilterContextProps = () => {
  return React.useContext(KPIsAndFilterContext);
};
