import styled from 'styled-components';

import { QuestionMarkIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: ThemeProp) => props.theme.colors.primary.c100};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: 0 0.375rem;
`;

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  opacity: 1;
  fill: ${(props: ThemeProp) => props.theme.colors.primary.c400};
  width: 0.5rem;
  height: 0.5rem;
`;

export const TooltipContent = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.primary.c100};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
`;
