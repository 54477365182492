import React, { useContext, useLayoutEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import { rgbToHex } from '../../utils/colors';
import { RippleContainer } from './Ripple.styles';

const duration = 600;

const useDebouncedRippleCleanUp = (rippleCount, cleanUpFunction) => {
  useLayoutEffect(() => {
    let bounce = null;
    if (rippleCount > 0) {
      clearTimeout(bounce as number);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce as number);
      }, duration * 4);
    }

    return () => clearTimeout(bounce as number);
  }, [rippleCount, duration, cleanUpFunction]);
};

interface RippleProps {
  randomId?: string;
}

const Ripple = (props: RippleProps) => {
  const [rippleArray, setRippleArray] = useState([]);
  const themeContext = useContext(ThemeContext);

  useDebouncedRippleCleanUp(rippleArray.length, () => {
    setRippleArray([]);
  });

  const addRipple = (event) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size = rippleContainer.width > rippleContainer.height ? rippleContainer.width : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;

    // get the computed rgb color of the button
    const color = window.getComputedStyle(document.getElementById(`button-custom-${props.randomId}`)).backgroundColor;
    const [r, g, b] = color
      .replace(/[^\d,]/g, '')
      .split(',')
      .map(Number);

    // Transform it to hex
    const hexColor = rgbToHex(r, g, b)?.toUpperCase();
    let colorKey = null;
    let isPrimary = true;

    // check if color is primary or neutral
    const themePrimary = themeContext.colors.primary;
    const themePrimaryValues = Object.values(themePrimary as object);
    if (themePrimaryValues.includes(hexColor)) {
      colorKey = Object.keys(themePrimary as object).find((key) => themePrimary[key] === hexColor);
    }
    const themeNeutrals = themeContext.colors.neutrals;
    const themeNeutralsValues = Object.values(themeNeutrals as object);
    if (themeNeutralsValues.includes(hexColor)) {
      isPrimary = false;
      colorKey = Object.keys(themeNeutrals as object).find((key) => themeNeutrals[key] === hexColor);
    }

    const isWhite = colorKey === 'white';

    let rippleColor = null;
    if (isWhite) {
      rippleColor = themeContext.colors.neutrals.c200;
    } else {
      const numberPart = Number(colorKey?.replace('c', ''));
      rippleColor = isPrimary
        ? themeContext.colors.primary[`c${numberPart + 100}`]
        : themeContext.colors.neutrals[`c${numberPart + 100}`];
    }
    const newRipple = {
      x,
      y,
      size,
      color: rippleColor,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <RippleContainer duration={duration} onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              key={`span${index}`}
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size,
                backgroundColor: ripple.color,
              }}
            />
          );
        })}
    </RippleContainer>
  );
};

export default Ripple;
