/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ResponsiveBar } from '@nivo/bar';
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import { useSessionContext } from '../../../contexts/SessionContext';
import i18n from '../../../locale';
import { formatCount } from '../../../utils/helpers';
import { usePriceFormats } from '../../../utils/PriceFormats';
import ChartTooltip from '../ChartTooltip';
import { formatValue, getDataLimitForWidth, sanitizedData, shouldRenderTickValue } from '../utils';

export interface ChartComponentProps {
  containerWidth?: number;
  sortByCount?: boolean;
  horizontal?: boolean;
  isHistogram?: boolean;
  counts: Array<{ key: { from: number; to: number }; count: number }>;
  yAxesLabel?: string;
  isCurrency?: boolean;
  formatBucketKey?: (value: string) => string;
}

const ColumnChart = ({
  horizontal,
  containerWidth,
  sortByCount = false,
  isHistogram = false,
  counts,
  yAxesLabel,
  isCurrency = false,
  formatBucketKey = undefined,
}: ChartComponentProps) => {
  const theme = useContext(ThemeContext);
  const { selectedCountry } = useSessionContext();

  const { priceFormat } = usePriceFormats(selectedCountry);

  const dataCount = getDataLimitForWidth(containerWidth);
  const data = useMemo(
    () => sanitizedData(counts, sortByCount, false, !horizontal ? dataCount : undefined, isHistogram),
    [counts, sortByCount, dataCount, horizontal, isHistogram],
  );

  const tickValuesAxisBottom = useMemo(() => {
    if (!horizontal) return undefined;

    const maxValue = Math.max(...data.map((item) => item.count));
    if (maxValue < 1000) return undefined;

    const roundedMax = Math.round(maxValue / 1000) * 1000;
    const step = Math.round(roundedMax / dataCount);
    return [...Array(dataCount + 1)].map((_, index) => index * step);
  }, [data, horizontal, dataCount]);

  const formatPriceKey = (value: string) => {
    const [start, end] = value.split(' - '); // Split the string at the dash
    const formattedStart = i18n.format(parseFloat(start.replace(/,/g, '')), 'number', undefined, priceFormat);
    const formattedEnd = i18n.format(parseFloat(end.replace(/,/g, '')), 'number', undefined, priceFormat);

    return `${formattedStart} - ${formattedEnd}`;
  };

  const graphTheme = {
    background: theme.colors.neutrals.black,
    axis: {
      domain: {
        line: {
          stroke: theme.colors.neutrals.c600,
          strokeWidth: 0,
        },
      },
      legend: {
        text: {
          fontSize: 10,
          fill: theme.colors.neutrals.c500,
          outlineWidth: 0,
          outlineColor: 'transparent',
        },
      },
      ticks: {
        line: {
          stroke: theme.colors.neutrals.c600,
          strokeWidth: 1,
        },
        text: {
          fontSize: 10,
          fill: theme.colors.neutrals.c300,
          outlineWidth: 0,
          outlineColor: 'transparent',
        },
      },
    },
    grid: {
      line: {
        stroke: theme.colors.neutrals.c700,
        strokeWidth: 1,
      },
    },
  };

  return (
    <ResponsiveBar
      theme={graphTheme}
      data={data}
      keys={['count']}
      layout={horizontal ? 'horizontal' : 'vertical'}
      indexBy="key"
      margin={{ top: 8, right: 8, bottom: 32, left: horizontal ? 80 : 56 }}
      padding={0.1}
      enableLabel={false}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={theme.colors.primary.c500}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      enableGridX={horizontal}
      enableGridY={!horizontal}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        format: (value) => (!horizontal ? shouldRenderTickValue(data, value, formatBucketKey) : undefined),
        tickValues: tickValuesAxisBottom,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxesLabel,
        legendPosition: 'middle',
        legendOffset: -50,
        format: (v: string) => formatValue(v, true),
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[]}
      role="application"
      valueFormat={(value: number) => formatCount(value)}
      tooltipLabel={(data) => data.data.hoverKey}
      tooltip={(data) => (
        <ChartTooltip
          bucketKey={
            formatBucketKey
              ? formatBucketKey(data.data.hoverKey)
              : isCurrency
              ? formatPriceKey(data.data.hoverKey)
              : data.data.hoverKey
          }
          count={data.data.count}
          percentage={data.data.percentage}
          cumulative={data.data.cumulative}
        />
      )}
      onMouseEnter={(_data, event) => {
        // @ts-ignore
        event.target.style.fill = theme.colors.primary.c600;
        // @ts-ignore
        event.target.style.transition = 'fill 0.2s ease';
        // @ts-ignore
        event.target.style.cursor = 'pointer';
      }}
      onMouseLeave={(_data, event) => {
        // @ts-ignore
        event.target.style.fill = theme.colors.primary.c500;
        // @ts-ignore
        event.target.style.transition = 'fill 0.2s ease';
        // @ts-ignore
        event.target.style.cursor = 'default';
      }}
    />
  );
};

export default ColumnChart;
