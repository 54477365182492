import { Modal } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { Button, SecondaryButton } from '../';
import BottomSheet from '../BottomSheet';
import { ButtonRole } from '../Button';
import { BottomSheetChangeBreakpoint } from './Modal';

export const StyledModal = styled(Modal)`
  display: flex;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
  &.nextui-modal {
    border-radius: 0;
    box-shadow: none !important;
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  }
`;

export const StyledModalBody = styled(Modal.Body)<any>`
  padding: ${(props) => (props.$noPadding ? '0' : props.$padding ?? '2rem')};
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
`;

export const StyledBottomSheet = styled(BottomSheet).attrs((props) => ({
  fullScreen: props.fullScreen,
  useTransparentBackground: props.useTransparentBackground,
  headerWithoutStyles: props.headerWithoutStyles,
  showDragIndicator: props.showDragIndicator,
  displayOnTablet: props.bottomSheetChangeBreakpoint === BottomSheetChangeBreakpoint.Tablet,
  zIndex: props.zIndex,
  ...props,
}))`
  display: none;

  [data-rsbs-footer] {
    box-shadow: none;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: ${(props) => (props.displayOnTablet ? 'flex' : 'none')};
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

interface ButtonProps {
  $buttonRole: ButtonRole;
  $color: string;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => props.$buttonRole.backgroundColor(props.theme)};
  color: ${(props) => props.$buttonRole.color(props.theme)};
  border-color: ${(props) => props.$buttonRole.borderColor(props.theme)};
  &:hover {
    background-color: ${(props) => props.$buttonRole.backgroundColorHover(props.theme)};
    border-color: ${(props) => props.$buttonRole.backgroundColorHover(props.theme)};
    color: ${(props) => props.$buttonRole.colorHover(props.theme)};
  }
  &:active {
    background-color: ${(props) => props.$buttonRole.backgroundColorActive(props.theme)};
    color: ${(props) => props.$buttonRole.colorActive(props.theme)};
  }

  &:disabled {
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.c600};
    opacity: 0.7;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    cursor: not-allowed;
  }

  ${(props) => props.$color && `background-color: ${props.$color as string};`};
  ${(props) => props.$color && `color: ${props.theme.colors.neutrals.white as string};`};
`;

export const StyledSecondaryButton = styled(SecondaryButton)<{
  $mobileRemoveBorder?: boolean;
  $buttonRole: any;
}>`
  background-color: ${(props) => props.$buttonRole.backgroundColor(props.theme)};
  border-color: ${(props) => props.$buttonRole.borderColor(props.theme)};
  &:hover {
    background-color: ${(props) => props.$buttonRole.backgroundColorHover(props.theme)};
    border-color: ${(props) => props.$buttonRole.backgroundColorHover(props.theme)};
    color: ${(props) => props.$buttonRole.colorHover(props.theme)};
  }
  &:active {
    background-color: ${(props) => props.$buttonRole.backgroundColorActive(props.theme)};
    color: ${(props) => props.$buttonRole.colorActive(props.theme)};
  }
  border-color: @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    ${(props) => props.$mobileRemoveBorder && 'border: none;'};
  }
`;

interface FooterProps {
  reverse?: boolean;
  mobileColumnAlignment?: boolean;
}

export const StyledFooter = styled.div<FooterProps>`
  display: flex;
  flex-direction: ${(props: FooterProps) => (props.reverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 1rem;
  justify-content: ${(props: FooterProps) => (props.reverse ? 'flex-start' : 'flex-end')};
  padding: 0 2rem 1.5rem 2rem;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => (props.mobileColumnAlignment ? '0 0.5rem;' : '2rem 0 2rem 0;')};
    gap: 0.25rem;
    flex-direction: ${(props: FooterProps) =>
      props.reverse ? 'row-reverse' : props.mobileColumnAlignment ? 'column-reverse' : 'row'};
    button {
      max-height: 2.5rem;
    }
    gap: ${(props) => (props.mobileColumnAlignment ? '1rem;' : '0.5rem;')};
  }
`;

export const StyledGhostButton = styled(SecondaryButton)`
  background-color: transparent;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  margin-right: auto;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.md.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.md.lineHeight};
  &:hover {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  }
  &:focus {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }
`;
