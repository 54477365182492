import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Modal, { ModalProps } from '../Modal';

export interface ActionModalComponentProps {
  ref?: React.RefAttributes<HTMLElement>;
  onLoadingChanged?: (loading: boolean) => void;
  onFinish?: () => void;
  onClose?: () => void;
}

interface ActionModalProps extends Omit<ModalProps, 'open' | 'onClose' | 'children'> {
  Component: React.ComponentType<ActionModalComponentProps & any>;
  componentProps?: any;
  onClose?: () => void;
  customIcon?: React.ReactNode;
}

const ActionModal = forwardRef(({ Component, componentProps, onClose, customIcon, ...rest }: ActionModalProps, ref) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  const handleClose = () => {
    onClose?.();
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  return (
    <Modal
      mobileHeaderCustomIcon={customIcon}
      open={isOpen}
      loading={loading}
      onClose={handleClose}
      onSubmit={() => componentRef.current.submit()}
      {...rest}
    >
      <Component ref={componentRef} onLoadingChanged={setLoading} onFinish={() => setOpen(false)} {...componentProps} />
    </Modal>
  );
});

export default ActionModal;
