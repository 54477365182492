import React from 'react';

const AddressIcon = (props: any) => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 7.66675C13 12.3334 7 16.3334 7 16.3334C7 16.3334 1 12.3334 1 7.66675C1 6.07545 1.63214 4.54933 2.75736 3.42411C3.88258 2.29889 5.4087 1.66675 7 1.66675C8.5913 1.66675 10.1174 2.29889 11.2426 3.42411C12.3679 4.54933 13 6.07545 13 7.66675Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 9.66675C8.10457 9.66675 9 8.77132 9 7.66675C9 6.56218 8.10457 5.66675 7 5.66675C5.89543 5.66675 5 6.56218 5 7.66675C5 8.77132 5.89543 9.66675 7 9.66675Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddressIcon;
