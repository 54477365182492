import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import i18next from 'i18next';
import React, { createContext, FC, ReactNode, useContext, useState } from 'react';

import { KPIFieldType, ValidKPIField } from '../helpers/KPIsHelper';
import { useAppContext } from '../hooks/AppContext';
import {
  FieldValueKpiMetricDocument,
  KpiDaysOnMarketDocument,
  KpiLevel,
  KpiMetricsDocument,
  ListingMarketDaysSupplyMetricDocument,
  ListingVolumeMetricDocument,
} from '../types/api.graphql';
import { KPIMetricStats } from '../views/segments/NewSegmentPage/components/KPIsListingDrawer/KPIsListingDrawer';
import { getValueInDateRange, useKPIsAndFilterContext } from './KPIsAndFilterContext';
import { useSessionContext } from './SessionContext';

interface KPIsMetricsContextProps {
  getTitleFromKpiField: (kpiField: string) => string;
  getHelperTextFromKpiField: (kpiField: string) => string;
  getKPIsMetrics: () => Promise<void>;
  loadingKpiMetrics: boolean;
  kpiMetrics: Array<KPIMetricStats>;
  setKpiMetrics: any;
  selectedKpiMetrics: KPIMetricStats;
  setSelectedKpiMetrics: any;
  clearKPIsMetrics?: () => void;
  getTypeIconFromKpiField?: (kpiField: string) => KpiLevel;
  getTypeTextFromKpiField?: (level: KpiLevel) => string;
}

interface KPIsMetricsProviderProps {
  children: ReactNode;
}

export interface KPIsData {
  name: string;
  field: ValidKPIField;
  position?: number;
  hidden: boolean;
}

const KPIsMetricsContext = createContext<KPIsMetricsContextProps>({
  getTitleFromKpiField: undefined,
  getKPIsMetrics: undefined,
  loadingKpiMetrics: false,
  kpiMetrics: null,
  setKpiMetrics: undefined,
  selectedKpiMetrics: null,
  setSelectedKpiMetrics: undefined,
  getHelperTextFromKpiField: undefined,
  clearKPIsMetrics: undefined,
  getTypeIconFromKpiField: undefined,
  getTypeTextFromKpiField: undefined,
});

export const KPIsMetricsProvider: FC<KPIsMetricsProviderProps> = ({ children }) => {
  const { dateRangeFilter, filtersQueryParam, selectedKPIs } = useKPIsAndFilterContext();
  const { showError } = useAppContext();
  const { selectedCountry } = useSessionContext();
  const client = useApolloClient();

  const getTitleFromKpiField = (kpi: string) => {
    return selectedKPIs?.find((item) => item.field === kpi)?.name;
  };

  const getHelperTextFromKpiField = (kpi: string) => {
    return i18next.t(`kpiMetrics.${selectedKPIs?.find((item) => item.field === kpi)?.field}HelpText`);
  };

  const getTypeIconFromKpiField = (kpi: string) => {
    return KPIFieldType[selectedKPIs?.find((item) => item.field === kpi)?.field];
  };

  const getTypeTextFromKpiField = (level: KpiLevel) => {
    return i18next.t(`kpiMetrics.${level === KpiLevel.Vin ? 'vin' : 'listing'}HelpText`);
  };

  const defineMetricPromise = (selectedKPI: KPIsData) => {
    switch (selectedKPI.field) {
      case ValidKPIField.Price:
      case ValidKPIField.Mileage:
        return {
          query: client.query({
            query: KpiMetricsDocument,
            variables: {
              kpi: selectedKPI.field,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
            },
          }),
          id: selectedKPI.field,
        };
      case ValidKPIField.VehiclePrice:
      case ValidKPIField.VehicleMileage:
        return {
          query: client.query({
            query: KpiMetricsDocument,
            variables: {
              kpi: selectedKPI.field === ValidKPIField.VehiclePrice ? ValidKPIField.Price : ValidKPIField.Mileage,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
              kpiLevel: KpiLevel.Vin,
            },
          }),
          id: selectedKPI.field,
        };
      case ValidKPIField.DaysOnMarket:
        return {
          query: client.query({
            query: KpiDaysOnMarketDocument,
            variables: {
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.DaysOnMarket,
        };
      case ValidKPIField.VehicleDaysOnMarket:
        return {
          query: client.query({
            query: KpiDaysOnMarketDocument,
            variables: {
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
              kpiLevel: KpiLevel.Vin,
            },
          }),
          id: ValidKPIField.VehicleDaysOnMarket,
        };
      case ValidKPIField.ListingVolumeMetric:
        return {
          query: client.query({
            query: ListingVolumeMetricDocument,
            variables: {
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.ListingVolumeMetric,
        };
      case ValidKPIField.VehicleVolumeMetric:
        return {
          query: client.query({
            query: ListingVolumeMetricDocument,
            variables: {
              kpiLevel: KpiLevel.Vin,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.VehicleVolumeMetric,
        };
      case ValidKPIField.ListingByFranchise:
        return {
          query: client.query({
            query: FieldValueKpiMetricDocument,
            variables: {
              field: ValidKPIField.ListingByFranchise,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              value: 'Franchise',
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.ListingByFranchise,
        };

      case ValidKPIField.VehicleByFranchise:
        return {
          query: client.query({
            query: FieldValueKpiMetricDocument,
            variables: {
              field: ValidKPIField.ListingByFranchise,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              value: 'Franchise',
              country: selectedCountry,
              kpiLevel: KpiLevel.Vin,
            },
          }),
          id: ValidKPIField.VehicleByFranchise,
        };

      case ValidKPIField.ListingByCPO:
        return {
          query: client.query({
            query: FieldValueKpiMetricDocument,
            variables: {
              field: ValidKPIField.ListingByCPO,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              value: 'true',
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.ListingByCPO,
        };

      case ValidKPIField.VehicleByCPO:
        return {
          query: client.query({
            query: FieldValueKpiMetricDocument,
            variables: {
              field: ValidKPIField.ListingByCPO,
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              value: 'true',
              country: selectedCountry,
              kpiLevel: KpiLevel.Vin,
            },
          }),
          id: ValidKPIField.VehicleByCPO,
        };

      case ValidKPIField.MarketDaysSupply:
        return {
          query: client.query({
            query: ListingMarketDaysSupplyMetricDocument,
            variables: {
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
            },
          }),
          id: ValidKPIField.MarketDaysSupply,
        };

      case ValidKPIField.VehicleMarketDaysSupply:
        return {
          query: client.query({
            query: ListingMarketDaysSupplyMetricDocument,
            variables: {
              dateRange: getValueInDateRange(dateRangeFilter),
              filters: filtersQueryParam(),
              country: selectedCountry,
              kpiLevel: KpiLevel.Vin,
            },
          }),
          id: ValidKPIField.VehicleMarketDaysSupply,
        };
      default:
        return null;
    }
  };

  const defineStatsFromMetricsResponse = (value: any) => {
    return (
      value?.data?.kpiMetrics?.stats ||
      value?.data?.kpiDaysOnMarket?.stats ||
      value?.data?.listingVolumeMetric?.stats ||
      value?.data?.fieldValueKpiMetric?.stats
    );
  };

  const defineSingleValueFromMetricsResponse = (value: any) => {
    return value?.data?.listingMarketDaysSupplyMetric?.value;
  };

  const getKPIsMetrics = async () => {
    try {
      setLoadingKpiMetrics(true);
      if (!selectedKPIs || selectedKPIs.length === 0 || !selectedCountry) {
        setKpiMetrics([]);
      } else {
        const kpiMetricsData = [];
        const kpiMetricsPromises = selectedKPIs
          .map((selectedKPI) => defineMetricPromise(selectedKPI))
          .filter((item) => item !== null);

        if (kpiMetricsPromises.length > 0) {
          // We need to cast Promise as any in order to use allSettled in out version of TS.
          const result = await (Promise as any).allSettled(kpiMetricsPromises.map((item) => item.query));
          const resultWithId = kpiMetricsPromises.map((item, index) => ({ ...result[index], id: item.id }));
          resultWithId
            ?.filter((response: PromiseSettledResult<ApolloQueryResult<any>>) => response.status === 'fulfilled')
            ?.forEach((item: { status: 'fulfilled'; value: any; id: ValidKPIField }) => {
              return kpiMetricsData.push({
                kpi: item?.id,
                stats: defineStatsFromMetricsResponse(item?.value),
                value: defineSingleValueFromMetricsResponse(item?.value),
              });
            });
        }
        setKpiMetrics(kpiMetricsData);
      }
    } catch (error: any) {
      showError(error?.message);
    } finally {
      setLoadingKpiMetrics(false);
    }
  };

  const clearKPIsMetrics = () => {
    setKpiMetrics(null);
  };

  const [loadingKpiMetrics, setLoadingKpiMetrics] = useState(false);
  const [kpiMetrics, setKpiMetrics] = useState<Array<KPIMetricStats>>(null);
  const [selectedKpiMetrics, setSelectedKpiMetrics] = useState();

  return (
    <KPIsMetricsContext.Provider
      value={{
        getTitleFromKpiField,
        getHelperTextFromKpiField,
        getKPIsMetrics,
        loadingKpiMetrics,
        kpiMetrics,
        setKpiMetrics,
        selectedKpiMetrics,
        setSelectedKpiMetrics,
        clearKPIsMetrics,
        getTypeIconFromKpiField,
        getTypeTextFromKpiField,
      }}
    >
      {children}
    </KPIsMetricsContext.Provider>
  );
};

export const useKPIsMetricsContext: () => KPIsMetricsContextProps = () => {
  return useContext(KPIsMetricsContext);
};
