import React, { SVGProps } from 'react';

const FileArrowUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.1922 6.43281L12.8172 2.05781C12.7591 1.99979 12.6902 1.95378 12.6143 1.92241C12.5384 1.89105 12.4571 1.87494 12.375 1.875H4.875C4.54348 1.875 4.22554 2.0067 3.99112 2.24112C3.7567 2.47554 3.625 2.79348 3.625 3.125V16.875C3.625 17.2065 3.7567 17.5245 3.99112 17.7589C4.22554 17.9933 4.54348 18.125 4.875 18.125H16.125C16.4565 18.125 16.7745 17.9933 17.0089 17.7589C17.2433 17.5245 17.375 17.2065 17.375 16.875V6.875C17.3751 6.7929 17.359 6.71159 17.3276 6.63572C17.2962 6.55985 17.2502 6.4909 17.1922 6.43281ZM13 4.00859L15.2414 6.25H13V4.00859ZM16.125 16.875H4.875V3.125H11.75V6.875C11.75 7.04076 11.8158 7.19973 11.9331 7.31694C12.0503 7.43415 12.2092 7.5 12.375 7.5H16.125V16.875ZM12.8172 10.8078C12.8753 10.8659 12.9213 10.9348 12.9527 11.0107C12.9842 11.0866 13.0003 11.1679 13.0003 11.25C13.0003 11.3321 12.9842 11.4134 12.9527 11.4893C12.9213 11.5652 12.8753 11.6341 12.8172 11.6922C12.7591 11.7503 12.6902 11.7963 12.6143 11.8277C12.5384 11.8592 12.4571 11.8753 12.375 11.8753C12.2929 11.8753 12.2116 11.8592 12.1357 11.8277C12.0598 11.7963 11.9909 11.7503 11.9328 11.6922L11.125 10.8836V14.375C11.125 14.5408 11.0592 14.6997 10.9419 14.8169C10.8247 14.9342 10.6658 15 10.5 15C10.3342 15 10.1753 14.9342 10.0581 14.8169C9.94085 14.6997 9.875 14.5408 9.875 14.375V10.8836L9.06719 11.6922C9.00912 11.7503 8.94018 11.7963 8.86431 11.8277C8.78844 11.8592 8.70712 11.8753 8.625 11.8753C8.54288 11.8753 8.46156 11.8592 8.38569 11.8277C8.30982 11.7963 8.24088 11.7503 8.18281 11.6922C8.12474 11.6341 8.07868 11.5652 8.04725 11.4893C8.01583 11.4134 7.99965 11.3321 7.99965 11.25C7.99965 11.1679 8.01583 11.0866 8.04725 11.0107C8.07868 10.9348 8.12474 10.8659 8.18281 10.8078L10.0578 8.93281C10.1159 8.8747 10.1848 8.8286 10.2607 8.79715C10.3365 8.7657 10.4179 8.74951 10.5 8.74951C10.5821 8.74951 10.6635 8.7657 10.7393 8.79715C10.8152 8.8286 10.8841 8.8747 10.9422 8.93281L12.8172 10.8078Z" />
    </svg>
  );
};

export default FileArrowUpIcon;
