import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';

export const TooltipContainer = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const KeyText = styled.span`
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.lineHeight};
  margin-bottom: 0.75rem;
`;

export const ValueKey = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.md.lineHeight};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ValueText = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.body.sm.lineHeight};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
