import { useEffect, useState } from 'react';

import { lightTheme } from '../theme/Themes';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      if (getWindowDimensions().width !== windowDimensions.width) {
        setWindowDimensions(getWindowDimensions());
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const parsePx = (px: string) => Number(px.replace('px', ''));

export const inMobile = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.mobile));

export const inTablet = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.tablet));

export const inDesktop = (width?: number) =>
  (width || window.innerWidth) > Number(parsePx(lightTheme.breakpoints.tablet));

// Layout breakpoints for standard padding
export const smallLayout = (width?: number) =>
  (width || window.innerWidth) < Number(parsePx(lightTheme.breakpoints.tablet));

export const mediumLayout = (width?: number) =>
  (width || window.innerWidth) >= Number(parsePx(lightTheme.breakpoints.tablet)) &&
  (width || window.innerWidth) < Number(parsePx(lightTheme.breakpoints.large));

export const largeLayout = (width?: number) =>
  (width || window.innerWidth) >= Number(parsePx(lightTheme.breakpoints.large));
