import React, { SVGProps } from 'react';

const ZipCodeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.91406 6.52419C2.91406 4.85398 4.26804 3.5 5.93826 3.5V3.5C7.60847 3.5 8.96245 4.85398 8.96245 6.52419V17.25H3.53906C3.19389 17.25 2.91406 16.9702 2.91406 16.625V6.52419Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81714 3.5H14.1639C16.235 3.5 17.9139 5.17893 17.9139 7.25V16.625C17.9139 16.9702 17.6341 17.25 17.2889 17.25H6.74767"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.3516 9.25L13.8516 9.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <rect
        x="12.9141"
        y="9.25"
        width="2.5"
        height="3.125"
        rx="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.72656 9.25H6.35156" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ZipCodeIcon;
