import styled from 'styled-components';

import { BlackBookLoadingPageLogo } from './assets';
import { ThemeProp } from './theme/Themes';

export const Root = styled.div`
  height: 100%;
`;

export const LogoContainer = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
`;

export const Logo = styled(BlackBookLoadingPageLogo)`
  margin: auto;
`;
