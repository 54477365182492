import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const backgroundSelector = (props: any, selected?: boolean, disabled?: boolean, invertColors?: boolean) => {
  if (selected && disabled) return props.theme.colors.neutrals.c500 as string;
  if (selected) return invertColors ? props.theme.colors.neutrals.c800 : (props.theme.colors.primary.c500 as string);
  return 'transparent';
};

const borderSelector = (props: any, selected?: boolean, disabled?: boolean, invertColors?: boolean) => {
  if (selected && disabled) return props.theme.colors.neutrals.c500 as string;
  if (selected) return invertColors ? props.theme.colors.neutrals.c800 : (props.theme.colors.primary.c500 as string);
  return props.theme.colors.neutrals.white as string;
};

const hoverBackgroundSelector = (props: any, selected?: boolean, disabled?: boolean, invertColors?: boolean) => {
  if (selected && disabled) return props.theme.colors.neutrals.c500 as string;
  if (selected) return invertColors ? props.theme.colors.neutrals.c600 : (props.theme.colors.primary.c700 as string);
  return props.theme.colors.neutrals.c600 as string;
};

const hoverBorderSelector = (props: any, selected?: boolean, disabled?: boolean, invertColors?: boolean) => {
  if (selected && disabled) return props.theme.colors.neutrals.c500 as string;
  if (selected) return invertColors ? props.theme.colors.neutrals.c600 : (props.theme.colors.primary.c700 as string);
  return props.theme.colors.neutrals.white as string;
};

interface CustomRadioButtonOutsideProps {
  $selected?: boolean;
  $disabled?: boolean;
  $invertColors?: boolean;
}

export const CustomRadioButtonOutside = styled.div<CustomRadioButtonOutsideProps>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  cursor: ${(props: CustomRadioButtonOutsideProps) => (props.$disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;
  background: ${(props: ThemeProp & CustomRadioButtonOutsideProps) =>
    backgroundSelector(props, props.$selected, props.$disabled, props.$invertColors)};
  border: 1px solid
    ${(props: ThemeProp & CustomRadioButtonOutsideProps) =>
      borderSelector(props, props.$selected, props.$disabled, props.$invertColors)};
  :hover {
    background: ${(props: ThemeProp & CustomRadioButtonOutsideProps) =>
      hoverBackgroundSelector(props, props.$selected, props.$disabled, props.$invertColors)};
    border: 1px solid
      ${(props: ThemeProp & CustomRadioButtonOutsideProps) =>
        hoverBorderSelector(props, props.$selected, props.$disabled, props.$invertColors)};
  }
  :focus {
    box-shadow: 0 0 0 0.25rem ${(props: ThemeProp) => props.theme.colors.neutrals.c400}80;
  }
`;

export const CustomRadioButtonInside = styled.div<{ $invertColors?: boolean }>`
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  background: ${(props: ThemeProp & { $invertColors: boolean }) =>
    props.$invertColors ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.c800};
`;
