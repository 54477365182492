import styled from 'styled-components';

import { Text } from '../../components';
import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div<{ $isUnAuthenticated?: boolean }>`
  display: flex;
  align-items: center;
  gap: 3rem;
  ${(props) => props.$isUnAuthenticated && 'margin-top: 3rem;'}
  padding: 5.75rem 9.69rem;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    padding: 7.5rem 1.5rem;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 24rem;
`;

export const TextTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const TextTitleSmall = styled(Text)`
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.mdCaps.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.caption.mdCaps.lineHeight};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  text-transform: uppercase;
`;

export const TextTitleLarge = styled(Text)`
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.headline.h2.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.headline.h2.lineHeight};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    font-size: ${(props: ThemeProp) => props.theme.fontVariants.mobile.headline.h2.fontSize};
    line-height: ${(props: ThemeProp) => props.theme.fontVariants.mobile.headline.h2.lineHeight};
  }
`;

export const TextBody = styled(Text)`
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.lineHeight};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
`;

export const TextBodyButton = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.fontSize};
  line-height: ${(props: ThemeProp) => props.theme.fontVariants.desktop.buttons.sm.lineHeight};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-family: ${(props: ThemeProp) => props.theme.fonts.secondary};
  color: ${(props: ThemeProp) => props.theme.colors.primary.c500};

  margin-left: 0.25rem;
  cursor: pointer;
`;
