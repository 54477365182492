import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFoundPageIcon from '../../assets/placeholders/NotFoundPageIcon';
import {
  Container,
  TextBody,
  TextBodyButton,
  TextContainer,
  TextTitleContainer,
  TextTitleLarge,
  TextTitleSmall,
} from './NotFoundPage.styles';

interface NotFoundPageProps {
  isUnAuthenticated?: boolean;
}

const NotFoundPage = ({ isUnAuthenticated }: NotFoundPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container $isUnAuthenticated={isUnAuthenticated}>
      <NotFoundPageIcon />
      <TextContainer>
        <TextTitleContainer>
          <TextTitleSmall>{t('notFoundPage.smallTitle')}</TextTitleSmall>
          <TextTitleLarge>{t('notFoundPage.title')}</TextTitleLarge>
        </TextTitleContainer>
        <TextBody>
          {t('notFoundPage.text1')}
          <TextBodyButton onClick={() => navigate(-1)}>{t('notFoundPage.textButton')}</TextBodyButton>.
        </TextBody>
        <TextBody>{t('notFoundPage.text2')}</TextBody>
      </TextContainer>
    </Container>
  );
};

export default NotFoundPage;
