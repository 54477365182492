import React, { createContext, FC, ReactNode, useContext, useRef, useState } from 'react';

interface AnimatedNavigationProps {
  kpisListingsDetailsDrawerRef: React.RefObject<HTMLDivElement>;
  isOpenKPIsMenu: boolean;
  showKPIsMenu: () => void;
  hideKPIsMenu: () => void;
  isKPIDetailMenuOpen: boolean;
  setIsKPIDetailMenuOpen: (isOpen: boolean) => void;
  kpiDetailsDrawerRef: React.RefObject<HTMLDivElement>;
  handleActiveFilterChange: (tab: FilterView) => void;
  activeFilterView: FilterView;
  isExpandedListingView: boolean;
  setIsExpandedListingView: (isExpanded: boolean) => void;
  activeFiltersTab: FilterTab;
  setActiveFiltersTab: (tab: FilterTab) => void;
  isExpandedFiltersMenu: boolean;
  setIsExpandedFiltersMenu: (isExpanded: boolean) => void;
  isVisibleFiltersMenu: boolean;
  setIsVisibleFiltersMenu: (isVisible: boolean) => void;
}

interface AnimatedNavigationProviderProps {
  children: ReactNode;
}

export enum FilterView {
  KPI = 0,
  Listing = 1,
}

export enum FilterTab {
  Property = 0,
  Geo = 1,
}

export const AnimatedNavigation = createContext<AnimatedNavigationProps>({
  kpisListingsDetailsDrawerRef: null,
  isOpenKPIsMenu: false,
  showKPIsMenu: undefined,
  hideKPIsMenu: undefined,
  isKPIDetailMenuOpen: false,
  setIsKPIDetailMenuOpen: undefined,
  kpiDetailsDrawerRef: null,
  handleActiveFilterChange: undefined,
  activeFilterView: FilterView.KPI,
  isExpandedListingView: false,
  setIsExpandedListingView: undefined,
  activeFiltersTab: FilterTab.Property,
  setActiveFiltersTab: undefined,
  isExpandedFiltersMenu: false,
  setIsExpandedFiltersMenu: undefined,
  isVisibleFiltersMenu: true,
  setIsVisibleFiltersMenu: undefined,
});

enum DrawerComponents {
  KPIsListing = 'KPIsListing',
  KPIDetail = 'KPIDetail',
  KPIConfig = 'KPIConfig',
  FiltersView = 'FiltersView',
}

export const desktopWidths: Record<DrawerComponents, string> = {
  [DrawerComponents.KPIsListing]: '23.6875rem',
  [DrawerComponents.KPIDetail]: '27rem',
  [DrawerComponents.KPIConfig]: '25rem',
  [DrawerComponents.FiltersView]: '22.25rem',
};

export const AnimatedNavigationProvider: FC<AnimatedNavigationProviderProps> = ({ children }) => {
  const [isOpenKPIsMenu, setIsOpenKPIsMenu] = useState<boolean>(false);
  const [activeFilterView, setActiveFilterView] = useState(FilterView.KPI);
  const [isKPIDetailMenuOpen, setIsKPIDetailMenuOpen] = useState<boolean>(false);
  const [isExpandedListingView, setIsExpandedListingView] = useState<boolean>(false);
  const [activeFiltersTab, setActiveFiltersTab] = useState(FilterTab.Property);
  const [isExpandedFiltersMenu, setIsExpandedFiltersMenu] = useState(false);
  // Similar to expanded, but could be expanded and hidden
  const [isVisibleFiltersMenu, setIsVisibleFiltersMenu] = useState(true);

  const showKPIsMenu = () => {
    setIsOpenKPIsMenu(true);
  };

  const hideKPIsMenu = () => {
    setIsOpenKPIsMenu(false);
  };

  const handleActiveFilterChange = (tab: FilterView) => {
    if (tab === FilterView.KPI) {
      setIsExpandedListingView(false);
      setActiveFilterView(tab);
    } else {
      if (isOpenKPIsMenu || isKPIDetailMenuOpen) {
        hideKPIsMenu();
        setIsKPIDetailMenuOpen(false);
        setTimeout(() => setActiveFilterView(tab), 350);
      } else {
        setActiveFilterView(tab);
      }
    }
  };

  const kpiDetailsDrawerRef = useRef<HTMLDivElement>(null);
  const kpisListingsDetailsDrawerRef = useRef<HTMLDivElement>(null);

  return (
    <AnimatedNavigation.Provider
      value={{
        kpisListingsDetailsDrawerRef,
        isOpenKPIsMenu,
        showKPIsMenu,
        hideKPIsMenu,
        isKPIDetailMenuOpen,
        setIsKPIDetailMenuOpen,
        kpiDetailsDrawerRef,
        handleActiveFilterChange,
        activeFilterView,
        isExpandedListingView,
        setIsExpandedListingView,
        activeFiltersTab,
        setActiveFiltersTab,
        isExpandedFiltersMenu,
        setIsExpandedFiltersMenu,
        isVisibleFiltersMenu,
        setIsVisibleFiltersMenu,
      }}
    >
      {children}
    </AnimatedNavigation.Provider>
  );
};

export const useAnimatedNavigationContext: () => AnimatedNavigationProps = () => {
  return useContext(AnimatedNavigation);
};
