import { useSessionContext } from '../contexts/SessionContext';
import { ListingsCountry } from '../types/api.graphql';

const getCurrencyCode = (selectedCountry: ListingsCountry): string => {
  const countryToCurrency: Record<ListingsCountry, string> = {
    [ListingsCountry.Us]: 'USD',
    [ListingsCountry.Ca]: 'CAD',
    // Add more country-currency mappings as needed
  };

  return countryToCurrency[selectedCountry] || 'USD'; // Default to USD if country is not found
};

export const usePriceFormats = (providedCountry?: ListingsCountry) => {
  const { selectedCountry } = useSessionContext();

  const country = providedCountry || selectedCountry;

  const currencyCode = getCurrencyCode(country);

  const priceFormat = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  };

  const priceFormatCompact = {
    notation: 'compact',
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  };

  return { priceFormat, priceFormatCompact };
};

export const getDistanceTextLong = (selectedCountry: ListingsCountry): string => {
  const countryToText: Record<ListingsCountry, string> = {
    [ListingsCountry.Us]: 'miles',
    [ListingsCountry.Ca]: 'kilometers',
  };

  return countryToText[selectedCountry] || countryToText[ListingsCountry.Us];
};

export const getDistanceTextShort = (selectedCountry: ListingsCountry): string => {
  const countryToText: Record<ListingsCountry, string> = {
    [ListingsCountry.Us]: 'mi',
    [ListingsCountry.Ca]: 'km',
  };

  return countryToText[selectedCountry] || countryToText[ListingsCountry.Us];
};
