export const MEDIUM_LAYOUT_PADDING = '5.75rem';
export const SMALL_LAYOUT_PADDING = '1.5rem';
export const HEADER_HEIGHT = '4rem';
export const HEADER_HEIGHT_PX = 64;
export const SEGMENTS_PAGE_DESKTOP_HEADER_HEIGHT = '7.5rem';
export const SEGMENTS_PAGE_TABLET_HEADER_HEIGHT = '5.563rem';
export const SEGMENTS_PAGE_MOBILE_HEADER_HEIGHT = '8.563rem';
export const NEW_SEGMENT_PAGE_HEIGHT = `calc(100vh - ${HEADER_HEIGHT} - 4rem)`;
export const COUNT_HEIGHT = '3.25rem';

export const BOTTOM_NAV_HEIGHT = '4rem';

export const SCROLLBAR_WIDTH = '0.375rem';

export const RESULT_HEADER_HEIGHT_PX = 64;
export const FILTER_TABS_HEIGHT_PX = 75;
export const FILTER_HEADER_TITLE_HEIGHT_PX = 24;

export const SEGMENT_KPI_BOX_MAX_WIDTH_1 = '7.75rem';
export const SEGMENT_KPI_BOX_MAX_WIDTH_2 = '12.75rem';
export const SEGMENT_KPI_BOX_MAX_WIDTH_3 = '17.75rem';
export const SEGMENT_KPI_BOX_MAX_WIDTH_4 = '23rem';
export const SEGMENTS_CONTAINER_SIDE_MARGIN = '5.75rem';
export const SEGMENTS_CONTAINER_COLUMN_TITLES_HEIGHT = '3.5rem';

export const KPI_METRICS_MOBILE_SWIPER_CARD_HEIGHT = '9rem';

export const INPUT_SUGGESTIONS_TOP_POSITION_OFFSET = -(
  RESULT_HEADER_HEIGHT_PX +
  FILTER_TABS_HEIGHT_PX +
  FILTER_HEADER_TITLE_HEIGHT_PX +
  HEADER_HEIGHT_PX +
  35
);
