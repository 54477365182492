import Moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CrossErrorIcon from '../../assets/CrossErrorIcon';
import InfoErrorIcon from '../../assets/InfoErrorIcon';
import { errorToast } from '../../utils/CustomToasts/CustomToasts';
import {
  Container,
  CustomPredefinedRangeButton,
  IconContainer,
  InputsContainer,
  MinimumDateErrorMessageContainer,
  MinimumDateInformationMessageContainer,
  PredefinedSelectionButton,
  PredefinedSelectionsColumnContainer,
  PredefinedSelectionsRowContainer,
  RangeSelectionContainer,
  StyledDateRangePicker,
  StyledDateTimeInput,
} from './DateRangePicker.styles';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const moment = extendMoment(Moment as any);

interface PredefinedSelection {
  name: string;
  range: DateRange;
}

interface DateRangePickerProps {
  onChange?: (range: DateRange) => void;
  maxDaysCount?: number;
  selectedRange?: DateRange;
  minimumDate?: Date;
  maximumDate?: Date;
  setIsWrongRange?: (isWrong: boolean) => void;
  hidePredefinedSelections?: boolean;
}

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const DateRangePicker = ({
  onChange,
  maxDaysCount,
  selectedRange,
  minimumDate,
  maximumDate,
  setIsWrongRange,
  hidePredefinedSelections = false,
  ...rest
}: DateRangePickerProps) => {
  const { t } = useTranslation();
  const [valueRange, setValueRange] = useState(selectedRange || moment.range(moment(), moment()));
  const [rangeCustom, setRangeCustom] = useState<DateRange>(selectedRange || undefined);
  const [valueFrom, setValueFrom] = useState<Date | Moment.Moment>(
    selectedRange ? moment(selectedRange?.start).startOf('day') : undefined,
  );
  const [valueTo, setValueTo] = useState<Date | Moment.Moment>(
    selectedRange ? moment(selectedRange?.end)?.startOf('day') : undefined,
  );
  const [predefinedSelection, setPredefinedSelection] = useState<PredefinedSelection>(undefined);
  const [startDateSelected, setStartDateSelected] = useState<Date>(undefined);
  const [pendingDate, setPendingDate] = useState<Date>(undefined);
  const [minimumFromDateError, setMinimumFromDateError] = useState(false);

  const predefinedSelections: PredefinedSelection[] = [
    {
      name: t('components.dateRangePicker.last30days'),
      range: moment.range(moment().subtract(29, 'days'), moment()),
    },
    {
      name: t('components.dateRangePicker.thisMonth'),
      range: moment.range(moment().startOf('month'), moment().endOf('month')),
    },
    {
      name: t('components.dateRangePicker.lastMonth'),
      range: moment.range(
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ),
    },
  ];

  const normalizeRange = (range: DateRange) => {
    if (!maximumDate) return range;

    return range.intersect(moment.range(range.start, maximumDate));
  };

  const onPredefinedSelect = (selection: PredefinedSelection) => {
    handleSelect(normalizeRange(selection.range), false);
    setPredefinedSelection(selection);
    setStartDateSelected(undefined);
  };

  const isWrongRange = (range: DateRange) => {
    if (maxDaysCount) {
      const rangeDaysCount = moment.duration(range.end.diff(range.start)).asDays() + 1;
      return rangeDaysCount > maxDaysCount;
    }
    return false;
  };

  const onCustomRangeSelect = () => {
    if (!isWrongRange(rangeCustom)) {
      selectRange(rangeCustom);
      setPredefinedSelection(undefined);
    }
  };

  const CustomRangeButton = () => {
    const customRangeDaysCount = moment.duration(moment(rangeCustom.end).diff(moment(rangeCustom.start))).asDays() + 1;
    return (
      <CustomPredefinedRangeButton isSelected={!predefinedSelection} onClick={onCustomRangeSelect}>
        {t(
          customRangeDaysCount > 1
            ? 'components.dateRangePicker.daysPlural'
            : 'components.dateRangePicker.daysSingular',
          { count: Math.floor(customRangeDaysCount) },
        )}
      </CustomPredefinedRangeButton>
    );
  };

  const PredefinedSelections = () => (
    <>
      {predefinedSelections.map((selection: PredefinedSelection, index: number) => (
        <PredefinedSelectionButton
          isFirst={index === 0}
          isSelected={selection.name === predefinedSelection?.name}
          onClick={() => onPredefinedSelect(selection)}
        >
          {selection.name}
        </PredefinedSelectionButton>
      ))}
      {rangeCustom && <CustomRangeButton />}
    </>
  );

  const selectRange = (range: DateRange) => {
    if (isWrongRange(range)) {
      errorToast(t('components.dateRangePicker.maxDaysError', { count: maxDaysCount }));
    } else {
      setValueRange(range);
      setValueFrom(range.start);
      setValueTo(range.end);
      onChange?.(range);
    }
  };

  const handleSelectStart = (date: Date) => {
    if (!isTouchDevice()) {
      return;
    }
    setPendingDate(date);
    if (startDateSelected) {
      const range =
        startDateSelected < date ? moment.range(startDateSelected, date) : moment.range(date, startDateSelected);
      selectRange(range);
      setRangeCustom(range);
      setStartDateSelected(undefined);
      setPredefinedSelection(undefined);
    } else {
      setStartDateSelected(date);
      setValueFrom(date);
      setValueRange(moment.range(date, date));
    }
  };

  const handleSelect = (range: DateRange, isCustomRange = true) => {
    if (isTouchDevice() && isCustomRange) {
      return;
    }

    selectRange(range);
    setPredefinedSelection(undefined);
    if (isCustomRange && !isWrongRange(range)) {
      setRangeCustom(range);
    }
  };

  const onChangeInputValue = (range: DateRange) => {
    if (range.end >= range.start) {
      setValueRange(range);
    }
    setRangeCustom(range);
    setPredefinedSelection(undefined);
    onChange?.(range);
  };

  const onChangeStartValue = (date: Date) => {
    if (moment(date) < moment(minimumDate)) {
      setMinimumFromDateError(true);
    } else {
      setMinimumFromDateError(false);
      if (valueTo) {
        const range = moment.range(moment(date), valueTo);
        onChangeInputValue(normalizeRange(range));
      }
      setValueFrom(date);
    }
  };

  const onChangeEndValue = (date: Date) => {
    if (minimumFromDateError) return;

    if (valueFrom) {
      const range = moment.range(valueFrom, moment(date));
      onChangeInputValue(normalizeRange(range));
    }

    setValueTo(date);
  };

  useEffect(() => {
    // FIX: there was an issue on the mobile devices: touhed date cell is selecting after scroll/swipe action
    const handleDateTouchEnd = (e) => {
      e.stopPropagation();
    };

    window.addEventListener('touchmove', function () {
      const elements = document.getElementsByClassName('DateRangePicker__Date');
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchend', handleDateTouchEnd);
      }
    });

    window.addEventListener('touchend', function () {
      const elements = document.getElementsByClassName('DateRangePicker__Date');
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('touchend', handleDateTouchEnd);
      }
    });
  }, []);

  useEffect(() => {
    updateInvalidState(moment(valueFrom) > moment(valueTo) || valueFrom > maximumDate || valueTo > maximumDate);
  }, [valueFrom, valueTo]);

  const shouldSkipPendingState = () => {
    if (!isTouchDevice() || !pendingDate) return false;
    return !valueRange.contains(pendingDate);
  };

  const updateInvalidState = (isInvalid: boolean) => {
    setIsWrongRange?.(isInvalid);
  };

  const getInputError = (isFrom: boolean) => {
    const inputId = isFrom ? 'inputFrom' : 'inputTo';
    if (document.getElementById(inputId) !== document.activeElement) return undefined;

    if (isFrom && minimumFromDateError) {
      // Return an empty string because we will show a custom error box.
      return ' ';
    }

    if (moment(valueFrom) > moment(valueTo)) {
      return isFrom ? t('components.input.invalidFromDate') : t('components.input.invalidToDate');
    }

    if (!maximumDate) return undefined;

    const value = isFrom ? valueFrom : valueTo;
    if (value > maximumDate) {
      return t('components.input.maximumDateError');
    }

    return undefined;
  };

  return (
    <Container {...rest}>
      {!hidePredefinedSelections && (
        <PredefinedSelectionsColumnContainer>
          <PredefinedSelections />
        </PredefinedSelectionsColumnContainer>
      )}
      <RangeSelectionContainer>
        <InputsContainer>
          <StyledDateTimeInput
            id="inputFrom"
            label={t('components.dateRangePicker.from')}
            value={valueFrom}
            onChange={onChangeStartValue}
            error={getInputError(true)}
            setIsWrongDate={updateInvalidState}
          />
          <StyledDateTimeInput
            id="inputTo"
            label={t('components.dateRangePicker.to')}
            value={valueTo}
            onChange={onChangeEndValue}
            error={getInputError(false)}
            setIsWrongDate={updateInvalidState}
          />
        </InputsContainer>
        {minimumFromDateError && (
          <MinimumDateErrorMessageContainer>
            <IconContainer>
              <CrossErrorIcon />
            </IconContainer>
            {t('components.dateRangePicker.errorMessage')}
          </MinimumDateErrorMessageContainer>
        )}
        <PredefinedSelectionsRowContainer>
          <PredefinedSelections />
        </PredefinedSelectionsRowContainer>
        <StyledDateRangePicker
          numberOfCalendars={2}
          singleDateRange
          value={valueRange}
          onSelect={handleSelect}
          onSelectStart={handleSelectStart}
          skipPendingState={shouldSkipPendingState()}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
        />
        {moment(selectedRange?.start) < moment(minimumDate) && (
          <MinimumDateInformationMessageContainer>
            <IconContainer>
              <InfoErrorIcon />
            </IconContainer>
            {t('components.dateRangePicker.infoMessage')}
          </MinimumDateInformationMessageContainer>
        )}
      </RangeSelectionContainer>
    </Container>
  );
};

export default DateRangePicker;
