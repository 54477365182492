import { Switch as NextSwitch } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  .nextui-switch--checked {
    background-color: ${(props: ThemeProp) => props.theme.colors.primary.c700};
    &:hover {
      background-color: ${(props: ThemeProp) => props.theme.colors.primary.c700};
    }
    &:active {
      background-color: ${(props: ThemeProp) => props.theme.colors.primary.c700};
    }
    &:hover:not(:active) {
      background-color: ${(props: ThemeProp) => props.theme.colors.primary.c700};
    }
  }
  .nextui-switch--unchecked {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  }
`;

export const StyledSwitch = styled(NextSwitch)`
  padding: 0;
`;

export const Title = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c300};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  margin: auto 0.5rem auto auto;
`;
