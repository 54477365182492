import React, { SVGProps } from 'react';

const NotFoundPageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="337" height="245" viewBox="0 0 337 245" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.9">
      <g opacity="0.6" filter="url(#filter0_f_4039_125047)">
        <path
          d="M272.5 92.4998C274.715 102.073 227.232 164.534 174.756 176.673C122.279 188.812 73.2899 170.775 65.3349 136.385C57.3799 101.996 95.5235 80.1387 148 67.9998C200.477 55.8609 269.131 77.9339 272.5 92.4998Z"
          fill="url(#paint0_linear_4039_125047)"
        />
      </g>
    </g>
    <mask id="mask0_4039_125047" maskUnits="userSpaceOnUse" x="76" y="38" width="209" height="182">
      <rect x="76" y="38" width="209" height="182" fill="url(#paint1_linear_4039_125047)" />
    </mask>
    <g mask="url(#mask0_4039_125047)">
      <g opacity="0.8" filter="url(#filter1_b_4039_125047)">
        <rect width="166.774" height="34" transform="translate(98 72)" fill="#262626" fillOpacity="0.85" />
        <g opacity="0.2">
          <path
            d="M119 81.6875C117.554 81.6875 116.14 82.1164 114.937 82.9199C113.735 83.7234 112.798 84.8654 112.244 86.2016C111.691 87.5378 111.546 89.0081 111.828 90.4266C112.11 91.8451 112.807 93.148 113.829 94.1707C114.852 95.1934 116.155 95.8898 117.573 96.172C118.992 96.4541 120.462 96.3093 121.798 95.7559C123.135 95.2024 124.277 94.2651 125.08 93.0626C125.884 91.8601 126.313 90.4463 126.313 89C126.31 87.0612 125.539 85.2025 124.168 83.8315C122.798 82.4606 120.939 81.6895 119 81.6875ZM119 93.5C118.833 93.5 118.67 93.4505 118.531 93.3578C118.392 93.2651 118.284 93.1333 118.22 92.9791C118.157 92.825 118.14 92.6553 118.172 92.4916C118.205 92.328 118.285 92.1776 118.403 92.0596C118.521 91.9416 118.672 91.8613 118.835 91.8287C118.999 91.7962 119.169 91.8129 119.323 91.8767C119.477 91.9406 119.609 92.0487 119.702 92.1875C119.794 92.3262 119.844 92.4894 119.844 92.6562C119.844 92.88 119.755 93.0946 119.597 93.2529C119.438 93.4111 119.224 93.5 119 93.5ZM119.563 90.0744V90.125C119.563 90.2742 119.503 90.4173 119.398 90.5227C119.292 90.6282 119.149 90.6875 119 90.6875C118.851 90.6875 118.708 90.6282 118.602 90.5227C118.497 90.4173 118.438 90.2742 118.438 90.125V89.5625C118.438 89.4133 118.497 89.2702 118.602 89.1648C118.708 89.0593 118.851 89 119 89C119.93 89 120.688 88.3672 120.688 87.5938C120.688 86.8203 119.93 86.1875 119 86.1875C118.07 86.1875 117.313 86.8203 117.313 87.5938V87.875C117.313 88.0242 117.253 88.1673 117.148 88.2727C117.042 88.3782 116.899 88.4375 116.75 88.4375C116.601 88.4375 116.458 88.3782 116.352 88.2727C116.247 88.1673 116.188 88.0242 116.188 87.875V87.5938C116.188 86.198 117.449 85.0625 119 85.0625C120.551 85.0625 121.813 86.198 121.813 87.5938C121.813 88.8158 120.845 89.8388 119.563 90.0744Z"
            fill="white"
          />
        </g>
        <rect opacity="0.3" x="140" y="85" width="112.774" height="8" fill="#525252" />
      </g>
      <g filter="url(#filter2_bdd_4039_125047)">
        <rect width="166.774" height="34" transform="translate(98 112)" fill="#262626" fillOpacity="0.85" />
        <g opacity="0.2">
          <path
            d="M119 121.688C117.554 121.688 116.14 122.116 114.937 122.92C113.735 123.723 112.798 124.865 112.244 126.202C111.691 127.538 111.546 129.008 111.828 130.427C112.11 131.845 112.807 133.148 113.829 134.171C114.852 135.193 116.155 135.89 117.573 136.172C118.992 136.454 120.462 136.309 121.798 135.756C123.135 135.202 124.277 134.265 125.08 133.063C125.884 131.86 126.313 130.446 126.313 129C126.31 127.061 125.539 125.202 124.168 123.832C122.798 122.461 120.939 121.69 119 121.688ZM119 133.5C118.833 133.5 118.67 133.451 118.531 133.358C118.392 133.265 118.284 133.133 118.22 132.979C118.157 132.825 118.14 132.655 118.172 132.492C118.205 132.328 118.285 132.178 118.403 132.06C118.521 131.942 118.672 131.861 118.835 131.829C118.999 131.796 119.169 131.813 119.323 131.877C119.477 131.941 119.609 132.049 119.702 132.187C119.794 132.326 119.844 132.489 119.844 132.656C119.844 132.88 119.755 133.095 119.597 133.253C119.438 133.411 119.224 133.5 119 133.5ZM119.563 130.074V130.125C119.563 130.274 119.503 130.417 119.398 130.523C119.292 130.628 119.149 130.688 119 130.688C118.851 130.688 118.708 130.628 118.602 130.523C118.497 130.417 118.438 130.274 118.438 130.125V129.562C118.438 129.413 118.497 129.27 118.602 129.165C118.708 129.059 118.851 129 119 129C119.93 129 120.688 128.367 120.688 127.594C120.688 126.82 119.93 126.188 119 126.188C118.07 126.188 117.313 126.82 117.313 127.594V127.875C117.313 128.024 117.253 128.167 117.148 128.273C117.042 128.378 116.899 128.438 116.75 128.438C116.601 128.438 116.458 128.378 116.352 128.273C116.247 128.167 116.188 128.024 116.188 127.875V127.594C116.188 126.198 117.449 125.062 119 125.062C120.551 125.062 121.813 126.198 121.813 127.594C121.813 128.816 120.845 129.839 119.563 130.074Z"
            fill="white"
          />
        </g>
        <rect opacity="0.3" x="140" y="125" width="112.774" height="8" fill="#6F6F6F" />
      </g>
      <g opacity="0.8" filter="url(#filter3_b_4039_125047)">
        <rect width="136.774" height="24" transform="translate(98 152)" fill="#262626" fillOpacity="0.85" />
        <rect opacity="0.3" x="110" y="160" width="112.774" height="8" fill="#525252" />
      </g>
    </g>
    <rect x="248" y="114" width="30" height="30" rx="15" fill="#DA1E28" />
    <path
      d="M263 121.688C261.554 121.688 260.14 122.116 258.937 122.92C257.735 123.723 256.798 124.865 256.244 126.202C255.691 127.538 255.546 129.008 255.828 130.427C256.11 131.845 256.807 133.148 257.829 134.171C258.852 135.193 260.155 135.89 261.573 136.172C262.992 136.454 264.462 136.309 265.798 135.756C267.135 135.202 268.277 134.265 269.08 133.063C269.884 131.86 270.313 130.446 270.313 129C270.31 127.061 269.539 125.202 268.168 123.832C266.798 122.461 264.939 121.69 263 121.688ZM263 135.188C261.776 135.188 260.58 134.825 259.562 134.145C258.545 133.465 257.752 132.498 257.283 131.368C256.815 130.237 256.693 128.993 256.931 127.793C257.17 126.593 257.759 125.49 258.625 124.625C259.49 123.759 260.593 123.17 261.793 122.931C262.993 122.693 264.237 122.815 265.368 123.283C266.498 123.752 267.465 124.545 268.145 125.562C268.825 126.58 269.188 127.776 269.188 129C269.186 130.64 268.533 132.213 267.373 133.373C266.213 134.533 264.64 135.186 263 135.188ZM262.438 129.562V125.625C262.438 125.476 262.497 125.333 262.602 125.227C262.708 125.122 262.851 125.062 263 125.062C263.149 125.062 263.292 125.122 263.398 125.227C263.503 125.333 263.563 125.476 263.563 125.625V129.562C263.563 129.712 263.503 129.855 263.398 129.96C263.292 130.066 263.149 130.125 263 130.125C262.851 130.125 262.708 130.066 262.602 129.96C262.497 129.855 262.438 129.712 262.438 129.562ZM263.844 132.094C263.844 132.261 263.794 132.424 263.702 132.563C263.609 132.701 263.477 132.809 263.323 132.873C263.169 132.937 262.999 132.954 262.835 132.921C262.672 132.889 262.521 132.808 262.403 132.69C262.285 132.572 262.205 132.422 262.172 132.258C262.14 132.095 262.157 131.925 262.22 131.771C262.284 131.617 262.392 131.485 262.531 131.392C262.67 131.299 262.833 131.25 263 131.25C263.224 131.25 263.438 131.339 263.597 131.497C263.755 131.655 263.844 131.87 263.844 132.094Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_f_4039_125047"
        x="0.283936"
        y="0.487793"
        width="336.291"
        height="243.969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_4039_125047" />
      </filter>
      <filter
        id="filter1_b_4039_125047"
        x="94.3716"
        y="68.3716"
        width="174.031"
        height="41.2567"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.81419" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4039_125047" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4039_125047" result="shape" />
      </filter>
      <filter
        id="filter2_bdd_4039_125047"
        x="85.2077"
        y="105.604"
        width="205.151"
        height="72.3769"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.81419" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4039_125047" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7.25674" />
        <feGaussianBlur stdDeviation="3.62837" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_4039_125047" result="effect2_dropShadow_4039_125047" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6.39615" dy="12.7923" />
        <feGaussianBlur stdDeviation="9.59423" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_4039_125047" result="effect3_dropShadow_4039_125047" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_4039_125047" result="shape" />
      </filter>
      <filter
        id="filter3_b_4039_125047"
        x="94.3716"
        y="148.372"
        width="144.031"
        height="31.2567"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.81419" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4039_125047" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4039_125047" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_4039_125047"
        x1="50.373"
        y1="158.371"
        x2="236.444"
        y2="51.0512"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DA1E28" stopOpacity="0.8" />
        <stop offset="0.5" stopColor="#FA4D56" />
        <stop offset="1" stopColor="#FF8389" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4039_125047"
        x1="177.309"
        y1="222.774"
        x2="176.232"
        y2="150.497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" stopOpacity="0" />
        <stop offset="1" stopColor="#D9D9D9" />
      </linearGradient>
    </defs>
  </svg>
);

export default NotFoundPageIcon;
