// import { AudienceFilters } from '../constants/graphqlTypes';
import i18next from 'i18next';

import { GeoFilter, GeoFilterType } from '../contexts/KPIsAndFilterContext';
// import { createGeoFilterHelperFromGraphQL } from './filters';

export const maxZipAreaOpacity = 0.8;
export const minZipAreaOpacity = 0.1;
export const maxStateAreaOpacity = 0.8;
export const minStateAreaOpacity = 0.01;
const opacitiesStates = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8];

const unwantedStates = ['AS', 'PR', 'GU', 'DC'];
export interface LegendRange {
  from: number;
  to: number;
  opacity: number;
}

export const zipOpacity = (zipCount: number, zipMaxCount: number) => {
  return minZipAreaOpacity + ((maxZipAreaOpacity - minZipAreaOpacity) * zipCount) / zipMaxCount;
};

const zipMaxCountForFilter = (filter: GeoFilter) =>
  filter.zipCodes ? Math.max(...filter.zipCodes.map((zipCode) => zipCode.count)) : 0;

export const getLegendRanges = (filters: GeoFilter[], rangesCount = 7): LegendRange[] => {
  const zipMaxCount = Math.max(0, ...filters.map((filter) => zipMaxCountForFilter(filter)));
  const rangeLength = Math.trunc(zipMaxCount / rangesCount);
  const ranges: LegendRange[] = [];
  for (let i = 0; i < rangesCount; i++) {
    const from = i * rangeLength;
    const to = i < rangesCount - 1 ? from + rangeLength : zipMaxCount;
    const zipCount = (from + to) / 2;
    const opacity = zipOpacity(zipCount, zipMaxCount);
    ranges.push({ from, to, opacity });
  }
  return ranges;
};

export const getStateLegendRanges = (states): LegendRange[] => {
  const statesMaxCountForFilter = (states) =>
    Math.max(
      ...(states
        .filter((state) => !unwantedStates.includes(state.state as string))
        .map((state) => state.count || state.value) as number[]),
    );
  const statesMinCountForFilter = (states) =>
    Math.min(
      ...(states
        .filter((state) => !unwantedStates.includes(state.state as string))
        .map((state) => state.count) as number[]),
    );
  const stateMaxCount = statesMaxCountForFilter(states) || 0;
  const stateMinCount = statesMinCountForFilter(states) || 0;

  const rangesCount = opacitiesStates.length;
  const rangeLength = (stateMaxCount - stateMinCount) / rangesCount;

  const ranges: LegendRange[] = [];
  for (let i = 0; i < rangesCount; i++) {
    const from = stateMinCount + i * rangeLength;
    const to = i < rangesCount - 1 ? from + rangeLength : stateMaxCount;
    const opacity = opacitiesStates[i];
    ranges.push({ from, to, opacity });
  }
  return ranges;
};

// export const getGeoFiltersWithoutCounts = (filters: AudienceFilters): GeoFilter[] =>
//   filters
//     .filter((filter) => !!filter.geoFilter)
//     .map((filter) => {
//       return createGeoFilterHelperFromGraphQL(filter.geoFilter).fromGraphQLGeoFilter();
//     });

export const getGeofilterTypeLabel = (geoFilter: GeoFilter) => {
  switch (geoFilter.type) {
    case GeoFilterType.Circle:
      return i18next.t('geoFilters.radius');
    case GeoFilterType.Polygon:
      return i18next.t('geoFilters.polygon');
    case GeoFilterType.Region:
      return i18next.t('geoFilters.region', { region: geoFilter.data.region });
  }
};

export const getSelectorLabel = (geoFilter: GeoFilterType) => {
  switch (geoFilter) {
    case GeoFilterType.Circle:
      return i18next.t('geoFilters.radiusSelector');
    case GeoFilterType.Polygon:
      return i18next.t('geoFilters.polygonSelector');
    case GeoFilterType.Region:
      return i18next.t('geoFilters.regionSelector');
  }
};
