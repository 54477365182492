import React from 'react';

import HelpTooltip from '../HelpTooltip';
import { Container, StyledSwitch, Title } from './Switch.styles';

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  title: string;
  disabled?: boolean;
  helpText?: string;
  size?: 'xs' | 'sm' | 'md';
}

const Switch = ({ title, helpText, size, ...props }: SwitchProps) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <StyledSwitch size={size || 'sm'} {...props} />
      {helpText && <HelpTooltip $helpText={helpText} />}
    </Container>
  );
};

export default Switch;
