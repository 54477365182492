import { Popover } from '@nextui-org/react';
import styled from 'styled-components';

import { VerticalDotsIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import { IconButton } from '../';

interface StyledVertDotsIconProps {
  $active?: boolean;
}

export const StyledVertDotsIcon = styled(VerticalDotsIcon)<StyledVertDotsIconProps>`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  fill: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
`;

export const StyledVertDotsButton = styled(IconButton)`
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  border-radius: 0.25rem;

  &:hover,
  &:focus {
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.c700};
  }
`;

export const StyledPopoverContent = styled(Popover.Content)`
  margin-right: 0.5rem;
  padding: 0;
  top: 3rem;
  right: 2rem;
  max-width: fit-content;
`;

interface PopoverContainerProps {
  $showBorder?: boolean;
  $minWidth?: string;
  $noPadding?: boolean;
}

export const PopoverContainer = styled.div<PopoverContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props: ThemeProp & PopoverContainerProps) => (props.$noPadding ? '0' : '0.5rem')};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.c800};
  border: ${(props: ThemeProp & PopoverContainerProps) =>
    props.$showBorder ? `1px solid ${props.theme.colors.neutrals.c600}` : 'none'};
  min-width: ${(props: ThemeProp & PopoverContainerProps) => props.$minWidth ?? 'auto'};
`;

export const PopoverRow = styled.div`
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: var(--nextui-radii-sm);
    }
  }
`;
