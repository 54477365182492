const organizationGeneral = (organizationId: string) => `/app/organizations/${organizationId}/general`;
const organizationMembers = (organizationId: string) => `/app/organizations/${organizationId}/members`;
const organizationRoles = (organizationId: string) => `/app/organizations/${organizationId}/roles`;
const organizationInvites = (organizationId: string) => `/app/organizations/${organizationId}/invites`;
const organizationPricing = (organizationId: string) => `/app/organizations/${organizationId}/pricing`;
const organizationSettings = (organizationId: string) => `/app/organizations/${organizationId}/settings`;

const homePath = '/app/segments';

export {
  homePath,
  organizationGeneral,
  organizationInvites,
  organizationMembers,
  organizationPricing,
  organizationRoles,
  organizationSettings,
};
