import React, { SVGProps } from 'react';

const ArrowLineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.125 10C18.125 10.1658 18.0592 10.3247 17.9419 10.4419C17.8247 10.5592 17.6658 10.625 17.5 10.625H7.13359L11.6922 15.1828C11.7503 15.2409 11.7963 15.3098 11.8277 15.3857C11.8592 15.4616 11.8753 15.5429 11.8753 15.625C11.8753 15.7071 11.8592 15.7884 11.8277 15.8643C11.7963 15.9402 11.7503 16.0091 11.6922 16.0672C11.6341 16.1253 11.5652 16.1713 11.4893 16.2027C11.4134 16.2342 11.3321 16.2503 11.25 16.2503C11.1679 16.2503 11.0866 16.2342 11.0107 16.2027C10.9348 16.1713 10.8659 16.1253 10.8078 16.0672L5.18281 10.4422C5.1247 10.3841 5.0786 10.3152 5.04715 10.2393C5.0157 10.1635 4.99951 10.0821 4.99951 10C4.99951 9.91787 5.0157 9.83654 5.04715 9.76066C5.0786 9.68479 5.1247 9.61586 5.18281 9.55781L10.8078 3.93281C10.8659 3.87474 10.9348 3.82868 11.0107 3.79725C11.0866 3.76583 11.1679 3.74965 11.25 3.74965C11.3321 3.74965 11.4134 3.76583 11.4893 3.79725C11.5652 3.82868 11.6341 3.87474 11.6922 3.93281C11.7503 3.99088 11.7963 4.05982 11.8277 4.13569C11.8592 4.21156 11.8753 4.29288 11.8753 4.375C11.8753 4.45712 11.8592 4.53844 11.8277 4.61431C11.7963 4.69018 11.7503 4.75912 11.6922 4.81719L7.13359 9.375H17.5C17.6658 9.375 17.8247 9.44085 17.9419 9.55806C18.0592 9.67527 18.125 9.83424 18.125 10ZM3.125 2.5C2.95924 2.5 2.80027 2.56585 2.68306 2.68306C2.56585 2.80027 2.5 2.95924 2.5 3.125V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5C3.29076 17.5 3.44973 17.4342 3.56694 17.3169C3.68415 17.1997 3.75 17.0408 3.75 16.875V3.125C3.75 2.95924 3.68415 2.80027 3.56694 2.68306C3.44973 2.56585 3.29076 2.5 3.125 2.5Z"
      strokeWidth="0.3"
    />
  </svg>
);

export default ArrowLineIcon;
