import React, { SVGProps } from 'react';

const GlobeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5 1.875C8.89303 1.875 7.32214 2.35152 5.986 3.24431C4.64985 4.1371 3.60844 5.40605 2.99348 6.8907C2.37852 8.37535 2.21762 10.009 2.53112 11.5851C2.84463 13.1612 3.61846 14.6089 4.75476 15.7452C5.89106 16.8815 7.3388 17.6554 8.9149 17.9689C10.491 18.2824 12.1247 18.1215 13.6093 17.5065C15.094 16.8916 16.3629 15.8502 17.2557 14.514C18.1485 13.1779 18.625 11.607 18.625 10C18.6227 7.84581 17.766 5.78051 16.2427 4.25727C14.7195 2.73403 12.6542 1.87727 10.5 1.875ZM8.43985 13.125H12.5602C12.1406 14.5578 11.4375 15.8492 10.5 16.8664C9.5625 15.8492 8.85938 14.5578 8.43985 13.125ZM8.15625 11.875C7.94896 10.6336 7.94896 9.36641 8.15625 8.125H12.8438C13.051 9.36641 13.051 10.6336 12.8438 11.875H8.15625ZM3.625 10C3.62446 9.36597 3.71202 8.73493 3.88516 8.125H6.88985C6.70339 9.36805 6.70339 10.632 6.88985 11.875H3.88516C3.71202 11.2651 3.62446 10.634 3.625 10ZM12.5602 6.875H8.43985C8.85938 5.44219 9.5625 4.15078 10.5 3.13359C11.4375 4.15078 12.1406 5.44219 12.5602 6.875ZM14.1102 8.125H17.1148C17.4617 9.35093 17.4617 10.6491 17.1148 11.875H14.1102C14.2966 10.632 14.2966 9.36805 14.1102 8.125ZM16.6227 6.875H13.8547C13.5357 5.61993 13.0001 4.43019 12.2719 3.35938C13.2022 3.60939 14.07 4.05135 14.8193 4.65682C15.5686 5.26229 16.1829 6.01788 16.6227 6.875ZM8.72813 3.35938C7.99992 4.43019 7.46427 5.61993 7.14532 6.875H4.37735C4.81713 6.01788 5.43143 5.26229 6.18074 4.65682C6.93005 4.05135 7.79778 3.60939 8.72813 3.35938ZM4.37735 13.125H7.14532C7.46427 14.3801 7.99992 15.5698 8.72813 16.6406C7.79778 16.3906 6.93005 15.9487 6.18074 15.3432C5.43143 14.7377 4.81713 13.9821 4.37735 13.125ZM12.2719 16.6406C13.0001 15.5698 13.5357 14.3801 13.8547 13.125H16.6227C16.1829 13.9821 15.5686 14.7377 14.8193 15.3432C14.07 15.9487 13.2022 16.3906 12.2719 16.6406Z" />
    </svg>
  );
};

export default GlobeIcon;
