import moment from 'moment';
import React, { ComponentPropsWithoutRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledInput } from './DateTimeInput.styles';

const DATE_FORMAT = 'MM/DD/YYYY';

interface DateTimeInputProps extends Omit<ComponentPropsWithoutRef<typeof StyledInput>, 'value' | 'onChange'> {
  value: Date;
  onChange?: (date: Date) => void;
  setIsWrongDate?: (disabled: boolean) => void;
}

const DateTimeInput = forwardRef(({ value, onChange, setIsWrongDate, ...props }: DateTimeInputProps, ref) => {
  const { t } = useTranslation();
  const { error, ...rest } = props;
  const formatDate = (val: Date) => (val ? moment(val).format(DATE_FORMAT) : '');
  const [valueInitial, setValueInitial] = useState(value);
  const [inputValue, setInputValue] = useState(formatDate(value));
  const [isDateInvalid, setDateInvalid] = useState(false);

  if (value !== null && valueInitial !== value) {
    setInputValue(formatDate(value));
    setValueInitial(value);
    setDateInvalid(!moment(value).isValid());
  }

  useEffect(() => {
    setIsWrongDate?.(isDateInvalid);
  }, [isDateInvalid]);

  const onValueChange = (event: any) => {
    let inputValue = event.target.value as string;
    //replace all non numeric characters to prevent formatting any random string
    //with this code, the user can only type numbers, if a user types a letter, it will be ignored and removed when a number is typed
    //this code automatically adds the / to the date as the user types
    inputValue = inputValue.replace(/[^\d]/g, '');
    let formattedValue = '';

    if (inputValue.length >= 2) {
      formattedValue += inputValue.slice(0, 2) + '/';
      if (inputValue.length >= 4) {
        formattedValue += inputValue.slice(2, 4) + '/';
        if (inputValue.length >= 8) {
          formattedValue += inputValue.slice(4, 8);
        } else {
          formattedValue += inputValue.slice(4);
        }
      } else {
        formattedValue += inputValue.slice(2);
      }
    } else {
      formattedValue = inputValue;
    }
    setInputValue(formattedValue);
    const currentDate = moment(formattedValue, DATE_FORMAT, true);

    if (currentDate.isValid()) {
      onChange?.(currentDate.toDate());
      setDateInvalid(false);
    } else {
      setDateInvalid(true);
    }
  };

  return (
    <StyledInput
      ref={ref}
      placeholder={t('common.datePlaceholder')}
      value={inputValue}
      error={error || (isDateInvalid && t('components.input.invalidDate'))}
      onChange={onValueChange}
      {...rest}
    />
  );
});

export default DateTimeInput;
