import styled from 'styled-components';

export const InputContainer = styled.div`
  .nextui-input-main-container {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
`;
